import HomeIcon from "../Components/Icons/HomeIcon";
import SettingsIcon from "../Components/Icons/SettingsIcon";
import NotificationIcon from "../Components/Icons/NotificationIcon";
import {useLocation, useNavigate} from "react-router-dom";
import {capitalize} from "../Utils/common";
import {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {logout} from "../../Modules/Login/duck/action";

export default function Toolbar(props) {

    const navigate = useNavigate();
    const location = useLocation()
    const { pathname } = location;
    const [menuOpened,setMenuOpened] = useState(false);
    const dispatch = useDispatch();
    const authState = useSelector( (state) => state.auth)


    useEffect(() => {
        if (authState.logoutSuccess) {
            // navigate("/login");
        }
    },[authState])

    const actions = [
        {
            "title": "Profile",
            "onClick": () => {
                navigate("/settings");
            }
        },
        {
            "title": "Logout Session",
            "onClick": () => {
                dispatch(logout())
            }
        }
    ]

    return (
        <div className={`flex justify-between pt-4 z-10 pb-3 sticky top-0 px-2 bg-white left-0 right-0 ${props.className}`}>
            <h3 className="text-xl font-firmaBold text-gray-700 dark:text-gray-50" >{ capitalize(pathname.split("/")[1].replaceAll("-"," ")) }</h3>
            <div className={"flex items-center"}>

                <NotificationIcon className={"fill-gray-100 w-5 h-5"}/>

                <SettingsIcon onClick={() => {
                    navigate("/settings")
                }} className={"fill-gray-100 cursor-pointer mx-4 w-5 h-5"}/>


                <div onClick={() => {setMenuOpened(!menuOpened)}} className="relative border-2 flex items-center
                                justify-center border-gray-500 dark:border-white
                                 rounded-full cursor-pointer h-8 w-8">

                    <span className={"text-sm dark:text-white font-medium"}>
                        {authState?.login?.user?.name && authState?.login?.user?.name.split(" ").map(item => item.charAt(0)).join("")}
                    </span>


                    <div className={`mt-4 absolute z-40 border 
                            overflow-hidden overflow-y-auto
                            rounded-lg max-h-44 bg-white 
                            mb-2 rounded -left-32 -right-2 top-4
                         ${menuOpened ? 'block' : 'hidden'}
                         `}
                    >
                        {
                            actions.map((item,index) => {

                                return (
                                    <div key={index} data-class={"allowed"} onClick={item.onClick}
                                         className="h-10 px-6 border-t flex text-gray-600
                            hover:bg-gray-50 text-xs cursor-pointer
                             items-center">
                                        {
                                            item.title
                                        }
                                    </div>
                                )
                            })
                        }
                    </div>

                </div>
            </div>
        </div>
    )
}