
import * as React from "react"

const AttachmentIcon = (props) => (
    <svg
        dataSlot="icon"
        fill="none"
        strokeWidth={1.5} stroke="currentColor"
        viewBox="0 0 24 24"
        xmlns="http://www.w3.org/2000/svg"
        aria-hidden="true"
        height={20}
        width={20}
        {...props}
    >
        <path strokeLinecap="round" strokeLinejoin="round" d="m18.375 12.739-7.693 7.693a4.5 4.5 0 0 1-6.364-6.364l10.94-10.94A3 3 0 1 1 19.5 7.372L8.552 18.32m.009-.01-.01.01m5.699-9.941-7.81 7.81a1.5 1.5 0 0 0 2.112 2.13" />
    </svg>
)

export default AttachmentIcon



