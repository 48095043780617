import {ActionTypes} from "./type";

const initialState = {
    fetch_roles: {
        success: false,
        loading: false,
        error: null,
        data: null
    },
    fetch_accounts: {
        success: false,
        loading: false,
        error: null,
        data: null
    },
    create_account: {
        success: false,
        loading: false,
        error: null,
        data: null
    },
    create_business_account: {
        success: false,
        loading: false,
        error: null,
        data: null
    },
    update_kyc: {
        success: false,
        loading: false,
        error: null,
        data: null
    },
    pre_rules: {
        success: false,
        loading: false,
        error: null,
        data: null
    },
    banks: {
        success: false,
        loading: false,
        error: null,
        data: null
    },
    business: {
        success: false,
        loading: false,
        error: null,
        data: null
    },
    kyc_level: {
        success: false,
        loading: false,
        error: null,
        data: null
    }
};

export const BusinessKYCReducer = (state = initialState,action) => {
    switch (action.type) {
        //fetch roles
        case ActionTypes.REQUEST_FETCH_ROLES:
            return {
                ...state,
                fetch_roles: {
                    ...state.fetch_roles,
                    loading: true,
                    success: false,
                    error: null
                },
                update_kyc: initialState.update_kyc

            }
        case ActionTypes.FETCH_SUCCESS_ROLES:
            return {
                ...state,
                fetch_roles: {
                    ...state.fetch_roles,
                    success: true,
                    loading: false,
                    data: action.payload
                }
            }
        case ActionTypes.FETCH_ERROR_ROLES:
            return {
                ...state,
                fetch_roles: {
                    ...state.fetch_roles,
                    success: false,
                    loading: false,
                    error: action.payload
                }
            }

        //fetch accounts
        case ActionTypes.REQUEST_FETCH_ACCOUNTS:
            return {
                ...state,
                fetch_accounts: {
                    ...state.fetch_accounts,
                    loading: true,
                    success: false,
                    error: null
                }
            }
        case ActionTypes.FETCH_SUCCESS_ACCOUNTS:
            return {
                ...state,
                fetch_accounts: {
                    ...state.fetch_accounts,
                    success: true,
                    loading: false,
                    data: action.payload
                }
            }
        case ActionTypes.FETCH_ERROR_ACCOUNTS:
            return {
                ...state,
                fetch_accounts: {
                    ...state.fetch_accounts,
                    success: false,
                    loading: false,
                    error: action.payload
                }
            }

        //create accounts
        case ActionTypes.REQUEST_CREATE_ACCOUNT:
            return {
                ...state,
                create_account: {
                    ...state.create_account,
                    loading: true,
                    success: false,
                    error: null
                }
            }
        case ActionTypes.SUCCESS_CREATE_ACCOUNT:
            return {
                ...state,
                create_account: {
                    ...state.create_account,
                    success: true,
                    loading: false,
                    data: action.payload
                }
            }
        case ActionTypes.ERROR_CREATE_ACCOUNT:
            return {
                ...state,
                create_account: {
                    ...state.create_account,
                    success: false,
                    loading: false,
                    error: action.payload
                }
            }

        //update kyc
        case ActionTypes.REQUEST_UPDATE_KYC:
            return {
                ...state,
                update_kyc: {
                    ...state.update_kyc,
                    loading: true,
                    success: false,
                    error: null
                }
            }
        case ActionTypes.SUCCESS_UPDATE_KYC:
            return {
                ...state,
                update_kyc: {
                    ...state.update_kyc,
                    success: true,
                    loading: false,
                    data: action.payload
                },
                business: {
                    ...state.business,
                    data: action.payload.business
                }
            }
        case ActionTypes.ERROR_UPDATE_KYC:
            return {
                ...state,
                update_kyc: {
                    ...state.update_kyc,
                    success: false,
                    loading: false,
                    error: action.payload
                }
            }

        //fetch pre-rules
        case ActionTypes.REQUEST_FETCH_PRE_RULES:
            return {
                ...state,
                pre_rules: {
                    ...state.pre_rules,
                    loading: true,
                    success: false,
                    error: null
                }
            }
        case ActionTypes.FETCH_SUCCESS_PRE_RULES:
            return {
                ...state,
                pre_rules: {
                    ...state.pre_rules,
                    success: true,
                    loading: false,
                    data: action.payload
                }
            }
        case ActionTypes.FETCH_ERROR_PRE_RULES:
            return {
                ...state,
                pre_rules: {
                    ...state.pre_rules,
                    success: false,
                    loading: false,
                    error: action.payload
                }
            }

        //fetch banks
        case ActionTypes.REQUEST_FETCH_BANKS:
            return {
                ...state,
                banks: {
                    ...state.banks,
                    loading: true,
                    success: false,
                    error: null
                }
            }
        case ActionTypes.FETCH_SUCCESS_BANKS:
            return {
                ...state,
                banks: {
                    ...state.banks,
                    success: true,
                    loading: false,
                    data: action.payload
                }
            }
        case ActionTypes.FETCH_ERROR_BANKS:
            return {
                ...state,
                banks: {
                    ...state.banks,
                    success: false,
                    loading: false,
                    error: action.payload
                }
            }

        //create business account
        case ActionTypes.REQUEST_CREATE_BUSINESS_ACCOUNT:
            return {
                ...state,
                create_business_account: {
                    ...state.create_business_account,
                    loading: true,
                    success: false,
                    error: null
                }
            }
        case ActionTypes.SUCCESS_CREATE_BUSINESS_ACCOUNT:
            return {
                ...state,
                create_business_account: {
                    ...state.create_business_account,
                    success: true,
                    loading: false,
                    data: action.payload
                }
            }
        case ActionTypes.ERROR_CREATE_BUSINESS_ACCOUNT:
            return {
                ...state,
                create_business_account: {
                    ...state.create_business_account,
                    success: false,
                    loading: false,
                    error: action.payload
                }
            }


        //fetch business details
        case ActionTypes.REQUEST_FETCH_BUSINESS_DETAILS:
            return {
                ...state,
                business: {
                    ...state.business,
                    loading: true,
                    success: false,
                    error: null
                }
            }
        case ActionTypes.FETCH_SUCCESS_BUSINESS_DETAILS:
            return {
                ...state,
                business: {
                    ...state.business,
                    success: true,
                    loading: false,
                    data: action.payload
                }
            }
        case ActionTypes.FETCH_ERROR_BUSINESS_DETAILS:
            return {
                ...state,
                business: {
                    ...state.business,
                    success: false,
                    loading: false,
                    error: action.payload
                }
            }


        //fetch business KYC LEVEL
        case ActionTypes.REQUEST_CREATE_BUSINESS_KYC_LEVEL:
            return {
                ...state,
                kyc_level: {
                    ...state.kyc_level,
                    data: null,
                    loading: true,
                    success: false,
                    error: null
                }
            }
        case ActionTypes.SUCCESS_CREATE_BUSINESS_KYC_LEVEL:
            return {
                ...state,
                kyc_level: {
                    ...state.kyc_level,
                    success: true,
                    loading: false,
                    data: action.payload
                }
            }
        case ActionTypes.ERROR_CREATE_BUSINESS_KYC_LEVEL:
            return {
                ...state,
                kyc_level: {
                    ...state.kyc_level,
                    success: false,
                    loading: false,
                    error: action.payload
                }
            }


        default:
            return state

    }
}

