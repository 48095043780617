import JavInput from "../../../../Shared/Components/Forms/JavInput";
import {useState} from "react";
import JavButton from "../../../../Shared/Components/Buttons/JavButton";

export default function DirectorsAndAppointedOfficers(props) {

    const [form,setForm] = useState({
        full_name: "",
        job_title: "",
    });

    const handleChanges = (event) => {
        const value = event.target.value;
        setForm({
            ...form,
            [event.target.name]: value
        })
    }

    const onContinueTapped = () => {
        props.onContinue(form)
    }


    return (
        <div className={"grid grid-cols-2 dark:bg-[#242A38] rounded border dark:border-gray-600 gap-x-12 gap-y-4 py-4 px-4 my-2"}>

            <JavInput
                title={"Full Legal Name"}
                name={"full_name"}
                value={form.full_name}
                onChange={handleChanges}
            />
            <JavInput
                title={"Occupation/Job Title"}
                name={"job_title"}
                value={form.job_title}
                onChange={handleChanges}
            />


            <div className={"col-span-2 flex justify-end my-2"}>

                <JavButton
                    title={"Continue"}
                    padding={"px-16 py-3"}
                    textColor={"text-white"}
                    onClick={onContinueTapped}
                />

            </div>




        </div>
    )
}