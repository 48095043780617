import HomeIcon from "../Icons/HomeIcon";
import DistrictsIcon from "../Icons/DistrictsIcon";
import {Link, useLocation} from "react-router-dom";
import {useNavigate} from "react-router-dom";
import {useEffect, useState} from "react";
import AccountIcon from "../Icons/AccountIcon";
import TaxTypeIcon from "../Icons/TaxTypeIcon";
import TaxPayer from "../Icons/TaxPayerIcon";
import TaxPayerIcon from "../Icons/TaxPayerIcon";
import CollectorsIcon from "../Icons/CollectorsIcon";
import CollectionsIcon from "../Icons/CollectionsIcon";
import MapIcon from "../Icons/MapIcon";
import ReceiptRefundIcon from "../Icons/ReceiptRefundIcon";
import UsersIcon from "../Icons/UsersIcon";
import BusinessIcon from "../Icons/BusinessIcon";
import SettingsIcon from "../Icons/SettingsIcon";
import PendingTransactionIcon from "../Icons/PendingTransactionIcon";
import BankIcon from "../Icons/BankIcon";
import GroupIcon from "../Icons/GroupIcon";
import TerminalIcon from "../Icons/TerminalIcon";
import IdentityIcon from "../Icons/IdentityIcon";
import {CreditCardIcon} from "@heroicons/react/24/outline";


export function getIcon(props,isActive) {
    switch (props.icon) {
        case "home":
            return <HomeIcon className={` ${isActive ? 'fill-white' : 'fill-gray-500'} `}/>
        case "district":
            return <DistrictsIcon className={`${isActive ? 'fill-white' : 'fill-gray-500' }`}/>
        case "tax_payer":
            return <TaxPayerIcon className={`${isActive ? 'fill-white' : 'fill-gray-500' }`}/>
        case "businesses":
            return <BusinessIcon className={` w-5 h-5 ${isActive ? 'fill-white' : 'fill-gray-100 stoke-gray-100' }`}/>
        case "banks":
            return <BankIcon className={` w-5 h-5 ${isActive ? 'fill-white' : 'fill-gray-500' }`}/>
        case "tax_type":
            return <TaxTypeIcon className={`${isActive ? 'fill-white' : 'fill-gray-500' }`}/>
        case "collectors":
            return <CollectorsIcon className={`${isActive ? 'fill-white' : 'fill-gray-500' }`}/>
        case "collections":
            return <CreditCardIcon className={`${isActive ? 'fill-white' : 'fill-gray-100 stoke-gray-100' } w-5`} />
        case "account":
            return <AccountIcon className={`${isActive ? 'fill-white' : 'fill-gray-500' }`}/>
        case "map_view":
            return <MapIcon className={`${isActive ? 'fill-white' : 'fill-gray-500' }`}/>
        case "settings":
            return <SettingsIcon className={`${isActive ? 'fill-white' : 'fill-gray-500' }`}/>
        case "report":
            return <ReceiptRefundIcon className={`${isActive ? 'fill-white' : 'fill-gray-100 stoke-gray-100' }`}/>
        case "channel":
            return <TerminalIcon className={`${isActive ? 'fill-white' : 'fill-gray-100 stoke-gray-100' }`}/>
        case "kyc":
            return <IdentityIcon className={`${isActive ? 'fill-white' : 'fill-gray-100 stoke-gray-100' }`}/>
        case "group":
            return <GroupIcon className={`${isActive ? 'fill-none stroke-blue-200' : 'fill-gray-100 stoke-gray-100'}`} />
        case "pending_transactions":
            return <PendingTransactionIcon className={`${isActive ? 'fill-white' : 'fill-gray-100 stoke-gray-100' } w-5 h-5`}/>
    }
}

export default function NavBarItem(props) {

    const navigate = useNavigate();
    const [isActive,setIsActive] = useState();
    const location = useLocation()
    const { pathname } = location;

    useEffect(() => {
        setIsActive(pathname.includes(props.link))
    },[pathname])

    return (
        <div onClick={() => { navigate(props.link) }} className={` flex py-1.5 cursor-pointer dark:hover:bg-[#080A0D]/50 items-center ${isActive ? 'bg-[#E4EBFA] dark:bg-[#080A0D]/30' : ''} mr-5 text-sm rounded-lg`}>
            <div className={`py-2 px-2 mx-2  ${isActive ? 'bg-blue-500' : ''} rounded-lg`}>
                {getIcon(props,isActive)}
            </div>
            <h3 className="text-sm dark:text-white mx-2">{props.title.toLowerCase()}</h3>
        </div>
    )
}