import JavInput from "../../../../Shared/Components/Forms/JavInput";
import {useEffect, useState} from "react";
import JavFormSelect from "../../../../Shared/Components/Forms/JavFormSelect";
import JavCheckMultiSelect from "../../../../Shared/Components/Forms/JavCheckMultiSelect";
import Table from "../../../../Shared/Components/Table";
import JavButton from "../../../../Shared/Components/Buttons/JavButton";
import {useDispatch, useSelector} from "react-redux";
import {fetchBanks, fetchPreRules} from "../duck/action";


export default function BankingAndSettlement(props) {

    const dispatch = useDispatch();
    const kycState = useSelector( (state) => state.kyc);
    const [banks,setBanks] = useState([]);
    const initialForm = {
        bank_country: "GH",
        bank_name: "",
        bank_address: "",
        account_number: "",
        account_holder_name: "",
        account_currency: "",
        settlement_method: "",
        bank_primary_key: ""
    };
    const [form,setForm] = useState(initialForm);
    useEffect(() => {
        dispatch(fetchPreRules())
    },[]);

    useEffect(() => {
        dispatch(fetchBanks(form.bank_country))
    },[form.bank_country])

    useEffect(() => {
        console.log("data is ",props.data);
        if (props.data && props.data instanceof Array) {
            props.data.forEach((item,index) => {
                setBanks([...banks,
                    {
                        fields: [
                            {
                                title: index + 1
                            },
                            {
                                title: item.account_number
                            },
                            {
                                title: item.bank_name
                            },
                            {
                                title: item.bank_country
                            },
                            {
                                title: item.account_currency
                            },
                            {
                                title: item.settlement_method
                            },
                            {
                                render: () => {
                                    return (
                                        <td className={"flex items-center justify-center h-full"}>
                                            <JavButton
                                                bgColor={"bg-red-500"}
                                                textColor={"text-white"}
                                                title={"Remove"}
                                                className={"mt-2"}
                                                onClick={() => {
                                                    setBanks(banks.filter(item => index !== item.id))
                                                }}
                                            />
                                        </td>
                                    )
                                }
                            }
                        ],
                        meta: item
                    }
                ])
            })

        }
    },[props.data])

    const handleChanges = (event) => {
        const value = event.target.value;
        setForm({
            ...form,
            [event.target.name]: value
        })
    }

    const onContinueTapped = () => {

        let mBanks = banks.map(item => {
            return item.meta;
        });

        props.onContinue({
            banks: mBanks,
        });
    }

    const resetForm = () => {
        setForm(initialForm);
    }
    const onSaveUserTapped = () => {
        const bankId = banks.length + 1;
        setBanks([...banks,
            {
                fields: [
                    {
                        title: bankId
                    },
                    {
                        title: form.account_number
                    },
                    {
                        title: form.bank_name
                    },
                    {
                        title: form.bank_country
                    },
                    {
                        title: form.account_currency
                    },
                    {
                        title: form.settlement_method
                    },
                    {
                        render: () => {
                            return (
                                <td className={"flex items-center justify-center h-full"}>
                                    <JavButton
                                        bgColor={"bg-red-500"}
                                        textColor={"text-white"}
                                        title={"Remove"}
                                        className={"mt-2"}
                                        onClick={() => {
                                            setBanks(banks.filter(item => bankId !== item.id))
                                        }}
                                    />
                                </td>
                            )
                        }
                    }
                ],
                meta: form
            }
        ])
        resetForm();
    }

    return (
        <div className={"grid grid-cols-2 dark:bg-[#242A38] rounded border dark:border-gray-600 gap-x-12 gap-y-4 py-4 px-4 my-2"}>

            <div className={"col-span-2 mt-4 font-proximaBold"}>
                <h2 className={"text-gray-700"}>Add Baking & settlement account</h2>
            </div>

            <JavFormSelect
                items={kycState.pre_rules.data && kycState.pre_rules.data[0]["valueSet"].map((item) => {
                    return {...item,title: item.country}
                })}
                position={"bottom"}
                onChange={(item) => {
                    setForm({
                        ...form,
                        bank_country: item.countryCode
                    })
                }}
                name={"bank_country"}
                title={"Bank Country"}
                className={" mt-2"}/>

            <JavFormSelect
                items={kycState.banks.data && kycState.banks.data.map((item) => {
                    return {...item,title: `${item.institutionName} - [ ${item.swiftBIC} ]`}
                })}
                position={"bottom"}
                onChange={(item) => {
                    setForm({
                        ...form,
                        bank_name: item.institutionName,
                        bank_address: item.address1,
                        bank_primary_key: item.primaryKey
                    })
                }}
                title={"Bank Name"}
                className={" mt-2"}/>


            <JavInput
                title={"Bank Address"}
                name={"bank_address"}
                value={form.bank_address}
                onChange={handleChanges}
            />

            <JavInput
                title={"Account number"}
                name={"account_number"}
                onChange={handleChanges}
                value={form.account_number}
            />

            <JavInput
                title={"Account Holder Name"}
                name={"account_holder_name"}
                onChange={handleChanges}
                value={form.account_holder_name}
            />

            <JavFormSelect
                items={kycState.pre_rules.data && kycState.pre_rules.data[2]["valueSet"].map((item) => {
                    return {...item,title: item.currencyCode}
                })}
                onChange={(item) => {
                    setForm({
                        ...form,
                        account_currency: item.currencyCode
                    })
                }}
                position={"bottom"}
                title={"Select Currency"}
                className={" mt-2"}/>

            <div className={"col-span-2 my-4"}>

                <JavCheckMultiSelect
                    title={"Settlement method"}
                    items={[
                        "ACH",
                        "Wire",
                        "Draft",
                        "Check",
                        "Electronic bill payment",
                        "Checkmatic",
                        "Reverse wire drawdown"
                    ]}
                    onSelectionChanged={(selections) => {
                        setForm({
                            ...form,
                            settlement_method: selections
                        });
                    }}
                />
            </div>

            <div className={"col-span-2 flex justify-end my-1"}>

                <JavButton
                    title={"Add"}
                    padding={"px-16 py-3"}
                    textColor={"text-white"}
                    onClick={onSaveUserTapped}
                />

            </div>




            <div className={"bg-white mt-10 p-5 col-span-2"}>
                <h2 className={"mb-2 font-proximaBold"}> Banks & Settlement Accounts </h2>
                <Table
                    columns={["#","Account Number","Bank Name","Bank Country","currency","Settlement methods"]}
                    data={banks}
                />
            </div>


            <div className={"col-span-2 flex justify-end my-1"}>

                <JavButton
                    title={"Proceed"}
                    padding={"px-16 py-3"}
                    textColor={"text-white"}
                    onClick={onContinueTapped}
                />

            </div>




        </div>
    )
}