import AttachmentIcon from "../Icons/AttachmentIcon";
import ChatBubbleLeft from "./bubbles/ChatBubbleLeft";
import ChatBubbleRight from "./bubbles/ChatBubbleRight";
import {useState} from "react";
import axios from "axios";
import {useSelector} from "react-redux";
import {ReceiptRefundIcon} from "../Icons/RefundIcon";
import LoadingIcon from "../Icons/LoadingIcon";

export default function ChatPanel({conversations,setConversations}) {

    const [resetLoading,setResetLoading] = useState(false);
    const [form,setForm] = useState({
        chat: ""
    });
    const authState = useSelector( (state) => state.auth);

    const handleChanges = (event) => {
        const value = event.target.value;
        setForm({
            ...form,
            [event.target.name]: value
        })
    }

    const onResetChatTapped = async () => {
        setResetLoading(true);
        await new Promise(resolve => setTimeout(resolve, 1500)); // 3 sec
        setConversations([]);
        setResetLoading(false);
    }

    const onChatBoxKeydown = async (event) => {
        console.log("event is ",event.key)
        if (event?.key === "Enter") {

            let message = form?.chat;

            setConversations((previousConversations) => {
                return [...previousConversations,{
                    message: message,
                    timestamp: new Date().getTime(),
                    user: "user"
                }]
            })
            setForm({...form, chat: ""})



            //fetch for data
            let response = await axios.request({
                url: `http://staging.api.javolin.com:5005/webhooks/rest/webhook`,
                method: "POST",
                data: {
                    "message": message,
                    "sender": authState?.login?.user?.id
                }
            });


            await new Promise(resolve => setTimeout(resolve, 1500)); // 3 sec

            if (response?.data?.length < 1) {
                message = "m sorry😥 i couldn't find you an answer. I will learn 📖 and get better";
            }else {
                message = response?.data?.[0]?.["text"]
            }

            setConversations((previousConversations) => {
                return [...previousConversations,{
                    message: message,
                    timestamp: new Date().getTime(),
                    user: "chatbot"
                }]
            })
        }

    }


    return (
        <div className={"flex flex-col w-96 h-[30rem] bg-gray-50 border rounded-xl shadow-black overflow-hidden"}>

            <div className={"overflow-hidden flex justify-between items-center h-14 w-full bg-blue-800"}>
                <div className={"flex"}>
                    <img
                        src={"images/logo_white.png"}
                        className={"h-10 w-10 p-1 m-2 object-contain border rounded-full"}
                    />

                    <div className={"flex flex-col"}>

                        <h2 className={"text-white font-proximaBold mt-2"}>Javolin Admin Assistant</h2>
                        <h4 className={"text-gray-200 text-xs"}>always online</h4>
                    </div>
                </div>

                <div className={"px-2"}>

                    {
                        resetLoading
                            ? <LoadingIcon className={`animate-spin h-5 w-5 mx-2 fill-white cursor-pointer`}/>
                            : <ReceiptRefundIcon onClick={onResetChatTapped} className={"stroke-white cursor-pointer"}/>
                    }

                </div>





            </div>

            <div className={"h-[22rem] grow overflow-hidden overflow-y-scroll"}>

                {
                    conversations.map((item) => {
                        return item?.user === "user" ?
                         ( <ChatBubbleRight chat={item}/> ) :  ( <ChatBubbleLeft chat={item}/>)
                    })
                }

            </div>

            <div className={"h-14 w-full flex justify-end items-center border-t border-t-0 border-gray-200 bg-white flex"}>

                <div className={"grow w-full"}>
                    <input
                        autoComplete="false"
                        name={"chat"}
                        value={form.chat}
                        onChange={handleChanges}
                        onKeyDown={onChatBoxKeydown}
                        className={"h-full text-sm text-gray-600 px-2 w-full outline-none"}
                        placeholder={"chat here..."}/>
                </div>

                <div className={"px-2"}>
                    <AttachmentIcon className={"stroke-gray-500 h-4 stroke-1.5"}/>
                </div>
            </div>

        </div>
    )
}