import React from 'react';
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
} from 'chart.js';
import { Bar } from 'react-chartjs-2';
import { faker } from '@faker-js/faker';
import ChartDataLabels from 'chartjs-plugin-datalabels';

ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
    ChartDataLabels
);

const drawRoundedBars = (context, datasetIndex, barIndex, value) => {
    const x = value.x;
    const y = value.y;
    const width = value.width;
    const height = value.height;
    const radius = height / 2; // Set the radius for rounded corners

    // Draw rounded rectangle
    context.beginPath();
    context.moveTo(x + radius, y);
    context.lineTo(x + width - radius, y);
    context.quadraticCurveTo(x + width, y, x + width, y + radius);
    context.lineTo(x + width, y + height - radius);
    context.quadraticCurveTo(x + width, y + height, x + width - radius, y + height);
    context.lineTo(x + radius, y + height);
    context.quadraticCurveTo(x, y + height, x, y + height - radius);
    context.lineTo(x, y + radius);
    context.quadraticCurveTo(x, y, x + radius, y);
    context.closePath();
    context.fill();
}


export const options = {
    indexAxis: 'y',
    elements: {
        bar: {
            borderWidth: 2,
        },
    },
    scales: {
        yAxis: {
            display: false
        },
        xAxis: {
            grid: {
                display: false
            }
        }
    },
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
        legend: {
            position: 'right',
            display: false
        },
        title: {
            display: false,
            text: 'Chart.js Horizontal Bar Chart',
        },
        datalabels: {
            color: 'black',
            anchor: 'end',
            align: 'start',
            clamp: true,
            labels: {
                title: {
                    font: {
                        weight: 'bold'
                    }
                }
            },
            backgroundColor: function(context) {
                return context.dataset.backgroundColor;
            },
            borderRadius: 4,
            formatter: function (value, context) {
                return value; // Display the value of the bar
            },
            display: function(context) {
                return true;
            },
            padding: 6
        }
    },
};

const labels = ['January', 'February'];

export const data = {
    labels,
    datasets: [
        {
            label: 'Dataset 1',
            data: [100,0],
            borderColor: ['#6CC24A','#D3E5CC'],
            backgroundColor: ['#6CC24A','#D3E5CC'],
            barThickness: 17
        }
    ],
};

export function HorizontalChart() {
    return <Bar options={options} data={data} />;
}
