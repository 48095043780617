import JavInput from "../../Shared/Components/Forms/JavInput";
import {useEffect, useState} from "react";
import JavGoogleMapsAutoComplete from "../../Shared/Components/Forms/JavGoogleMapsAutoComplete";
import JavFormSelect from "../../Shared/Components/Forms/JavFormSelect";
import JavButton from "../../Shared/Components/Buttons/JavButton";
import {useDispatch, useSelector} from "react-redux";
import {createBusinessAccount} from "./kyc/duck/action";
import {useNavigate} from "react-router-dom";

export default function OnBoardBusiness() {

    const initialForm = {
        business_name: "",
        business_type: "",
        business_address: "",
        currency_id: 1,
        full_name: "",
        phone_number: "",
        email: ""
    };
    const kycState = useSelector( (state) => state.kyc);
    const [form,setForm] = useState(initialForm);
    const dispatch = useDispatch();
    const navigate = useNavigate();


    const handleChanges = (event) => {
        const value = event.target.value;
        setForm({
            ...form,
            [event.target.name]: value
        })
    }

    useEffect(() => {
        console.log("kycState updated")
        if (kycState.create_business_account.success) {
            alert("account created successfully");
            navigate(`/businesses/${kycState.create_business_account.data.user.id}`,
                {
                    state: {business: kycState.create_business_account.data.user}
                })
        }
        console.log(kycState);
    },[kycState])

    const onCreateTapped = () => {
        dispatch(createBusinessAccount(form));
    }

    return (
        <div>

            <div className={"grid grid-cols-2 bg-gray-50 dark:bg-[#242A38] rounded border dark:border-gray-600 gap-x-12 gap-y-4 py-4 px-4 my-2"}>

                <div className={"col-span-2 mt-4 font-proximaBold"}>
                    <h2 className={"text-gray-700 text-lg mb-2 font-primary"}>Onboard New Business 💼</h2>
                </div>


                <JavInput
                    title={"Name of Business"}
                    name={"business_name"}
                    value={form.business_name}
                    onChange={handleChanges}
                />

                <JavInput
                    title={"Type of Business"}
                    name={"business_type"}
                    value={form.business_type}
                    onChange={handleChanges}
                />

                <JavGoogleMapsAutoComplete
                    title={"Physical Address"}
                    name={"business_address"}
                    value={form.business_address}
                    onChange={handleChanges}
                />

                <JavFormSelect
                    items={[
                        "USD","GHS","CAD","XOF","EUR",""
                    ]}
                    position={"bottom"}
                    onChange={(item) => {
                        setForm({
                            ...form,
                            currency_id: 2
                        })
                    }}
                    name={"bank_country"}
                    title={"Default Currency"}
                    className={" mt-2"}/>


                <div className={"col-span-2 mt-4 font-proximaBold"}>
                    <h2 className={"text-gray-500 text-sm mb-1 font-primary"}>👉 Super Admin information</h2>
                </div>

                <JavInput
                    title={"Full Name"}
                    name={"full_name"}
                    value={form.full_name}
                    onChange={handleChanges}
                />

                <JavInput
                    title={"Phone Number"}
                    name={"phone_number"}
                    value={form.phone_number}
                    onChange={handleChanges}
                />

                <JavInput
                    title={"Email"}
                    name={"email"}
                    value={form.email}
                    onChange={handleChanges}
                />


                <div className={"col-span-2 flex justify-end my-1"}>

                    <JavButton
                        title={"Create"}
                        padding={"px-16 py-3"}
                        textColor={"text-white"}
                        isLoading={kycState.create_business_account.loading}
                        onClick={onCreateTapped}
                    />

                </div>


            </div>

        </div>
    )
}