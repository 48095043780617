import Tab from "../../../Shared/Components/Tabs";
import ClientInformation from "./tabs/ClientInformation";
import UserRolesAndPermissions from "./tabs/UserRolesAndPermissions";
import Ownership from "./tabs/Ownership";
import DirectorsAndAppointedOfficers from "./tabs/DirectorsAndAppointedOfficers";
import BankingAndSettlement from "./tabs/BankingAndSettlement";
import ForeignExchange from "./tabs/ForeignExchange";
import AuthorizationAndCertification from "./tabs/AuthorizationAndCertification";
import {useEffect, useRef, useState} from "react";
import {useLocation, useNavigate} from "react-router-dom";
import {useParams} from "react-router";
import {useDispatch, useSelector} from "react-redux";
import {businessDetails, updateKYCDetails} from "./duck/action";
import Loading from "../../../Shared/Components/Loading";
import BackArrowIcon from "../../../Shared/Components/Icons/Arrow/BackArrowIcon";
import BusinessApproval from "./tabs/BusinessApproval";
import {useAlert} from "../../../Shared/Context/AlertContext";

export default function KYC() {

    const tabRef = useRef();
    const [canUpdate,setCanUpdate] = useState(false);
    const [complianceInfo,setComplianceInfo] = useState({
        clientInformation: {},
        ownership: {},
        directorAndAppointedOfficers: {},
        bakingAndSettlement: {},
        foreignExchangeAndPayments: {},
        authorizationAndCertification: {}
    });

    const kycState = useSelector( (state) => state.kyc);
    const location = useLocation();
    const {business} = location.state;
    const navigate = useNavigate();
    const {id} = useParams();
    const dispatch = useDispatch();
    const { showAlert } = useAlert();


    useEffect(() => {
        console.log("complianceInfo",complianceInfo)
        if (canUpdate) {
            dispatch(updateKYCDetails({
                business_id: id,
                json: complianceInfo
            }))
            setCanUpdate(false)
        }
    },[canUpdate,complianceInfo])

    useEffect(() => {
        console.log("kycState.update_kyc",kycState.update_kyc)
        if (kycState.update_kyc?.success) {
            showAlert("updated successfully","green")
            tabRef.current.moveForward();
        }
    },[kycState.update_kyc])

    useEffect(() => {
        dispatch(businessDetails(business.id))
    },[business]);

    //updating already stored date on server
    useEffect(() => {
        console.log("KYC DETAILS");
        console.log(kycState);
        if (kycState.business.data) {
            let mBusiness = JSON.parse(kycState.business.data.business_kyc);
            console.log(business);

            if (mBusiness) {
                let clientInformation = {};
                let ownership = {};
                let bakingAndSettlement = {};
                console.log("mBusiness",mBusiness);
                if (mBusiness.clientInformation && Object.values(mBusiness.clientInformation).length > 0) {
                    console.log("clientInformation",mBusiness.clientInformation);
                    clientInformation = mBusiness.clientInformation;
                }

                if (mBusiness.ownership && Object.values(mBusiness.ownership).length > 0) {
                    ownership = mBusiness.ownership
                    console.log("ownership",mBusiness.ownership);
                }
                if (mBusiness.bakingAndSettlement && Object.values(mBusiness.bakingAndSettlement).length > 0) {
                    console.log("bakingAndSettlement",mBusiness.bakingAndSettlement);
                    bakingAndSettlement = mBusiness.bakingAndSettlement;
                }
                setComplianceInfo({
                    ...complianceInfo,
                    clientInformation: clientInformation,
                    bakingAndSettlement: bakingAndSettlement,
                    ownership: ownership
                })
            }
        }
    },[kycState.business]);


    return (
        <div>


            {
                kycState.business.data &&
                <div>
                    <div onClick={() => {
                        navigate(-1)
                    }} className={"flex cursor-pointer items-center"}>
                        <BackArrowIcon class={"h-6 w-6 dark:text-white"}/>
                        <span className={"text-sm mx-2 font-proximaBold text-gray-600"}>{business.business_name} - KYC</span>
                    </div>

                    <Tab ref={tabRef} className={""}>

                        <ClientInformation
                            title={"Client Information"}
                            data={complianceInfo.clientInformation}
                            continueLoading={kycState.update_kyc.loading}
                            onContinue={(data) => {
                                setComplianceInfo({
                                    ...complianceInfo,
                                    clientInformation: data
                                })
                                setCanUpdate(true);
                            }}
                        />

                        <UserRolesAndPermissions
                            title={"User Roles & permission"}
                            onContinue={(data) => {
                                console.log("on Continue")
                                console.log(tabRef.current.getCurrentIndex())
                                tabRef.current.moveForward();
                            }}
                        />

                        <Ownership
                            title={"OwnerShip"}
                            data={complianceInfo.ownership}
                            onContinue={(data) => {
                                setComplianceInfo({
                                    ...complianceInfo,
                                    ownership: data
                                })
                                setCanUpdate(true);
                            }}
                        />

                        <DirectorsAndAppointedOfficers
                            title={"Directors & Appointed Officers"}
                            data={complianceInfo.directorAndAppointedOfficers}
                            onContinue={(data) => {
                                setComplianceInfo({
                                    ...complianceInfo,
                                    directorAndAppointedOfficers: data
                                })
                                setCanUpdate(true);
                            }}
                        />

                        <BankingAndSettlement
                            title={"Banking & Settlement"}
                            data={complianceInfo.bakingAndSettlement}
                            onContinue={(data) => {
                                setComplianceInfo({
                                    ...complianceInfo,
                                    bakingAndSettlement: data.banks
                                })
                                setCanUpdate(true);
                            }}
                        />

                        <ForeignExchange
                            title={"Foreign Exchange & Payments"}
                            data={complianceInfo.foreignExchangeAndPayments}
                            onContinue={(data) => {
                                setComplianceInfo({
                                    ...complianceInfo,
                                    foreignExchangeAndPayments: data
                                })
                                setCanUpdate(true);
                            }}
                        />

                        <AuthorizationAndCertification
                            title={"Authorization & Certification"}
                            data={complianceInfo.authorizationAndCertification}
                            onContinue={(data) => {
                                setComplianceInfo({
                                    ...complianceInfo,
                                    authorizationAndCertification: data
                                })
                                setCanUpdate(true);
                            }}

                        />

                        <BusinessApproval
                            title={"Approve Business"}
                        />


                    </Tab>

                </div>
            }
            {
                <Loading
                    open={kycState.business.loading}
                    isLoading={kycState.business.loading}
                />
            }

        </div>
    )
}