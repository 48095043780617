import { ActionTypes } from "./type";

const initialState = {
    create_code_group: {
        success: false,
        loading: false,
        error: null,
        data: null
    },
    delete_code_group: {
        success: false,
        loading: false,
        error: null,
        data: null
    },
    create_code: {
        success: false,
        loading: false,
        error: null,
        data: null
    },
    delete_code: {
        success: false,
        loading: false,
        error: null,
        data: null
    }
};
export const CommonsReducer = (state = initialState, action) => {
    switch (action.type) {


        //init
        case "INIT_COMMON":
            return {
                ...state,
                [action.action]: initialState[action.action]
            }


        //create-code-group
        case ActionTypes.CREATE_CODE_GROUP_REQUEST:
            return {
                ...state,
                create_code_group: {
                    ...state.create_code_group,
                    loading: true,
                    success: false,
                    error: null
                }
            }
        case ActionTypes.CREATE_CODE_GROUP_SUCCESS:
            return {
                ...state,
                create_code_group: {
                    ...state.create_code_group,
                    success: true,
                    loading: false,
                    data: action.payload
                }
            }
        case ActionTypes.CREATE_CODE_GROUP_ERROR:
            return {
                ...state,
                create_code_group: {
                    ...state.create_code_group,
                    success: false,
                    loading: false,
                    error: action.payload
                }
            }

        //delete-code-group
        case ActionTypes.DELETE_CODE_GROUP_REQUEST:
            return {
                ...state,
                delete_code_group: {
                    ...state.delete_code_group,
                    loading: true,
                    success: false,
                    error: null
                }
            }
        case ActionTypes.DELETE_CODE_GROUP_SUCCESS:
            return {
                ...state,
                delete_code_group: {
                    ...state.delete_code_group,
                    success: true,
                    loading: false,
                    data: action.payload
                }
            }
        case ActionTypes.DELETE_CODE_GROUP_ERROR:
            return {
                ...state,
                delete_code_group: {
                    ...state.delete_code_group,
                    success: false,
                    loading: false,
                    error: action.payload
                }
            }




        //create
        case ActionTypes.CREATE_CODE_REQUEST:
            return {
                ...state,
                create_code: {
                    ...state.create_code,
                    loading: true,
                    success: false,
                    error: null
                }
            }
        case ActionTypes.CREATE_CODE_SUCCESS:
            return {
                ...state,
                create_code: {
                    ...state.create_code,
                    success: true,
                    loading: false,
                    data: action.payload
                }
            }
        case ActionTypes.CREATE_CODE_ERROR:
            return {
                ...state,
                create_code: {
                    ...state.create_code,
                    success: false,
                    loading: false,
                    error: action.payload
                }
            }


        //delete
        case ActionTypes.DELETE_CODE_REQUEST:
            return {
                ...state,
                delete_code: {
                    ...state.delete_code,
                    loading: true,
                    success: false,
                    error: null
                }
            }
        case ActionTypes.DELETE_CODE_SUCCESS:
            return {
                ...state,
                delete_code: {

                    ...state.delete_code,
                    success: true,
                    loading: false,
                    data: action.payload
                }
            }
        case ActionTypes.DELETE_CODE_ERROR:
            return {
                ...state,
                delete_code: {
                    ...state.delete_code,
                    success: false,
                    loading: false,
                    error: action.payload
                }
            }


        default:
            return state

    }
}

