import {useDispatch, useSelector} from "react-redux";
import {useEffect, useRef, useState} from "react";
import Table from "../../Shared/Components/Table";
import YesNoDialog from "../../Shared/Components/Dialog/YesNoDialog";
import {approveTransaction} from "./duck/action";
import {useNavigate} from "react-router-dom";
import JavButton from "../../Shared/Components/Buttons/JavButton";
import BlockIcon from "../../Shared/Components/Icons/BlockIcon";
import EyeIcon from "../../Shared/Components/Icons/EyeIcon";


export default function Fees() {

    const pendingTransactionsState = useSelector( (state) => state.pendingTransactions)
    const [approveLoading,setApproveLoading] = useState(false);
    const [selectedItemModel,setSelectItemModel] = useState(null);
    const dispatch = useDispatch();
    const columns = [ "#", "Name","Description","Fees","Discounts","Created At","Action"];
    const [loadingId,setLoadingId] = useState(null);
    const [tableVersion,setTableVersion] = useState(0);
    const navigate = useNavigate();

    const [form,setForm] = useState({
        legal_name: "",
        trade_name: "",
        address: "",
        legal_structure: "",
        tax_id: "",
        primary_activities: "",
        website_link: "",
        formation_country: "",
        incorporation_number: "",
    });

    const handleChanges = (event) => {
        const value = event.target.value;
        setForm({
            ...form,
            [event.target.name]: value
        })
    }




    useEffect(() => {
        if (pendingTransactionsState.approve_transaction.success) {
            //reload
            setTableVersion(tableVersion+1)
        }

    },[pendingTransactionsState.approve_transaction.success])

    return (
        <div className={""}>

            <YesNoDialog
                open={selectedItemModel != null}
                title={"Are you sure ?"}
                yesLoading={approveLoading}
                onYesClicked={() => {
                    dispatch(approveTransaction(selectedItemModel.id));
                    setLoadingId(selectedItemModel.id)
                    setSelectItemModel(null);
                }}
                onNoClicked={() => setSelectItemModel(null)}
                onCloseClicked={() => setSelectItemModel(null)}
            >
                <div className="text-xs py-3">
                    Are you sure approve this transaction ?
                </div>
            </YesNoDialog>

            <div className="dark:bg-[#242A38] bg-gray-50 dark:border-gray-500 border py-2 mb-4 rounded-lg px-2">
                <Table
                    link={"admin-portal/modules"}
                    tag={"fees.table"}
                    columns={columns}
                    currentVersion={tableVersion}
                    dependencies={{
                        pendingTransactionsState,
                        loadingId
                    }}
                    fields={["id","name","description",
                        {
                        render: (content) => {
                            return (
                                <td className={"text-center pt-"}>
                                    <ol>
                                        {
                                            content.fees.map((fee,index) => {
                                                return (
                                                    <li>
                                                        {index + 1}. {fee.deduction_type} [ {fee.deduction_value} ]
                                                    </li>
                                                )
                                            })
                                        }
                                    </ol>
                                </td>
                            )
                        }
                    },{
                            render: (content) => {
                                return (
                                    <td className={"text-center pt-"}>
                                        <ol>
                                            {
                                                content.discounts.map((discount,index) => {
                                                    return (
                                                        <li>
                                                            {index + 1}. {discount.commission_type} [ {discount.commission_value} ]
                                                        </li>
                                                    )
                                                })
                                            }
                                        </ol>
                                    </td>
                                )
                            }
                        }
                    ,'created_at',
                    {
                        render: (content) => {
                            return (
                                <td className={""}>
                                    <div className={`flex justify-center`}>

                                        <JavButton onClick={() => {
                                            navigate(`/fees/module/${content.id}`);
                                        }} className={"p-1 mx-2"} bgColor={"bg-gray-200 "}>
                                            <EyeIcon/>
                                        </JavButton>

                                    </div>
                                </td>
                            )
                        }
                    }]}
                />
            </div>



        </div>

    )
}