import {useState} from "react";
import LoadingIcon from "../../Icons/LoadingIcon";

export default function JavSwitch(props){
    const [toggle, setToggle] = useState(props.active);
    const toggleClass = "transform translate-x-3.5 text-blue-200 bg-[#13ce66]";
    return (
        <>
            <div className="flex flex-col justify-center items-center ">
                {/*   Switch Container */}

                <div
                    className={`md:w-10 md:h-5 w-8 h-3 flex ${props.isLoading ? '' : 'border-gray-400 border'}  items-center bg-gray-50 rounded-full p-1 cursor-pointer`}
                    onClick={() => {
                        props.onChange ? props.onChange() : setToggle(!toggle);
                    }}
                >
                    {/* Switch */}
                    {
                        !props.isLoading &&
                        <div
                            className={
                                "bg-gray-400 md:w-4 md:h-4 h-3 w-3 rounded-full shadow-md transform duration-300 ease-in-out" +
                                (toggle ? toggleClass : null)
                            }
                        ></div>
                    }


                    {/* Loader */}
                    {
                        props.isLoading &&
                        <div className={"flex justify-center"}>
                            <LoadingIcon className={`animate-spin h-5 w-5 mx-2 fill-black`}/>
                        </div>
                    }


                </div>
            </div>
        </>
    );
}


