import {useEffect} from "react";
import JavButton from "../Buttons/JavButton";
import CloseIcon from "../Icons/CloseIcon";

export default function Dialog({icon: Icon,...props}) {
  useEffect(() => {}, []);

  if (!props.open) {
    return <></>;
  }

  const getPosition = (position) => {
    let positionClassName = "";
    switch (position) {
      case "top":
        positionClassName = "items-start";
        break;
      case "bottom":
        positionClassName = "items-end";
        break;
      case "center":
      default:
        positionClassName = "items-center";
    }
    return positionClassName;
  };

  return (
    <div
      className={`absolute flex z-10 justify-center
                        p-2 top-0 left-0 ${getPosition(
                          props?.position
                        )} 
                        w-screen h-screen
                        backdrop-filter
                        backdrop-blur-sm`}
    >
      <div
        className={
          "absolute top-0 bottom-0 left-0 right-0 bg-black opacity-60"
        }
      ></div>

      <div
        style={props?.style}
        className={`${props?.width ?? "w-96"} ${
          props?.hasBorder && "border"
        } z-20 overflow-hidden bg-white rounded-xl`}
      >
        {props?.titleWithSubtitle && (
          <div
            className={
              "bg-jav-card-100 bg-bg-pattern relative py-8 flex items-center justify-center"
            }
          >
            <div className={"flex flex-col text-center"}>
              <span className={"font-firmaBold text-lg text-black"}>
                {props?.title}
              </span>
              <span className={"font-satoshiMedium"}>
               {props?.subTitle}
              </span>
            </div>
            <CloseIcon
              onClick={props.onCloseClicked}
              className={
                "stroke-current shadow-lg text-black stroke-2 absolute top-3 right-5 bg-white rounded-full cursor-pointer"
              }
            />
          </div>
        )}

        {!props?.titleWithSubtitle && (
          <div
            className={`border-b ${
              props.review ? "bg-bg-review" : "bg-jav-primary-300"
            } text-white px-4 py-3 flex
                                items-center justify-between`}
          >
            <h3 className="text-sm font-satoshiBold">{props.title}</h3>

            <CloseIcon
              onClick={props.onCloseClicked}
              className={
                `stroke-current shadow-lg text-black stroke-2 ${props.review ? "bg-white": "bg-blue-50"} rounded-full cursor-pointer`
              }
            />
          </div>
        )}

        <div className="py-3 px-3">{props.children}</div>

        <div
          className={`grid px-5 gap-x-4
                                grid-cols-${
                                  props.actions?.length <= 3
                                    ? props.actions?.length
                                    : 3
                                } pb-2`}
        >
          {props?.actions?.map((action) => {
            return (
              <JavButton
                onClick={action.onClick}
                isLoading={action.loading}
                className={`text-white ${action.className}`}
                textColor={"text-white"}
                title={action.title}
                bgColor={action?.bgColor}
              />
            );
          })}
        </div>
      </div>
    </div>
  );
}
