export const ActionTypes = {

    REQUEST_FETCH_ROLES: "REQUEST_FETCH_ROLES",
    FETCH_SUCCESS_ROLES: "FETCH_SUCCESS_ROLES",
    FETCH_ERROR_ROLES: "FETCH_ERROR_ROLES",


    REQUEST_CREATE_ACCOUNT: "REQUEST_CREATE_ACCOUNT",
    SUCCESS_CREATE_ACCOUNT: "SUCCESS_CREATE_ACCOUNT",
    ERROR_CREATE_ACCOUNT: "ERROR_CREATE_ACCOUNT",

    REQUEST_FETCH_ACCOUNTS: "REQUEST_FETCH_ACCOUNTS",
    FETCH_SUCCESS_ACCOUNTS: "FETCH_SUCCESS_ACCOUNTS",
    FETCH_ERROR_ACCOUNTS: "FETCH_ERROR_ACCOUNTS",

    REQUEST_FETCH_PRE_RULES: "REQUEST_FETCH_PRE_RULES",
    FETCH_SUCCESS_PRE_RULES: "FETCH_SUCCESS_PRE_RULES",
    FETCH_ERROR_PRE_RULES: "FETCH_ERROR_PRE_RULES",

    REQUEST_FETCH_BANKS: "REQUEST_FETCH_BANKS",
    FETCH_SUCCESS_BANKS: "FETCH_SUCCESS_BANKS",
    FETCH_ERROR_BANKS: "FETCH_ERROR_BANKS",

    REQUEST_FETCH_BUSINESS_DETAILS: "REQUEST_FETCH_BUSINESS_DETAILS",
    FETCH_SUCCESS_BUSINESS_DETAILS: "FETCH_SUCCESS_BUSINESS_DETAILS",
    FETCH_ERROR_BUSINESS_DETAILS: "FETCH_ERROR_BUSINESS_DETAILS",


    REQUEST_UPDATE_KYC: "REQUEST_UPDATE_KYC",
    SUCCESS_UPDATE_KYC: "SUCCESS_UPDATE_KYC",
    ERROR_UPDATE_KYC: "ERROR_UPDATE_KYC",

    REQUEST_CREATE_BUSINESS_ACCOUNT: "REQUEST_CREATE_BUSINESS_ACCOUNT",
    SUCCESS_CREATE_BUSINESS_ACCOUNT: "SUCCESS_CREATE_BUSINESS_ACCOUNT",
    ERROR_CREATE_BUSINESS_ACCOUNT: "ERROR_CREATE_BUSINESS_ACCOUNT",


    REQUEST_CREATE_BUSINESS_KYC_LEVEL: "REQUEST_CREATE_BUSINESS_KYC_LEVEL",
    SUCCESS_CREATE_BUSINESS_KYC_LEVEL: "SUCCESS_CREATE_BUSINESS_KYC_LEVEL",
    ERROR_CREATE_BUSINESS_KYC_LEVEL: "ERROR_CREATE_BUSINESS_KYC_LEVEL",


    REQUEST_BUSINESS_ACCOUNT_RESET: "REQUEST_BUSINESS_ACCOUNT_RESET",
    SUCCESS_BUSINESS_ACCOUNT_RESET: "SUCCESS_BUSINESS_ACCOUNT_RESET",
    ERROR_BUSINESS_ACCOUNT_RESET: "ERROR_BUSINESS_ACCOUNT_RESET",




}