import JavInput from "../../Shared/Components/Forms/JavInput";
import JavButton from "../../Shared/Components/Buttons/JavButton";
import {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {login} from "./duck/action";
import {useNavigate} from "react-router-dom";
import {useAlert} from "../../Shared/Context/AlertContext";
import Alert from "../../Shared/Components/Alert";

export default function Login() {

    const loginState = useSelector( (state) => state.auth)
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { showAlert } = useAlert();

    const [credentials,setCredentials] = useState({
        phone_number: "",
        password: ""
    });

    const handleChanges = (event) => {
        const value = event.target.value;
        setCredentials({
            ...credentials,
            [event.target.name]: value
        })
    }

    const onLoginClicked = () => {
        dispatch(login(credentials.phone_number,credentials.password));
    }

    useEffect(() => {

        if (loginState.login.errorMessage) {
            showAlert(loginState?.login.errorMessage,"red")
        }

        if (loginState.login.loginSuccess) {
            navigate("/dashboard")
        }

    },[loginState?.login])

    
    return (
        <div>

            <Alert/>

            <img src={"images/login_bg.webp"}
                 className={"h-screen w-screen  object-left"} />

            <div className="absolute top-0
                            bottom-0 left-0 right-0
                            flex">

                <div className={"relative drop-shadow-md w-1/2 bg-gradient-to-r from-black"}>

                    <div className={"w-1/2 absolute bottom-0 top-0 hidden lg:flex flex-col justify-center mx-10"}>

                        <div className="flex flex-col absolute text-white bottom-0 mb-2">
                            <span className={"text-xs"}>v 1.1</span>
                        </div>

                        <img className={"w-56"}
                             src={"/images/logo_white.png"}
                        />

                        <div className="text-left mb-28 mt-4 text-4xl
                                    text-gray-50
                                    font-firmaBold">
                            <h3>Admin Dashboard</h3>
                        </div>

                        <div className={""}>

                            {/*<img className={"w-44"}*/}
                            {/*     src={"https://aywma.gov.gh/img/logo.png"}*/}
                            {/*/>*/}

                        </div>


                    </div>


                </div>

                <div className={"w-full lg:w-1/2 flex items-center justify-center"}>

                    <div className="flex flex-col w-full mx-10 sm:mx-20 md:mx-40 lg:mr-10 ml-10">

                        <div className={"p-8 border rounded grid  gap-y-4 grid-cols-1 bg-white"}>

                            <div className={"font-firmaBold text-3xl text-gray-500"}>
                                Login.
                            </div>

                            <JavInput type={"text"}
                                      name={"phone_number"}
                                      placeholder={"Email"}
                                      height={"h-12"}
                                      className={"text-xs w-full"}
                                      onChange={handleChanges}
                            />

                            <JavInput type={"password"}
                                      name={"password"}
                                      placeholder={"Password"}
                                      height={"h-12"}
                                      className={"text-xs w-full"}
                                      onChange={handleChanges}
                            />

                            <div>
                                <span className="text-blue-900 text-xs font-firmaBold">Forgot Password ?</span>
                            </div>

                            <div className="flex justify-between">


                                <div>

                                </div>
                                <JavButton onClick={onLoginClicked} isLoading={loginState.login.isLoading} className={"px-10 font-firmaBold"}>
                                    Sign In
                                </JavButton>

                            </div>

                        </div>

                        <div className={"mt-3"}>
                            <span className={"font-firma  text-gray-300 text-xs"}>
                                Can't Login ? <a className={"text-white cursor-pointer underline"}>Contact Admin</a>
                            </span>

                        </div>

                    </div>


                </div>
            </div>

        </div>

    )
}