import * as React from "react"

const CollectorsIcon = (props) => (
    <svg
        width={20}
        height={20}
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <path
            d="M7.5 1.667a3.962 3.962 0 0 0-3.958 3.958c0 2.142 1.675 3.875 3.858 3.95a.672.672 0 0 1 .183 0h.058a3.948 3.948 0 0 0 3.817-3.95A3.962 3.962 0 0 0 7.5 1.667ZM11.733 11.791c-2.325-1.55-6.116-1.55-8.458 0-1.058.709-1.642 1.667-1.642 2.692 0 1.025.584 1.975 1.634 2.675 1.166.783 2.7 1.175 4.233 1.175 1.533 0 3.067-.392 4.233-1.175 1.05-.708 1.634-1.658 1.634-2.692-.009-1.025-.584-1.975-1.634-2.675ZM16.658 6.117a2.983 2.983 0 0 1-2.608 3.225h-.042c-.05 0-.1 0-.141.017a2.99 2.99 0 0 1-2.109-.692 3.814 3.814 0 0 0 1.25-3.167 3.867 3.867 0 0 0-.641-1.816 2.994 2.994 0 0 1 4.292 2.433Z"
        />
        <path
            d="M18.325 13.825c-.067.808-.584 1.508-1.45 1.983-.834.458-1.883.675-2.925.65.6-.542.95-1.217 1.017-1.933.083-1.034-.409-2.025-1.392-2.817a6.88 6.88 0 0 0-1.917-1.05c1.842-.533 4.159-.175 5.583.975.767.617 1.159 1.392 1.084 2.192Z"
        />
    </svg>
)

export default CollectorsIcon
