import {useEffect, useRef, useState} from "react";
import {formatCurrency} from "../../../../Shared/Utils/common";
import Table from "../../../../Shared/Components/Table";

export default function TransactionSummary (props) {

    const tableRef = useRef();
    const [form,setForm] = useState({
        notes: ""
    });

    useEffect(() => {
        let meta = props?.data?.meta ? JSON.parse(props?.data?.meta) : {};
        setForm({
            ...form,
            notes: meta?.notes
        })
        tableRef?.current?.reloadTable(`admin-portal/transaction-payments?transaction_id=${props?.data?.loan?.id}`);
    },[props?.data])





    if (!props.data) {
        return <>
            Loading...
        </>
    }


    return (
        <div className={"bg-gray-50"}>


            <div className={"text-sm flex items-center font-proximaBold py-3"}>
                <div className={"h-2 mx-2 w-2 bg-green-600 rounded-full"}/>
                Transaction Details
            </div>

            <div className={"grid grid-cols-1 border-b border-gray-300"}>

                <div className={"grid grid-cols-4"}>
                    <div className={"bg-gray-200 font-proximaBold text-sm py-3 px-2"}>
                        Transaction Reference
                    </div>
                    <div className={"text-center text-gray-600 py-2"}>
                        { props.data.reference}
                    </div>
                </div>

            </div>

            <div className={"grid grid-cols-2 border-b border-gray-300"}>

                <div className={"grid  grid-cols-2"}>
                    <div className={"bg-gray-200 font-proximaBold text-sm py-4 px-2"}>
                        Transaction Amount
                    </div>

                    <div className={"text-center flex text-blue-700 font-bold items-center justify-center py-2"}>
                        {props.data && props.data.currency && props.data.currency.ISO || "GHS"} {props.data  && formatCurrency(props.data.amount)}
                    </div>
                </div>

                <div className={"grid  grid-cols-2"}>
                    <div className={"bg-gray-200 py-4 px-2"}>
                        Transaction Fee
                    </div>

                    <div className={"text-center py-2"}>
                        <div className={"text-center flex text-blue-600 font-bold items-center justify-center py-2"}>
                            {props.data  && props.data.currency && props.data.currency.ISO} {props.data && props.data.fee}
                        </div>
                    </div>
                </div>


            </div>

            <div className={"grid grid-cols-2 border-b border-gray-300"}>

                <div className={"grid  grid-cols-2"}>
                    <div className={"bg-gray-200  py-4 px-2"}>
                        Module
                    </div>

                    <div className={"text-center flex font-bold items-center justify-center py-2"}>
                         {props.data.module}
                    </div>
                </div>

                <div className={"grid  grid-cols-2"}>
                    <div className={"bg-gray-200 py-4 px-2"}>
                        Status
                    </div>

                    <div className={"text-center items-center flex justify-center"}>
                         <span className={"bg-red-100 text-red-500 rounded  text-xs p-1 font-bold mx-2"}>
                            {props.data.status.toLowerCase()}
                        </span>
                    </div>
                </div>


            </div>

            <div className={"grid grid-cols-2 border-b border-gray-300"}>

                <div className={"grid grid-cols-2"}>
                    <div className={"bg-gray-200 font-proximaBold text-sm py-4 px-2"}>
                        Timestamp
                    </div>

                    <div className={"text-center flex font-bold items-center justify-center py-2"}>
                        {props.data && props.data.created_at}
                    </div>
                </div>

                <div className={"grid  grid-cols-2"}>
                    <div className={"bg-gray-200 font-proximaBold text-sm py-4 px-2"}>
                        External Reference
                    </div>

                    <div className={"text-center flex font-bold items-center justify-center py-2"}>

                    </div>
                </div>

            </div>



            <div className={"grid grid-cols-2 border-b border-gray-300"}>

                <div className={"grid grid-cols-2"}>
                    <div className={"bg-gray-200 font-proximaBold text-sm py-4 px-2"}>
                        Description
                    </div>

                    <div className={"text-center text-sm flex font-bold items-center justify-center py-2"}>
                        {props.data && props.data.description}
                    </div>
                </div>

            </div>


            {
                props.data && props.data.module === "LOANS" && props?.data?.loan?.loan_type === "1" &&

                <div>
                    <div className={"text-sm flex items-center font-proximaBold my-3"}>
                        <div className={"h-2 mx-2 w-2 bg-green-600 rounded-full"}/>
                        General Loan Details
                    </div>

                    <div className={"grid grid-cols-2 border-b border-gray-300"}>

                        <div className={"grid  grid-cols-2"}>
                            <div className={"bg-gray-200 font-proximaBold text-sm py-4 px-2"}>
                                Initiated
                            </div>

                            <div className={"text-center py-2"}>
                                {props.data?.loan?.business?.full_name}
                            </div>
                        </div>

                        <div className={"grid  grid-cols-2"}>
                            <div className={"bg-gray-200 font-proximaBold text-sm py-4 px-2"}>
                                Role
                            </div>

                            <div className={"text-center py-2"}>
                                Super Admin
                            </div>
                        </div>

                    </div>

                    <div className={"grid grid-cols-2 border-b border-gray-300"}>

                        <div className={"grid  grid-cols-2"}>
                            <div className={"bg-gray-200 font-proximaBold text-sm py-4 px-2"}>
                                Beneficiary Account
                            </div>

                            <div className={"text-center py-2"}>
                                {props.data?.loan?.beneficiary?.name}
                            </div>
                        </div>

                        <div className={"grid  grid-cols-2"}>
                            <div className={"bg-gray-200 font-proximaBold text-sm py-4 px-2"}>
                                Beneficiary Bank Account
                            </div>

                            <div className={"text-center py-2"}>
                                {props.data?.loan?.beneficiary?.account_number}
                                {" "}[ {props.data?.loan?.beneficiary?.bank_name} ]
                                {" "}({<span
                                className={"text-sm border px-1 py-0.5 font-proximaBold text-white rounded mx-1 bg-blue-600"}>{props.data?.loan?.beneficiary?.currency}</span>})
                            </div>
                        </div>

                    </div>

                    <div className={"text-sm flex items-center font-proximaBold my-3"}>
                        <div className={"h-2 mx-2 w-2 bg-green-600 rounded-full"}/>
                        Expected Loan Details
                    </div>

                    <div className={"grid grid-cols-3 border-b border-gray-300"}>

                        <div className={"grid grid-cols-2"}>
                            <div className={"bg-gray-200 font-proximaBold text-sm py-4 px-2"}>
                                Expected Number Of Trucks
                            </div>

                            <div className={"text-center py-2"}>
                                {props?.data?.loan?.expected_number_of_trucks} Truck(s)
                            </div>
                        </div>

                        <div className={"grid grid-cols-2"}>
                            <div className={"bg-gray-200 font-proximaBold text-sm py-4 px-2"}>
                                Expected Number of Bags
                            </div>

                            <div className={"text-center py-2"}>
                                {props?.data?.loan?.expected_number_of_bags} Bags
                            </div>
                        </div>

                        <div className={"grid grid-cols-2"}>
                            <div className={"bg-gray-200 font-proximaBold text-sm py-4 px-2"}>
                                Expected Weight Per Bag
                            </div>

                            <div className={"text-center py-2"}>
                                125 Kg
                            </div>
                        </div>


                    </div>

                    <div className={"grid grid-cols-3 border-b border-gray-300"}>

                        <div className={"grid  grid-cols-2"}>
                            <div className={"bg-gray-200 font-proximaBold text-sm py-4 px-2"}>
                                Cost Price Per Bag
                            </div>

                            <div className={"text-center py-2"}>
                                GHS {props?.data?.loan?.cost_price_per_bag}
                            </div>
                        </div>


                        <div className={"grid  grid-cols-2"}>
                            <div className={"bg-gray-200 font-proximaBold text-sm py-4 px-2"}>
                                Profit Per Bag
                            </div>

                            <div className={"text-center py-2"}>
                                GHS {props?.data?.loan?.profit_per_bag}
                            </div>
                        </div>

                        <div className={"grid  grid-cols-2"}>
                            <div className={"bg-gray-200 font-proximaBold text-sm py-4 px-2"}>
                                Cost Price Per Bag
                            </div>

                            <div className={"text-center py-2"}>
                                GHS {props?.data?.loan?.cost_price_per_bag}
                            </div>
                        </div>

                    </div>


                    <div className={"grid grid-cols-2 border-b border-gray-300"}>

                        <div className={"grid grid-cols-2"}>
                            <div className={"bg-gray-200 font-proximaBold text-sm py-4 px-2"}>
                                Total Profit
                            </div>

                            <div className={"text-center py-2"}>
                                GHS {formatCurrency(props?.data?.loan?.expected_total_profit, 2)}
                            </div>
                        </div>

                        <div className={"grid  grid-cols-2"}>
                            <div className={"bg-gray-200 font-proximaBold text-sm py-4 px-2"}>
                                Javolin's Profit
                            </div>

                            <div className={"text-center py-2"}>
                                GHS {formatCurrency(props?.data?.loan?.expected_total_javolin_profit, 2)}
                            </div>
                        </div>

                    </div>


                    <div className={"text-sm flex items-center font-proximaBold my-3"}>
                        <div className={"h-2 mx-2 w-2 bg-green-600 rounded-full"}/>
                        Actual Details
                    </div>

                    <div className={"grid grid-cols-2 border-b border-gray-300"}>

                        <div className={"grid grid-cols-2"}>
                            <div className={"bg-gray-200 font-proximaBold text-sm py-4 px-2"}>
                                Actual Number of Bags
                            </div>

                            <div className={"text-center py-2"}>
                                {props?.data?.loan?.actual_number_of_bags} Bags
                            </div>
                        </div>


                        <div className={"grid grid-cols-2"}>
                            <div className={"bg-gray-200 font-proximaBold text-sm py-4 px-2"}>
                                Javolin' Profit ( From Actual Bags )
                            </div>

                            <div className={"text-center py-2"}>
                                GHS {formatCurrency((props?.data?.loan?.total_repaid_amount - props?.data?.loan?.total_disbursed_amount), 2)}
                            </div>
                        </div>

                    </div>

                    <div className={"grid grid-cols-2 border-b border-gray-300"}>


                        <div className={"grid grid-cols-2"}>
                            <div className={"bg-gray-200 font-proximaBold text-sm py-4 px-2"}>
                                Total Disbursed Amount
                            </div>

                            <div className={"text-center py-2"}>
                                GHS {formatCurrency(props?.data?.loan?.total_disbursed_amount, 2)}
                            </div>
                        </div>

                        <div className={"grid grid-cols-2"}>
                            <div className={"bg-gray-200 font-proximaBold text-sm py-4 px-2"}>
                                Total Repaid Amount
                            </div>

                            <div className={"text-center py-2"}>
                                GHS {formatCurrency(props?.data?.loan?.total_repaid_amount, 2)}
                            </div>
                        </div>

                        {/*<div className={"grid grid-cols-2"}>*/}
                        {/*    <div className={"bg-gray-200 font-proximaBold text-sm py-4 px-2"}>*/}
                        {/*        Transaction Repaid Amount ( This Transaction )*/}
                        {/*    </div>*/}

                        {/*    <div className={"text-center py-2"}>*/}
                        {/*        GHS 500*/}
                        {/*    </div>*/}
                        {/*</div>*/}

                    </div>

                    {/*<div className={"grid grid-cols-1 border-b border-gray-300"}>*/}

                    {/*    <div className={"grid grid-cols-2"}>*/}
                    {/*        <div className={"bg-gray-200 font-proximaBold text-sm py-4 px-2"}>*/}
                    {/*            Payment Towards Previous Transactions*/}
                    {/*        </div>*/}

                    {/*        <div className={"text-center py-2"}>*/}
                    {/*            <span>1. <a className={"text-blue-500 underline cursor-pointer"}>JAV20202002</a> (GHS 20.00)</span><br/>*/}
                    {/*            <span>2. <a className={"text-blue-500 underline cursor-pointer"}>JAV20202002</a> (GHS 20.00)</span><br/>*/}
                    {/*        </div>*/}
                    {/*    </div>*/}
                    {/*</div>*/}


                    <div className={"text-sm flex items-center font-proximaBold my-3"}>
                        <div className={"h-2 mx-2 w-2 bg-green-600 rounded-full"}/>
                        Payments
                    </div>

                    <div className={"px-2"}>
                        <Table
                            ref={tableRef}
                            columns={["#", "Transaction Type", "From Bank", "To Bank", "Amount","Timestamp"]}
                            tag={`transactions.summary.table.${props?.data?.loan?.id}`}
                            link={`admin-portal/transaction-payments?transaction_id=${props?.data?.loan?.id}`}
                            fields={["id","transaction_type",{
                                render: (content,index) => {
                                    return (
                                        <td className={"text-center"}>
                                            {content.payment_medium_provider}
                                            [{content.payment_medium_account_number}]
                                        </td>
                                    )
                                }
                            },{
                                render: (content,index) => {
                                    return (
                                        <td className={"text-center"}>
                                            {content.received_account_provider}
                                            [{content.received_account_number}]
                                        </td>
                                    )
                                }
                            },{
                                render: (content,index) => {
                                    return (
                                        <td className={"text-center"}>
                                            {content.currency} {formatCurrency(content.amount)}
                                        </td>
                                    )
                                }
                            },"created_at"]}
                        />
                    </div>

                    <div className={"text-sm flex items-center font-proximaBold my-3"}>
                        <div className={"h-2 mx-2 w-2 bg-green-600 rounded-full"}/>
                        Notes
                    </div>


                    <div className={"grid grid-cols-2 border-b border-gray-300"}>

                        <div className={"col-span-2 grid grid-cols-2"}>

                            <div className={"bg-gray-200 flex items-center font-proximaBold text-sm py-4 px-2"}>
                                Notes
                            </div>

                            <div className={"text-center"}>
                                <textarea
                                    onChange={(event) => {
                                        props?.onSaveChanged(event.target.value)
                                        setForm({
                                            ...form,
                                            notes: event.target.value
                                        })
                                    }}
                                    placeholder={"Enter Any comment here"}
                                    className={"w-full outline-none p-2 h-full"}
                                    name={"notes"}
                                    value={form.notes}
                                />
                            </div>

                        </div>

                    </div>




                </div>
            }

            {
                props.data && props.data.module === "FOREX" &&
                (
                    <div>
                        <div className={"text-sm flex items-center font-proximaBold my-3"}>
                            <div className={"h-2 mx-2 w-2 bg-green-600 rounded-full"}/>
                            Forex Details
                        </div>

                        <div className={"grid grid-cols-2 border-b border-gray-300"}>

                            <div className={"grid  grid-cols-2"}>
                                <div className={"bg-gray-200 font-proximaBold text-sm py-4 px-2"}>
                                    Initiated
                                </div>

                                <div className={"text-center py-2"}>
                                    {props.data.tracking[0] && props.data.tracking[0]["process"][0]["creator"]["full_name"]}
                                </div>
                            </div>

                            <div className={"grid  grid-cols-2"}>
                                <div className={"bg-gray-200 font-proximaBold text-sm py-4 px-2"}>
                                    Role
                                </div>

                                <div className={"text-center py-2"}>
                                    Super Admin
                                </div>
                            </div>

                        </div>

                        <div className={"grid grid-cols-2 border-b border-gray-300"}>

                            <div className={"grid  grid-cols-2"}>
                                <div className={"bg-gray-200 font-proximaBold text-sm py-4 px-2"}>
                                    Beneficiary Account
                                </div>

                                <div className={"text-center py-2"}>
                                    {props.data.corepayOrder.beneficiary && props.data.corepayOrder.beneficiary.name}
                                </div>
                            </div>

                            <div className={"grid  grid-cols-2"}>
                                <div className={"bg-gray-200 font-proximaBold text-sm py-4 px-2"}>
                                    Beneficiary Bank Account
                                </div>

                                <div className={"text-center py-2"}>
                                    {props.data.corepayOrder.beneficiary && props.data.corepayOrder.beneficiary.account_number}
                                    {" "}[{props.data.corepayOrder.beneficiary && props.data.corepayOrder.beneficiary.bank_name}]
                                    {" "}({<span className={"text-sm border px-1 py-0.5 font-proximaBold text-white rounded mx-1 bg-blue-600"}>{props.data.corepayOrder.beneficiary && props.data.corepayOrder.beneficiary.currency}</span>})
                                </div>
                            </div>

                        </div>


                        <div className={"grid grid-cols-3 border-b border-gray-300"}>

                            <div className={"grid  grid-cols-2"}>
                                <div className={"bg-gray-200 font-proximaBold text-sm py-4 px-2"}>
                                    Selling
                                </div>

                                <div className={"text-center py-2 flex items-center justify-center"}>
                                    {props.data.corepayOrder.sender && JSON.parse(props.data.corepayOrder.sender).currency}
                                    {" "}{props.data.corepayOrder.sender && JSON.parse(props.data.corepayOrder.sender)?.amount?.toFixed(2)}
                                </div>
                            </div>

                            <div className={"grid  grid-cols-2"}>
                                <div className={"bg-gray-200 font-proximaBold text-sm py-4 px-2"}>
                                    Buying
                                </div>

                                <div className={"text-center py-2 items-center flex justify-center"}>
                                    {props.data.corepayOrder.recipient && JSON.parse(props.data.corepayOrder.recipient).currency}
                                    {" "}{props.data.corepayOrder.recipient && JSON.parse(props.data.corepayOrder.recipient)?.amount?.toFixed(2)}
                                </div>
                            </div>

                            <div className={"grid  grid-cols-2"}>
                                <div className={"bg-gray-200 font-proximaBold text-sm py-4 px-2"}>
                                    Fee
                                </div>

                                <div className={"text-center py-2 flex items-center justify-center"}>
                                    {props.data.corepayOrder.sender && JSON.parse(props.data.corepayOrder.sender).currency}
                                    {" "}{props.data.corepayOrder.sender && JSON.parse(props.data.corepayOrder.sender)?.fee?.toFixed(2)}

                                </div>
                            </div>


                        </div>

                        <div className={"grid grid-cols-1 border-b border-gray-300"}>

                            <div className={"grid grid-cols-4"}>
                                <div className={"bg-gray-200 font-proximaBold text-sm py-4 px-2"}>
                                    Business Approvals
                                </div>

                                <div className={"text-center py-2 flex col-span-3 justify-around items-center"}>
                                {props.data.pending_transaction && props.data.pending_transaction.filter(item => item.is_for_admin === 0 && item.approved_at !== null)
                                        .map((item,index) => {
                                        return (
                                            <div> {index + 1}. Kwabena Adu Darkwa[ <span className={"text-xs"}>2021-03-12 10:12:00 </span>]</div>
                                        )
                                    })}
                                </div>



                            </div>

                        </div>

                        <div className={"grid grid-cols-1 border-b border-gray-300"}>

                            <div className={"grid grid-cols-4"}>
                                <div className={"bg-gray-200 font-proximaBold text-sm py-4 px-2"}>
                                    Javolin Approvals
                                </div>

                                <div className={"text-center py-2 flex col-span-3 justify-around items-center"}>
                                    {props.data.pending_transaction && props.data.pending_transaction.
                                        filter(item => item.is_for_admin === 1 && item.approved_at !== null)
                                        .map((item,index) => {
                                            return (
                                                <div> {index + 1}. Kwabena Adu Darkwa[ <span className={"text-xs"}>2021-03-12 10:12:00 </span>]</div>
                                            )
                                        })}
                                </div>
                            </div>

                        </div>
                    </div>

                )
            }













        </div>
    )
}