import TransactionTracking from "./tracking";
import Table from "../../../../Shared/Components/Table";
import JavButton from "../../../../Shared/Components/Buttons/JavButton";
import UploadDocumentDialog from "./uploadDocumentDialog";
import {useEffect, useRef, useState} from "react";
import DownloadIcon from "../../../../Shared/Components/Icons/DownladIcon";
import {buildQueryParamFromForm, downloadFile} from "../../../../Shared/Utils/common";

export default function TransactionDocument(props) {

    const tableRef = useRef();
    const [showUploadDocument,setShowUploadDocument] = useState(false);
    const link = `admin-portal/documents?reference=${props?.data?.module}&reference_number=${props?.data?.reference}`;

    useEffect(() => {
        console.log("props",props)
    },[props])

    const onUploadDocumentClicked = () => {
        setShowUploadDocument(true);
    }

    return (
        <div className={"bg-gray-50 p-2"}>

            <UploadDocumentDialog
                transaction={props?.data}
                open={showUploadDocument}
                title={"Upload Transaction Document"}
                onCloseClicked={() => {
                    setShowUploadDocument(false)
                    tableRef.current.reloadTable(`${link}&${buildQueryParamFromForm({})}`)
                }}
            />

            <div className={"flex mb-5 justify-end"}>
                <JavButton
                    onClick={onUploadDocumentClicked}
                    title={"Upload Document"}
                    textColor={"text-white"}
                    className={"mt-5"}
                />
            </div>

            <Table
                ref={tableRef}
                columns={["No","Document Type","Remarks","Uploaded By","Timestamp","Action"]}
                link={link}
                tag={"documents.table"}
                fields={[{
                    render: (content,index) => {
                        return (
                            <td className={"text-center"}>
                                {index + 1}
                            </td>
                        )
                    }
                },{
                    render: (content) => {
                        return (
                            <div className={"flex h-10 items-center justify-center"}>
                                {content?.document_type?.code_name}
                            </div>
                        )
                    }
                },"meta.remarks","uploader.name","created_at",{
                    render: (content) => {
                        return (
                            <div className={"flex items-center justify-center"}>
                                <JavButton onClick={() => {
                                    downloadFile(`admin-portal/documents/${content?.id}/download`,null,content?.name).then(r => {
                                        // setExportDownloading(null);
                                    })
                                }} className={"p-1"} bgColor={"bg-gray-200 "}>
                                    <DownloadIcon />
                                </JavButton>
                            </div>
                        )

                    }
                }]}
            />

        </div>
    )
}