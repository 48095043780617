import {combineReducers} from "redux"
import {AuthReducer} from "../Modules/Login/duck/reducer";
import {DashboardReducer} from "../Modules/Dashboard/duck/reducer";
import {TableReducer} from "../Shared/Components/Table/duck/reducer";
import {PendingTransactionReducer} from "../Modules/PendingTransactions/duck/reducer";
import {TransactionReducer} from "../Modules/Transactions/duck/reducer";
import {BusinessKYCReducer} from "../Modules/Businesses/kyc/duck/reducer";
import {FeesReducer} from "../Modules/Fees/duck/reducer";
import {ListSelectReducer} from "../Shared/Components/Dialog/ListSelectDialog/duck/reducer";
import {AccountReducer} from "../Modules/AccountManagement/duck/reducer";
import {SettingsReducer} from "../Modules/Settings/duck/reducer";
import {FormsReducer} from "../Shared/Components/Forms/duck/reducer";
import {CommonsReducer} from "../Modules/Commons/duck/reducer";
import {ConnectReducer} from "../Modules/Connect/duck/reducer";

const reducers = combineReducers({
    auth: AuthReducer,
    dashboard: DashboardReducer,
    table: TableReducer,
    list: ListSelectReducer,
    forms: FormsReducer,
    commons: CommonsReducer,
    pendingTransactions: PendingTransactionReducer,
    transactions: TransactionReducer,
    kyc: BusinessKYCReducer,
    fees: FeesReducer,
    account: AccountReducer,
    settings: SettingsReducer,

    connect: ConnectReducer


});


export default reducers;