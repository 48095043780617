import * as React from "react"

const AccountIcon = (props) => (
    <svg
        width={20}
        height={20}
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <path
            d="M18.333 10c0-4.591-3.741-8.333-8.333-8.333-4.592 0-8.333 3.742-8.333 8.333a8.312 8.312 0 0 0 2.691 6.117c0 .008 0 .008-.008.017.083.083.183.15.267.225.05.041.091.083.141.116.15.125.317.242.475.359l.167.116c.158.109.325.209.5.3l.183.109c.167.091.342.175.525.25.067.033.134.066.2.091.184.075.367.142.55.2.067.025.134.05.2.067.2.058.4.108.6.158.059.017.117.034.184.042.233.05.466.083.708.108.033 0 .066.009.1.017.283.025.566.042.85.042.283 0 .566-.017.841-.042a.415.415 0 0 0 .1-.017c.242-.025.475-.058.709-.108.058-.008.117-.033.183-.042.2-.05.409-.091.6-.158.067-.025.133-.05.2-.067.184-.066.375-.125.55-.2.067-.025.133-.058.2-.091.175-.075.35-.159.525-.25.067-.034.125-.075.184-.109.166-.1.333-.191.5-.3.058-.033.108-.075.166-.116.167-.117.325-.234.475-.359.05-.041.092-.083.142-.116.091-.075.183-.15.267-.225 0-.009 0-.009-.009-.017a8.288 8.288 0 0 0 2.7-6.117Zm-4.216 4.142c-2.259-1.517-5.959-1.517-8.234 0a4.026 4.026 0 0 0-.917.833A7.067 7.067 0 0 1 2.917 10 7.088 7.088 0 0 1 10 2.917 7.088 7.088 0 0 1 17.083 10a7.067 7.067 0 0 1-2.05 4.975 3.858 3.858 0 0 0-.916-.833Z"
        />
        <path
            d="M10 5.775A3.126 3.126 0 0 0 6.875 8.9c0 1.692 1.325 3.067 3.083 3.117H10.108A3.12 3.12 0 0 0 13.125 8.9c0-1.725-1.4-3.125-3.125-3.125Z"
        />
    </svg>
)

export default AccountIcon
