import Table from "../../../../../Shared/Components/Table";
import JavButton from "../../../../../Shared/Components/Buttons/JavButton";
import {useEffect, useRef, useState} from "react";
import CreateFunctionDialog from "./components/CreateFunctionDialog";
import {useSelector} from "react-redux";
import {buildQueryParamFromForm} from "../../../../../Shared/Utils/common";
import CreateConfigDialog from "./components/CreateConfigDialog";

export default function ConnectChannelDetailFunctionsAndConfigs() {

    const [openFunctionsDialog,setOpenFunctionsDialog] = useState(false);
    const [openConfigDialog,setOpenConfigDialog] = useState(false);
    const connectState = useSelector( (state) => state.connect);
    const functionTableRef = useRef();
    const configTableRef = useRef();


    useEffect(() => {
        console.log("channelId is ",connectState?.fetch_channels)

        functionTableRef.current.reloadTable(`admin-portal/connect/channels/${connectState?.fetch_channels?.data?.id}/concerns?${buildQueryParamFromForm({
            concern: "functions"
        })}`);

        configTableRef.current.reloadTable(`admin-portal/connect/channels/${connectState?.fetch_channels?.data?.id}/concerns?${buildQueryParamFromForm({
            concern: "configs"
        })}`);


    }, [connectState?.fetch_channels?.data?.id]);


    return (
        <div>

            <CreateFunctionDialog
                channel={connectState?.fetch_channels?.data}
                open={openFunctionsDialog}
                onCloseClicked={() => setOpenFunctionsDialog(false)}
            />

            <CreateConfigDialog
                channel={connectState?.fetch_channels?.data}
                open={openConfigDialog}
                onCloseClicked={() => setOpenConfigDialog(false)}
            />

            <div className={"flex items-center mt-5 px-2 justify-between"}>

                <div className={""}>
                    <h3 className={"font-firmaBold"}>Functions</h3>
                    <span className={"text-sm text-gray-600"}>View and manage the Functions attached to this app.</span>
                </div>

                <JavButton
                    title={"Create Function"}
                    textColor={"text-white"}
                    onClick={() => setOpenFunctionsDialog(true)}
                />

            </div>

            <div className="dark:bg-[#242A38] bg-white dark:border-gray-500 border my-2 p-2 mb-4 rounded-lg mx-2">
                <Table
                    columns={["Id","Reference", "Event", "Mode", "Timestamp", "Action"]}
                    tag={"table.functions"}
                    fields={["id",{
                        render: (content) => {
                            return (
                                <td className={"text-center"}>
                                    <a className={"underline text-blue-500 cursor-pointer"} onClick={() => {}} >{content?.meta?.reference}</a>
                                </td>
                            )
                        }
                    },"meta.event","meta.mode","created_at",{
                        render: () => {
                            return (
                                <td className={"text-center"}>
                                    <JavButton title={"delete"} padding={"px-2 py-1"} textColor={"text-white"} bgColor={"bg-red-600"} />
                                </td>
                            )
                        }
                    }]}
                    ref={functionTableRef}
                    link={``}
                />
            </div>


            <div className={"flex items-center mt-10 px-2 justify-between"}>

                <div className={""}>
                    <h3 className={"font-firmaBold"}>Config Variables</h3>
                    <span className={"text-sm text-gray-600"}>View and manage the config variables.</span>
                </div>

                <JavButton
                    title={"Create Config Variable"}
                    textColor={"text-white"}
                    onClick={() => setOpenConfigDialog(true)}
                />

            </div>

            <div className="dark:bg-[#242A38] bg-white dark:border-gray-500 border my-2 p-2 mb-4 rounded-lg mx-2">
                <Table
                    ref={configTableRef}
                    tag={"table.config-variables"}
                    fields={["id","meta.name","meta.description","meta.content","created_at",{
                        render: () => {
                            return (
                                <td className={"text-center"}>
                                    <JavButton title={"delete"} padding={"px-2 py-1"} textColor={"text-white"} bgColor={"bg-red-600"} />
                                </td>
                            )
                        }
                    }]}
                    columns={["Id", "Name", "Description", "Value", "Timestamp", "Action"]}
                />
            </div>


        </div>
    )
}