export const ActionTypes = {

    //update account details
    REQUEST_UPDATE_ACCOUNT_DETAILS: "REQUEST_UPDATE_ACCOUNT_DETAILS",
    UPDATE_ACCOUNT_DETAILS_SUCCESS: "UPDATE_ACCOUNT_DETAILS_SUCCESS",
    UPDATE_ACCOUNT_DETAILS_ERROR: "UPDATE_ACCOUNT_DETAILS_ERROR",


    //change Password
    REQUEST_RECEIVABLE_NOTIFICATION_CHANGE_PASSWORD: "REQUEST_RECEIVABLE_NOTIFICATION_CHANGE_PASSWORD",
    CHANGE_PASSWORD_RECEIVABLE_NOTIFICATION_SUCCESS: "CHANGE_PASSWORD_RECEIVABLE_NOTIFICATION_SUCCESS",
    CHANGE_PASSWORD_RECEIVABLE_NOTIFICATION_ERROR: "CHANGE_PASSWORD_RECEIVABLE_NOTIFICATION_ERROR",

    //fetch
    REQUEST_RECEIVABLE_NOTIFICATION_FETCH: "REQUEST_RECEIVABLE_NOTIFICATION_FETCH",
    FETCH_RECEIVABLE_NOTIFICATION_SUCCESS: "FETCH_RECEIVABLE_NOTIFICATION_SUCCESS",
    FETCH_RECEIVABLE_NOTIFICATION_ERROR: "FETCH_RECEIVABLE_NOTIFICATION_ERROR",


    //toggle
    REQUEST_RECEIVABLE_NOTIFICATION_TOGGLE: "REQUEST_RECEIVABLE_NOTIFICATION_TOGGLE",
    TOGGLE_RECEIVABLE_NOTIFICATION_SUCCESS: "TOGGLE_RECEIVABLE_NOTIFICATION_SUCCESS",
    TOGGLE_RECEIVABLE_NOTIFICATION_ERROR: "TOGGLE_RECEIVABLE_NOTIFICATION_ERROR"



}