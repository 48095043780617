export default function DashboardStatItem(props) {
    return (
        <div className={"flex bg-gray-50 cursor-pointer border items-start rounded px-2 py-4"}>

            <div className={"p-2 mr-5 border bg-gray-100 rounded"}>
                { props.icon}
            </div>

            <div className={"flex flex-col"}>
                <h4 className={"font-proxima text-sm font-light text-gray-800"}>{props.title}</h4>
                <h1 className={"font-proximaBold text-xl"}>
                    {props.value}
                </h1>
            </div>

        </div>
    )
}