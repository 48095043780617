import DeleteIcon from "../Icons/DeleteIcon";
import JavButton from "../Buttons/JavButton";
import CloseIcon from "../Icons/CloseIcon";
import {useEffect, useRef, useState} from "react";
import {uploadResource} from "../../../Modules/Transactions/duck/action";

export default function JavFormFileInput (props) {

    const fileRef = useRef();
    // const [data,setData] = useState(null);
    // const [metaData,setMetaData] = useState(null);

    const onChooseFileClicked = () => {
        fileRef.current.click();
    }

    function getBase64(file,onSuccess,onError) {
        let reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () =>  onSuccess(reader.result);
        reader.onerror = (error) => onError(error);
    }

    const onFileChange = async (event) => {
        console.log("event",event);
        getBase64(event.target.files[0],(mData) => {
            props.onChange({
                file: mData,
                meta: event.target.files[0]
            });
            //upload the file
            // dispatch(uploadResource({file: mData}))

        },(error) => {
            alert("Error: "+error)
        })
    }




    return (
        <div>
            <h3 className={`text-sm font-proxima text-gray-600 ${props.isColumn ? 'w-2/5 bg-gray-100 dark:bg-[#242A38] flex items-center px-2' : ''}`}>{props.title}</h3>
            <div className={`border w-full py-1 px-2  rounded flex justify-between items-center ${props.className}`}>

                <input onChange={onFileChange} ref={fileRef} type={"file"} className={"hidden"}/>

                <div>
                    {
                        props.data &&
                        (
                            <div className={"text-sm"}>
                                {props.meta.name}
                            </div>
                        )
                    }

                    {
                        !props.data &&
                        (
                            <div onClick={onChooseFileClicked} className={"border text-xs px-2 bg-gray-500 text-gray-100 cursor-pointer font-bold rounded py-1.5"}>
                                Choose File
                            </div>
                        )
                    }
                </div>

                {
                    props.data &&
                    <div onClick={() => {
                        props.onChange({
                            file: null,
                            meta: null
                        });
                    }} className={"cursor-pointer"} bgColor={"bg-gray-200"}>
                        <CloseIcon/>
                    </div>
                }



            </div>
        </div>
    )
}