import {useParams} from "react-router";
import Table from "../../Shared/Components/Table";
import {useNavigate} from "react-router-dom";
import {EyeIcon} from "@heroicons/react/24/outline";
import ViewCollectionPaymentDetailsDialog from "./components/ViewCollectionPaymentDetailsDialog";
import {useState} from "react";

export default function CollectionDetails(props) {

    const {id} = useParams();
    const navigate = useNavigate();
    const [open,setOpen] = useState(false);
    const [selectedItem, setSelectedItem] = useState(null);

    return (
        <div className={"p-2"}>

            <div className={"py-2"}>
                <h2>List of Transactions</h2>
            </div>

            <ViewCollectionPaymentDetailsDialog
                open={open}
                selectedItem={selectedItem}
                onCloseClicked={() => {
                    setOpen(false);
                }}
            />

            <div
                className={"dark:bg-[#242A38] col-span-2 bg-white dark:border-gray-500 border py-2 mb-4 rounded-xl w-full shadow-sm px-2"}>
                <Table
                    columns={[
                        "id", "reference", "Amount","kyc done?", "has paid", "created_at", "action"
                    ]}
                    fields={["id", {
                        render: (content) => {
                            return (
                                <td onClick={() => navigate(`/collections/${content?.id}`)}
                                    className={"underline cursor-pointer text-center text-blue-500"}>
                                    {content?.reference}
                                </td>
                            )
                        }
                    }, {
                        render: (content) => {
                            return (
                                <td className={"text-center"}>
                                    GHS {content?.amount}
                                </td>
                            )
                        }
                    },{
                        render: (content) => {
                            return (
                                <td className={"text-center"}>
                                    Yes
                                </td>
                            )
                        }
                    },{
                        render: (content) => {
                            return (
                                <td className={"text-center"}>
                                    {content?.meta?.payment_done}
                                </td>
                            )
                        }
                    },"created_at", {
                        render: (content) => {
                            return (
                                <td className={"text-center"}>
                                    <button onClick={() => {
                                        setSelectedItem(content)
                                        setOpen(true)
                                    }}>
                                        <EyeIcon className={"w-5"}/>
                                    </button>
                                </td>
                            )
                        }
                    }]}
                    link={`admin-portal/collection-link-payments?collection_id=${id}`}
                    tag={"documents.table"}
                />
            </div>


        </div>
    )
}