import { ActionTypes } from "./type";
import { makeJavolinRequest } from "../../../Shared/Utils/common";


export const fetchChannelDetails = (id) => {
    return async function (dispatch) {
        dispatch({ type: ActionTypes.CHANNEL_DETAIL_REQUEST });
        makeJavolinRequest({
            path: `admin-portal/connect/channels/${id}`,
            method: "GET"
        }, {
            SUCCESS: ActionTypes.CHANNEL_DETAIL_SUCCESS,
            ERROR: ActionTypes.CHANNEL_DETAIL_ERROR
        }, dispatch);
    }
}


