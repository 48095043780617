import CloseIcon from "../../../Shared/Components/Icons/CloseIcon";
import {useEffect, useState} from "react";
import JavInput from "../../../Shared/Components/Forms/JavInput";
import JavButton from "../../../Shared/Components/Buttons/JavButton";
import {useDispatch, useSelector} from "react-redux";
import {createCode, createCodeGroup} from "../duck/action";

export default function AddCommonCodeDialog(props) {

    const dispatch = useDispatch();
    const commonsState = useSelector((state) => state.commons)


    useEffect(() => {

    },[])


    const [form,setForm] = useState({
        code_name: "",
        code_option: ""
    });


    const handleChanges = (event) => {
        const value = event.target.value;
        setForm({
            ...form,
            [event.target.name]: value
        })
    }

    const onCreateLetterTapped = () => {
        dispatch(createCode(props.group.id,form));
    }

    if (!!!props.open ) {
        return <></>;
    }

    return (
        <div className={`absolute flex z-10 justify-center
                        items-center p-2 top-0 left-0
                        w-screen h-screen bg-gray/10
                        backdrop-blur-sm`}>

            <div className={"min-w-[60%] lg:min-w-[30%] bg-white border rounded"}>

                <div className="border-b py-3 px-2 flex
                                items-center justify-between">

                    <h3 className="text-sm font-proximaBold
                                   text-blue-900 text-gray-700">
                        { props.title || "Create Common Code Group" }
                    </h3>

                    <CloseIcon onClick={props.onCloseClicked} className={"cursor-pointer"}/>

                </div>


                <div className={"grid grid-cols-1 mb-2 dark:bg-[#242A38] dark:border-gray-600 gap-x-3 gap-y-4 py-4 px-4 my-2"}>

                    <JavInput
                        title={"Group Id"}
                        name={"group"}
                        disabled={true}
                        value={`${props.group.code} - ${props.group.name}`}
                    />

                    <JavInput
                        title={"Name"}
                        name={"code_name"}
                        value={form.code_name}
                        required={true}
                        onChange={handleChanges}
                    />

                    <JavInput
                        title={"Option"}
                        name={"code_option"}
                        value={form.code_option}
                        onChange={handleChanges}
                    />



                    <div className={"flex justify-center mt-5"}>

                        <JavButton
                            title={"Create Common Group"}
                            padding={"px-16 py-2"}
                            textColor={"text-white"}
                            isLoading={commonsState?.create_code?.loading}
                            onClick={onCreateLetterTapped}
                        />

                    </div>


                </div>






            </div>
        </div>
    )
}