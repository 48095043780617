import * as React from "react"

const TaxTypeIcon = (props) => (
    <svg
        width={20}
        height={20}
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <path
            d="M16.525 7.25 12.75 3.475a3.959 3.959 0 0 0-3-1.158l-4.167.2a3.22 3.22 0 0 0-3.075 3.058l-.2 4.167a3.99 3.99 0 0 0 1.159 3l3.775 3.775a3.984 3.984 0 0 0 5.625 0l3.658-3.659a3.948 3.948 0 0 0 0-5.608Zm-8.608 3.067a2.4 2.4 0 1 1 0-4.801 2.4 2.4 0 0 1 0 4.8Z"
        />
    </svg>
)

export default TaxTypeIcon
