import JavInput from "../../../Shared/Components/Forms/JavInput";
import JavButton from "../../../Shared/Components/Buttons/JavButton";
import {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {changeAccountPassword} from "../duck/action";
import {logout} from "../../Login/duck/action";
import {useAlert} from "../../../Shared/Context/AlertContext";

export default function AccountSecurity() {

    const settingsState = useSelector( (state) => state.settings)
    const dispatch = useDispatch();
    const [form,setForm] = useState({
        current_password: "",
        new_password: "",
        confirm_new_password: ""
    });
    const { showAlert } = useAlert();

    const handleChanges = (event) => {
        const value = event.target.value;
        setForm({
            ...form,
            [event.target.name]: value
        })
    }

    const changePassword = () => {
        dispatch(changeAccountPassword(form));
    }

    useEffect(() => {
        if (settingsState?.change_password?.error) {
            showAlert(settingsState.change_password?.error,"red");
        }
        if (settingsState.change_password?.success) {
            showAlert("Updated successfully","green");
            dispatch(logout())
        }

    },[settingsState.change_password])

    return (
        <div className={"px-6 py-2"}>



            <h1 className={"text-3xl dark:text-gray-100 text-[#000]/76 font-proximaBold"}>
                Security
            </h1>


            <div className={"col-span-2 mt-4 font-proximaBold"}>
                <h2 className={"text-gray-700 dark:text-gray-200"}>Change Password</h2>
            </div>


            <div className={"grid grid-cols-2 dark:bg-[#242A38] rounded  dark:border-gray-600 gap-x-12 gap-y-4 py-4 my-2"}>


                <div className={"col-span-2 mt-4 font-proximaBold"}>
                    <JavInput
                        title={"Current Password"}
                        name={"current_password"}
                        value={form.current_password}
                        type={"password"}
                        onChange={handleChanges}
                    />
                </div>

                <JavInput
                    title={"New Password"}
                    name={"new_password"}
                    value={form.new_password}
                    type={"password"}
                    onChange={handleChanges}
                />

                <JavInput
                    title={"Confirm New Password"}
                    name={"confirm_new_password"}
                    value={form.confirm_new_password}
                    type={"password"}
                    onChange={handleChanges}
                />


                <div className={"col-span-2 flex justify-end my-1"}>

                    <JavButton
                        title={"Change Password"}
                        padding={"px-16 py-3"}
                        textColor={"text-white"}
                        isLoading={settingsState.change_password && settingsState.change_password.loading}
                        onClick={changePassword}
                    />

                </div>

            </div>

            <hr/>
            <div className={"col-span-2 mt-12 font-proximaBold"}>
                <h2 className={"text-gray-700 dark:text-gray-200"}>Two-Factor Authentication (<span className={"text-xs mx-2 border rounded bg-yellow-100 p-0.5"}> Disabled </span>)</h2>
            </div>
            <span className={"text-sm my-2 darK:text-gray-200 text-gray-500"}>
                Enabling this will provide an extra layer of security for your account.
                When logging in, We will ask for a special authentication code from your mail
            </span>

            <JavButton
                title={`Enable Two factor Authentication (Coming soon)`}
                padding={"px-16 py-3"}
                textColor={"text-white"}
                className={"mt-5"}
                disabled={true}
                onClick={() => {}}
            />




        </div>
    )
}