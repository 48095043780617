import TerminalIcon from "../../../../../Shared/Components/Icons/TerminalIcon";
import ArrowRightIcon from "../../../../../Shared/Components/Icons/Arrow/ArrlowRightIcon";
import DashboardStatItem from "../../../../Transactions/Components";
import DownloadIcon from "../../../../../Shared/Components/Icons/DownladIcon";
import Table from "../../../../../Shared/Components/Table";
import {useSelector} from "react-redux";
import {useNavigate} from "react-router-dom";
import {useEffect, useRef, useState} from "react";
import {buildQueryParamFromForm} from "../../../../../Shared/Utils/common";
import MessageTimeLapseDialog from "./components/MessageTimeLapseDialog";

export default function ConnectChannelDetailOverview() {

    const tableRef = useRef();
    const connectState = useSelector( (state) => state.connect);
    const navigate = useNavigate();
    const tableState = useSelector( (state) => state.table)
    const [open,setOpen] = useState(false);


    useEffect(() => {
        console.log("channelId is ",connectState?.fetch_channels)
        tableRef.current.reloadTable(`admin-portal/connect/channels/${connectState?.fetch_channels?.data?.id}/messages?${buildQueryParamFromForm({})}`);
    }, [connectState?.fetch_channels?.data?.id]);

    return (
        <div className={"px-2"}>



            <div className={"grid grid-cols-3 mt-5 gap-5"}>
                <DashboardStatItem title={"Total Transactions"} value={tableState['table.message-list']?.data?.summaries?.total_messages}
                                   icon={<DownloadIcon className={"h-4 w-4 stroke-1 stroke-black"}/>}/>
                <DashboardStatItem title={"Un Acknowledge Messages"} value={tableState['table.message-list']?.data?.summaries?.total_un_acknowledge_messages}
                                   icon={<DownloadIcon className={"h-4 w-4 stroke-1 stroke-black"}/>}/>
                <DashboardStatItem title={"Error Messages"} value={tableState['table.message-list']?.data?.summaries?.total_error_messages}
                                   icon={<DownloadIcon className={"h-4 w-4 stroke-1 stroke-black"}/>}/>

                {
                    tableState['table.message-list']?.data?.summaries?.senders?.map(item => {
                       return (
                           <DashboardStatItem title={`${item?.sender?.name} Messages`} value={item?.messages}
                                              icon={<DownloadIcon className={"h-4 w-4 stroke-1 stroke-black"}/>}/>
                       )
                    })
                }

            </div>


            <div className={"mt-10 text-xl font-proximaBold text-gray-700"}>
                Message List
            </div>
            <div
                className={"dark:bg-[#242A38] col-span-2 bg-gray-50 dark:border-gray-500 border py-2 mb-4 rounded-xl w-full shadow-sm px-2"}>
                <Table
                    tag={"table.message-list"}
                    ref={tableRef}
                    title={"Message List"}
                    columns={[
                        "id", "reference", "sender", "content", "status", "created_at"
                    ]}
                    fields={["id","reference","sender.name","body","status","created_at"]}
                />

            </div>


        </div>
    )
}