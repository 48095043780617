import './App.css';
import {BrowserRouter as Router, Navigate, Route, Routes} from "react-router-dom";
import AppLayout from "./Modules/Layouts/AppLayout";
import Login from "./Modules/Login";
import {useSelector} from "react-redux";
import {Component} from "react";
import Dashboard from "./Modules/Dashboard";
import PendingTransactions from "./Modules/PendingTransactions";
import ApiAccount from "./Modules/ApiAccounts";
import TransactionDetail from "./Modules/Transactions/details";
import Businesses from "./Modules/Businesses";
import Transactions from "./Modules/Transactions";
import BusinessDetail from "./Modules/Businesses/detail";
import KYC from "./Modules/Businesses/kyc";
import Fees from "./Modules/Fees";
import FeeDetails from "./Modules/Fees/detail";
import Notifications from "./Modules/Notifications";
import OnBoardBusiness from "./Modules/Businesses/onBaordBusiness";
import SpotDeals from "./Modules/SpotDeals";
import AccountManagement from "./Modules/AccountManagement";
import Settings from "./Modules/Settings";
import SettlementAccounts from "./Modules/SettlementAccounts";
import Commons from "./Modules/Commons";
import CommonCode from "./Modules/Commons/commonCode";
import ConnectChannels from "./Modules/Connect/Channels";
import ConnectChannelDetails from "./Modules/Connect/Channels/details/details";
import DigitalKYC from "./Modules/DigitalKYC";
import Collections from "./Modules/Collections";
import PaymentScreen from "./Modules/Collections/PaymentScreen";
import CollectionDetails from "./Modules/Collections/detail";


function App() {
  const mSate = useSelector((state) => state);
  const isAuth = !!useSelector((state) => state.auth.login.token);


  return (
      <Router>
        <Routes>

          <Route exact path="/login" element={<Login/>}/>

          <Route exact path="/payment/:id" element={<PaymentScreen/>}/>

          <Route path="/" element={isAuth ? <AppLayout/> : <Navigate to={"/login"} />}>
              <Route path="/dashboard" element={<Dashboard/>}/>

              <Route path="/businesses" element={<Businesses/>}/>
              <Route path="/businesses/:id" element={<BusinessDetail/>}/>
              <Route path="/businesses/kyc/:id" element={<KYC/>}/>
              <Route path="/businesses/account/onboard" element={<OnBoardBusiness/>} />


              {/*Forex & Cross Border*/}
              <Route path="/spot-deals" element={<SpotDeals/>}/>
              <Route path="/settlement-accounts" element={<SettlementAccounts/>}/>


              <Route path="/transactions" element={<Transactions/>}/>
              <Route path="/pending-approvals" element={<PendingTransactions/>}/>
              <Route path="/transaction/:transactionId" element={<TransactionDetail/>} />

              {/*Application*/}
              <Route path="/notifications" element={<Notifications/>}/>


              <Route path="/account-management" element={<AccountManagement/>}/>

              {/*Configs*/}
              <Route path="/fees" element={<Fees/>}/>
              <Route path="/fees/module/:id" element={<FeeDetails/>}/>
              <Route path="/api-accounts" element={<ApiAccount/>}/>
              <Route path="/settings" element={<Settings/>}/>
              <Route path="/commons" element={<Commons/>}/>
              <Route path="/commons/:id" element={<CommonCode/>}/>

              {/*Connect*/}
              <Route path={"/connect/channels"} element={<ConnectChannels/>} />
              <Route path={"/connect/channels/:id"} element={<ConnectChannelDetails/>} />

              <Route path={"/collections"} element={<Collections/>} />
              <Route path={"/collections/:id"} element={<CollectionDetails/>} />



              <Route path={"/digital-kyc"} element={<DigitalKYC/>} />


          </Route>

        </Routes>
      </Router>
  );
}

export default App;
