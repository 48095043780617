import NavBarItem from "./NavBarItem";
import NavTitle from "./NavTitle";
import {useSelector} from "react-redux";
import {PERMISSIONS} from "../../Utils/constants";
import {hasRole} from "../../Utils/common";

export default function NavBar(){

    const authState = useSelector( (state) => state.auth.login)

    return (
        <div className="py-2 grid grid-cols-1 gap-y-1">

            {
                hasRole(PERMISSIONS.VIEW_DASHBOARD) &&
                <NavBarItem link={"dashboard"} title={"Dashboard"} icon={"home"} isActive={true}/>
            }
            {
                hasRole(PERMISSIONS.VIEW_BUSINESSES) &&
                <NavBarItem link={"businesses"} title={"Businesses Partners"}
                            icon={"businesses"} isActive={false}/>
            }

            {
                hasRole(PERMISSIONS.VIEW_TRANSACTIONS) &&
                <NavBarItem link={"transactions"} title={"Transactions"}
                            icon={"businesses"} isActive={false}/>

            }

            <NavTitle title={"forex & cross border"}/>

            {
                hasRole(PERMISSIONS.VIEW_SPOT_RATE) &&
                <NavBarItem link={"spot-deals"} title={"Spot Deals"}
                            icon={"businesses"} isActive={false}/>
            }

            {
                hasRole(PERMISSIONS.VIEW_SPOT_RATE) &&
                <NavBarItem link={"settlement-accounts"} title={"Settlement Accounts"}
                            icon={"banks"} isActive={false}/>
            }


            <NavTitle title={"actions"}/>

            {
                hasRole(PERMISSIONS.VIEW_PENDING_APPROVALS) &&
                <NavBarItem link={"pending-approvals"} title={"Pending Approvals"}
                            icon={"pending_transactions"} isActive={false}/>
            }


            <NavTitle title={"application"}/>

            {
                hasRole(PERMISSIONS.VIEW_NOTIFICATIONS) &&
                <NavBarItem link={"notifications"} title={"Notifications"}
                icon={"businesses"} isActive={false}/>
            }

            <NavTitle title={"Platform Connect"}/>

            <NavBarItem link={"connect/channels"} title={"Channels"}
                        icon={"channel"} isActive={false}/>

            <NavBarItem link={"digital-kyc"} title={"Digital KYC"}
                        icon={"kyc"} isActive={false}/>

            <NavBarItem link={"collections"} title={"Collections"}
                        icon={"collections"} isActive={false}/>


            <NavTitle title={"configs"}/>

            {
                hasRole(PERMISSIONS.VIEW_FEES) &&
                <NavBarItem link={"fees"} title={"Fees"}
                            icon={"businesses"} isActive={false}/>
            }


            {
                hasRole(PERMISSIONS.VIEW_ACCOUNTS) &&
                <NavBarItem link={"account-management"} title={"Account Management"}
                            icon={"pending_transactions"} isActive={false}/>
            }

            {
                hasRole(PERMISSIONS.VIEW_API_KEYS_AND_ACCOUNTS) &&
                <NavBarItem link={"api-accounts"} title={"API Keys/Accounts"}
                            icon={"pending_transactions"} isActive={false}/>
            }

            {
                hasRole(PERMISSIONS.VIEW_ACCOUNTS) &&
                <NavBarItem link={"commons"} title={"Commons"}
                            icon={"group"} isActive={false} />
            }






        </div>
    )
}