import {useNavigate} from "react-router-dom";
import {useParams} from "react-router";
import {useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";
import {fetchChannelDetails} from "../../duck/action";
import Tab from "../../../../Shared/Components/Tabs";
import ConnectChannelDetailOverview from "./tabs/ConnectChannelDetailOverview";
import TerminalIcon from "../../../../Shared/Components/Icons/TerminalIcon";
import ArrowRightIcon from "../../../../Shared/Components/Icons/Arrow/ArrlowRightIcon";
import ConnectChannelDetailSettings from "./tabs/ConnectChannelDetailSettings";
import ConnectChannelDetailFunctionsAndConfigs from "./tabs/ConnectChannelDetailFunctionsAndConfigs";

export default function ConnectChannelDetails(props) {

    const navigate = useNavigate();
    const {id} = useParams();
    const dispatch = useDispatch();
    const connectState = useSelector( (state) => state.connect);

    useEffect(() => {
        dispatch(fetchChannelDetails(id));
    },[id])

    useEffect(() => {
        console.log("connect",connectState)
    }, [connectState]);

    return (

        <div>

            <div className={"flex py-3 items-center"}>

                <div onClick={() => navigate(-1)} className={"bg-blue-500 cursor-pointer text-white p-2 rounded-full"}>
                    <TerminalIcon className={"h-6 w-6 stroke-1"}/>
                </div>
                <ArrowRightIcon className={"h-5 mx-2 stroke-2 text-gray-600 w-5"}/>
                <span
                    className={"font-proximaBold underline"}> [ {connectState?.fetch_channels?.data?.reference} ] - {connectState?.fetch_channels?.data?.name} </span>

            </div>


            <Tab>
                <ConnectChannelDetailOverview title={"Overview"}/>
                {/*<div title={"Developer documentation"}/>*/}
                <ConnectChannelDetailFunctionsAndConfigs title={"Functions & Config Variables"}/>
                <ConnectChannelDetailSettings title={"Channel Settings"}/>
            </Tab>

        </div>

    )
}