import JavInput from "../../../../Shared/Components/Forms/JavInput";
import React, {useEffect, useState} from "react";
import Table from "../../../../Shared/Components/Table";
import JavFormSelect from "../../../../Shared/Components/Forms/JavFormSelect";
import JavButton from "../../../../Shared/Components/Buttons/JavButton";
import {useDispatch, useSelector} from "react-redux";
import {createBusinessUser, fetchBusinessAccounts, fetchBusinessRoles, resetBusinessAccount} from "../duck/action";
import {useParams} from "react-router";
import BlockIcon from "../../../../Shared/Components/Icons/BlockIcon";
import ResetIcon from "../../../../Shared/Components/Icons/ResetIcon";
import YesNoDialog from "../../../../Shared/Components/Dialog/YesNoDialog";


export default function UserRolesAndPermissions(props) {

    const [form,setForm] = useState({
        full_name: "",
        job_title: "",
        dob: "",
        address: "",
        email: "",
        phone_number: "",
        role_id: ""
    });
    const columns = [ "#", "User","Email","Phone Number","Role","Created At","Action"];
    const dispatch = useDispatch();
    const {id} = useParams();
    const kycState = useSelector( (state) => state.kyc);
    const [roles,setRoles] = useState([]);
    const [selectedItem,setSelectedItem] = useState(null);
    const [selectedAction,setSelectedAction] = useState(null);

    const handleChanges = (event) => {
        const value = event.target.value;
        setForm({
            ...form,
            [event.target.name]: value
        })
    }

    useEffect(() => {
        console.log("fetchState",kycState.fetch_roles);
        if (kycState.fetch_roles.success) {
            kycState.fetch_roles.data && setRoles(kycState.fetch_roles.data.map((it) => {
                return {
                    id: it.id,
                    title: it.name
                }
            }));
        }
    },[kycState.fetch_roles])

    useEffect(() => {
        dispatch(fetchBusinessRoles(id));
        dispatch(fetchBusinessAccounts(id));
    },[])

    const onSaveUserTapped = () => {
        dispatch(createBusinessUser({...form,business_id: id}));
    }

    const onContinueTapped = () => {
        props.onContinue();
    }

    return (
        <div>


            <YesNoDialog
                open={selectedItem != null &&
                    (selectedAction === "delete" || selectedAction === "reset")
                }
                title={"Are you sure ?"}
                onYesClicked={() => {
                    if (selectedAction === "reset") {
                        dispatch(resetBusinessAccount(selectedItem.id))
                    }
                }}
                onNoTapped={() => setSelectedItem(null)}
                onCloseClicked={() => setSelectedItem(null)}
            >
                <div className="text-xs py-3">

                    {
                        selectedAction === "reset" &&
                        <span>Are you sure you want to reset account ?</span>
                    }
                </div>
            </YesNoDialog>



            <h2 className={"mx-5 dark:text-white my-2 font-proximaBold"}>
                Add User
            </h2>



            <div className={"grid grid-cols-2 dark:bg-[#242A38] rounded border dark:border-gray-600 gap-x-12 gap-y-4 py-4 px-4 my-2"}>

                <JavInput
                    title={"Full Legal Name"}
                    name={"full_name"}
                    onChange={handleChanges}
                    value={form.full_name}
                />

                <JavInput
                    title={"Occupation/Job Title"}
                    name={"job_title"}
                    onChange={handleChanges}
                    value={form.job_title}
                />

                <JavInput
                    title={"Date of Birth"}
                    name={"dob"}
                    onChange={handleChanges}
                    value={form.dob}
                />

                <JavInput
                    title={"Physical Address"}
                    name={"physical_address"}
                    onChange={handleChanges}
                />


                <JavInput
                    title={"Email"}
                    name={"email"}
                    onChange={handleChanges}
                />

                <JavInput
                    title={"Phone Number"}
                    name={"phone_number"}
                    onChange={handleChanges}
                />

                <JavFormSelect
                    items={roles}
                    position={"bottom"}
                    onChange={(item) => {
                        console.log(item);
                        setForm({
                            ...form,
                            role_id: item.id
                        })
                    }}
                    title={"User Role"}
                    className={" mt-2"}/>

                <div className={"col-span-2 flex justify-end my-2"}>

                    <JavButton
                        title={"Save"}
                        padding={"px-16 py-3"}
                        textColor={"text-white"}
                        onClick={onSaveUserTapped}
                    />

                </div>

            </div>


            <div className={"bg-white dark:bg-[#242A38] mt-10 p-5"}>
                <h2 className={"mb-2 dark:text-white font-proximaBold"}> Users </h2>
                <Table
                    pageNumbers={[5,10,25,"All"]}
                    tag={"kyc.user_roles_and_permission"}
                    link={`admin-portal/accounts?business_id=${id}`}
                    columns={columns}
                    fields={["id","full_name","email","phone_number","role.role.name","created_at",{
                        "id": "action",
                        render: (content) => {
                            return (
                                <td className={""}>
                                    <div className={`flex justify-center`}>

                                        <JavButton onClick={() => {
                                            // setSelectItemModel(content)
                                        }} className={"p-1 mx-2"} bgColor={"bg-gray-200 "}>
                                            <BlockIcon/>
                                        </JavButton>

                                        <JavButton onClick={() => {
                                            setSelectedItem(content);
                                            setSelectedAction("reset");
                                        }} className={"p-1 text-white"} bgColor={"bg-yellow-400 "}>
                                            <ResetIcon/>
                                        </JavButton>



                                    </div>

                                </td>
                            )
                        }
                    }]}
                />
            </div>


            <div className={"col-span-2 flex justify-end my-2"}>

                <JavButton
                    title={"Continue"}
                    padding={"px-16 py-3"}
                    textColor={"text-white"}
                    onClick={onContinueTapped}
                />

            </div>


        </div>
    )
}