import JavInput from "../../../../Shared/Components/Forms/JavInput";
import {useEffect, useState} from "react";
import JavCheckMultiSelect from "../../../../Shared/Components/Forms/JavCheckMultiSelect";
import JavFormSelect from "../../../../Shared/Components/Forms/JavFormSelect";
import JavButton from "../../../../Shared/Components/Buttons/JavButton";
import {fetchPreRules} from "../duck/action";
import {useDispatch, useSelector} from "react-redux";

export default function ForeignExchange(props) {

    const dispatch = useDispatch();
    const kycState = useSelector( (state) => state.kyc);

    const [form,setForm] = useState({
        expected_funds_from: "",
        expected_funds_to: "",
        purpose: "",
        currency: ""
    });

    const handleChanges = (event) => {
        const value = event.target.value;
        setForm({
            ...form,
            [event.target.name]: value
        })
    }

    const onContinueTapped = () => {
        props.onContinue(form);
    }

    useEffect(() => {
        dispatch(fetchPreRules())
    },[]);

    return (
        <div className={"grid grid-cols-2 dark:bg-[#242A38] rounded border dark:border-gray-600 gap-x-12 gap-y-4 py-4 px-4 my-2"}>


            <div className={"col-span-2 my-1"}>
                <JavInput
                    title={"Purpose of transaction"}
                    name={"purpose"}
                    value={form.purpose}
                    onChange={handleChanges}
                />
            </div>


            <div className={"col-span-2 my-1"}>
                <JavCheckMultiSelect
                    title={"Currencies needed"}
                    items={[
                        "USD",
                        "GHS",
                        "GBP",
                        "CAD",
                        "XOF",
                    ]}
                    onSelectionChanged={(selection) => {
                        setForm({
                            ...form,
                            currency: selection
                        })
                    }}
                />
            </div>

            <JavFormSelect
                title={"Countries which funds are expected to go to"}
                name={"legal_name"}
                items={kycState.pre_rules.data && kycState.pre_rules.data[0]["valueSet"].map((item) => {
                    return {...item,title: item.country}
                })}
                position={"bottom"}
                onChange={(item) => {
                    setForm({
                        ...form,
                        expected_funds_to: item.countryCode
                    })
                }}
            />


            <JavFormSelect
                title={"Countries which funds are expected to come from"}
                name={"currency"}
                items={kycState.pre_rules.data && kycState.pre_rules.data[0]["valueSet"].map((item) => {
                    return {...item,title: item.country}
                })}
                position={"bottom"}
                onChange={(item) => {
                    setForm({
                        ...form,
                        expected_funds_from: item.countryCode
                    })
                }}
            />

            <div className={"col-span-2 flex justify-end my-1"}>

                <JavButton
                    title={"Continue"}
                    padding={"px-16 py-3"}
                    textColor={"text-white"}
                    onClick={onContinueTapped}
                />

            </div>



            </div>
    )
}