export default function ChatBubbleLeft({chat}) {
    return (
        <div className={"py-2 flex items-center my-1"}>

            <img src={"images/logo.png"} className={"h-8 w-8 object-contain p-1 border-0.5 border rounded-lg mx-2"}/>

            <div className={"w-full p-2 rounded text-xs text-gray-800 overflow-hidden"}>
                <pre className={"w-[80%] text-wrap p-2 rounded overflow-hidden bg-gray-100 border "}>
                   {chat?.message}
                </pre>
            </div>
        </div>
    )
}