import {useEffect, useState} from "react";
import Table from "../../Shared/Components/Table";
import {useDispatch, useSelector} from "react-redux";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import CountryOverview from "./charts/CountryOverview";
import Loading from "../../Shared/Components/Loading";
import {getDashboard} from "./duck/action";
import {useNavigate} from "react-router-dom";
import ActiveOverview from "./charts/ActiveOverview";
import TransactionOverview from "./charts/TransactionOverview";
import moment from "moment";
import JavTabSwitch from "../../Shared/Components/Switch/JavTabSwitch";
import ListIcon from "../../Shared/Components/Icons/ListIcon";
import PDFIcon from "../../Shared/Components/Icons/PDFIcon";
import CashIcon from "../../Shared/Components/Icons/CashIcon";
import {PresentationChartLineIcon} from "../../Shared/Components/Icons/PresentationChartLineIcon";
import JavButton from "../../Shared/Components/Buttons/JavButton";

const renderOptions = [{ name: "Revenue", icon: ListIcon }, { name: "Profit", icon: PresentationChartLineIcon } ];

export default function Dashboard() {

    const dispatch = useDispatch();
    const dashboardState = useSelector( (state) => state.dashboard)
    const [dateRange, setDateRange] = useState([moment().startOf('year').toDate(), moment().toDate()]);
    const [startDate, endDate] = dateRange;
    const navigate = useNavigate();
    const [chartType,setChartType] = useState("revenue");
    const [renderOption,setRenderOption] = useState(renderOptions?.[0]);


    useEffect(() => {
        if (startDate != null && endDate != null) {
            dispatch(getDashboard(startDate,endDate));
        }
    },[dateRange])

    useEffect(() => {
        console.log("dashboardState",dashboardState.fetch)
    },[dashboardState])



    return (
        <div className="w-full overflow-y-auto mt-2">

            <div className={""}>

                <div className={"flex my-5 items-center justify-between"}>

                    <div className={""}>
                    <span className={"text-xs text-gray-600"}>
                        Here is your analytic details
                    </span>
                    </div>

                    <div className={"flex items-center"}>
                        <JavButton
                            title={"View International Report"}
                            bgColor={"bg-white"}
                            textColor={"text-blue-500"}
                            className={"border mx-2 h-8 border-blue-500"}
                        />
                        <div>
                            <DatePicker
                                className={"px-14 text-xs py-2 mt-1dark:bg-[#242A38] dark:text-gray-200 w-full border rounded dark:border-gray-300"}
                                onChange={(dates) => {
                                    setDateRange(dates)
                                    // dispatch(getDashboard());
                                }}
                                startDate={startDate}
                                endDate={endDate}
                                selectsRange={true}
                            />
                        </div>
                    </div>

                </div>


                <div className={"bg-gray-50 dark:bg-[#242A38] dark:text-white dark:border-gray-500 rounded border"}>
                    <div className={"py-3 px-3 bg-white border-b dark:border-gray-500 flex justify-between"}>

                        <JavTabSwitch onChange={(item) => setRenderOption(item)} options={renderOptions}/>

                        <div className={"flex items-center text-sm"}>
                            Transactions & Fees Overview
                        </div>

                    </div>

                    <div className={""}>
                    </div>

                    <div className={"h-96"}>
                        <TransactionOverview
                            labels={dashboardState.fetch.data && dashboardState.fetch.data.datesRange}
                            data={
                                chartType === 'revenue' ?
                                dashboardState.fetch.data && dashboardState.fetch.data.transactions_overview :
                                dashboardState.fetch.data && dashboardState.fetch.data.transactions_fees_overview
                            }
                        />
                    </div>
                </div>

                <div className={"flex "}>

                    <div className={"w-3/5 h-96 bg-gray-50 dark:bg-[#242A38] dark:border-gray-500 my-2  rounded border"}>
                        <div className={"border-b bg-white dark:text-white dark:border-gray-500 p-4 text-sm"}>
                            Program Transactional Overview (Volumes)
                        </div>
                        <div className={"h-full p-16"}>
                            <ActiveOverview data={dashboardState?.fetch?.data?.program_overview}/>
                        </div>
                    </div>

                    <div className={"w-2/5 h-96 ml-5 bg-gray-50 dark:bg-[#242A38] dark:border-gray-500 my-2 rounded border"}>
                        <div className={"border-b px-2 bg-white dark:text-white dark:border-gray-500 py-4 text-sm"}>
                            Country Accounts Overview 
                        </div>
                        <div className={"h-full p-16"}>
                            <CountryOverview/>

                        </div>
                    </div>

                </div>




                <div className={"w-full rounded border dark:bg-[#242A38] dark:border-gray-500 bg-gray-50 dark:bg-[#242A38] px-5 py-4"}>

                    <Table
                        columns={[ "#", "Reference","module","Amount","Fee","Status","Timestamp"]}
                        link={"admin-portal/transactions?statuses=COMPLETED,REPAID,WAITING_APPROVAL"}
                        tag={"dashboard.recent_transaction"}
                        fields={[
                            "id",
                            {
                                id: "reference",
                                render: (content) => {
                                    return (
                                        <td>
                                            <span onClick={() => {
                                                navigate(`/transaction/${content.id}`)
                                            }} className={"px-2 text-blue-500 underline cursor-pointer"}>{ content.reference}</span>
                                        </td>
                                    )
                                },
                            },"module",{
                                id: "amount",
                                render: (content) => {
                                    return (
                                        <td className={"text-center"}>
                                            <span> GHS { content.amount} </span>
                                        </td>
                                    )
                                }
                            },{
                                id: "fee",
                                render: (content) => {
                                    return (
                                        <td className={"text-center"}>
                                            <span> GHS { content.fee} </span>
                                        </td>
                                    )
                                }
                            },"status","created_at",
                        ]}
                    />

                </div>

            </div>

            <Loading
                open={dashboardState.fetch.loading}
                isLoading={dashboardState.fetch.loading}
            >
            </Loading>

        </div>
    )
}