import Table from "../../Shared/Components/Table";
import {useNavigate} from "react-router-dom";
import JavButton from "../../Shared/Components/Buttons/JavButton";
import {useState} from "react";
import CreateConfigDialog from "../Connect/Channels/details/tabs/components/CreateConfigDialog";
import CreateDigitalKYCDialog from "./component/CreateDigitalKYCDialog";

export default function DigitalKYC() {

    const navigate = useNavigate();
    const [openCreateKyC,setCreateKYC] = useState(false);

    return (
        <div>

            <CreateDigitalKYCDialog
                open={openCreateKyC}
                onCloseClicked={() => setCreateKYC(false)}
            />

            <div className={"flex justify-between my-3"}>
                <div>
                    <h5 className={"font-firmaBold"}>Digital KYC</h5>
                    <span className={"text-xs text-gray-600"}>Create a verification link in less than 5 minutes without code</span>
                </div>

                <JavButton
                    title={"Create Kyc Link"}
                    textColor={"text-white"}
                    onClick={() => setCreateKYC(true)}
                />
            </div>



            <div
                className={"dark:bg-[#242A38] col-span-2 bg-white dark:border-gray-500 border py-2 mb-4 rounded-xl w-full shadow-sm px-2"}>
                <Table
                    columns={[
                        "id", "reference", "name", "callback Url", "created_at", "action"
                    ]}
                    fields={["id", {
                        render: (content) => {
                            return (
                                <td className={"underline cursor-pointer text-center text-blue-500"}>
                                    <a target={"_blank"} href={content?.link}>{content?.uid}</a>
                                </td>
                            )
                        }
                    }, "meta.name","meta.callback_endpoint", "updated_at", "created_at", {
                        render: () => {
                            return (
                                <td className={"text-center"}>
                                    <button>Delete</button>
                                </td>
                            )
                        }
                    }]}
                    link={`admin-portal/digital-kyc`}
                    tag={"digital-kyc.table"}
                />
            </div>


        </div>
    )
}