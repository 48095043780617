import JavInput from "../../../../Shared/Components/Forms/JavInput";
import JavFormSelect from "../../../../Shared/Components/Forms/JavFormSelect";
import {useEffect, useState} from "react";
import JavButton from "../../../../Shared/Components/Buttons/JavButton";
import JavGoogleMapsAutoComplete from "../../../../Shared/Components/Forms/JavGoogleMapsAutoComplete";
import {useLocation} from "react-router-dom";

export default function ClientInformation(props) {

    const location = useLocation();
    const {business} = location.state;


    const [form,setForm] = useState({
        legal_name: "",
        trade_name: "",
        address: "",
        business_telephone_number: "",
        legal_structure: "",
        tax_id: "",
        primary_activities: "",
        website_link: "",
        formation_country: "",
        formation_state: "",
        incorporation_number: "",
    });

    useEffect(() => {
        if (Object.keys(props.data).length > 0) {
            setForm({...props.data})
        }
        console.log("business details are",business)
    },[props.data]);

    useEffect(() => {

        if (form.legal_name === "") {
            form.legal_name = business.business_name
        }

        if (form.trade_name === "") {
            form.trade_name = business.business_name
        }

    },[form.legal_name,form.trade_name])

    const handleChanges = (event) => {
        const value = event.target.value;
        setForm({
            ...form,
            [event.target.name]: value
        })
    }

    const onContinueTapped = () => {
        props.onContinue(form);
    }

    return (
        <div className={"grid grid-cols-2 dark:bg-[#242A38] rounded border dark:border-gray-600 gap-x-12 gap-y-4 py-4 px-4 my-2"}>

            <JavInput
                title={"Client Organization's Complete Legal Name"}
                name={"legal_name"}
                value={form.legal_name}
                onChange={handleChanges}
            />
            <JavInput
                title={"DBA/Trade Name"}
                name={"trade_name"}
                value={form.trade_name}
                onChange={handleChanges}
            />

            <JavGoogleMapsAutoComplete
                title={"Address"}
                name={"address"}
                value={form.address}
                onChange={handleChanges}
            />


            <JavInput
                title={"Business Telephone Number"}
                name={"business_telephone_number"}
                value={form.business_telephone_number}
                onChange={handleChanges}
            />

            <div className={"col-span-2 my-4"}>

                <JavFormSelect
                    items={[
                        "Corporation/Ltd",
                        "Limited Liability Company",
                        "Publicly Traded",
                        "Partnership",
                        "Trust",
                        "Non-Profit"
                    ]}
                    selected={form.legal_structure}
                    name={"legal_structure"}
                    onChange={(item) => {
                        setForm({
                            ...form,
                            legal_structure: item
                        })
                    }}
                    title={"Select Legal Structure"}
                    className={" mt-2"}/>
            </div>

            <JavInput
                title={"Tax ID/EIN"}
                name={"tax_id"}
                onChange={handleChanges}
                value={form.tax_id}
            />

            <JavInput
                title={"Client Primary Activities"}
                name={"primary_activities"}
                onChange={handleChanges}
                value={form.primary_activities}
            />

            <JavInput
                title={"Website Link"}
                name={"website_link"}
                onChange={handleChanges}
                value={form.website_link}
            />

            <JavInput
                title={"Country of Formation"}
                name={"formation_country"}
                onChange={handleChanges}
                value={form.formation_country}
            />

            <JavInput
                title={"State/Region of Formation"}
                name={"formation_state"}
                onChange={handleChanges}
                value={form.formation_state}
            />

            <JavInput
                title={"Business Incorporation Number"}
                name={"incorporation_number"}
                onChange={handleChanges}
                value={form.incorporation_number}
            />

            <div className={"col-span-2 flex justify-end my-1"}>

                <JavButton
                    title={"Continue"}
                    padding={"px-16 py-3"}
                    textColor={"text-white"}
                    isLoading={props.continueLoading}
                    onClick={onContinueTapped}
                />

            </div>
            

        </div>
    )
}