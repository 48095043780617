import {ActionTypes} from "./type";
import {makeJavolinRequest} from "../../../Shared/Utils/common";
import Cookies from "js-cookie";


export const updateAccountDetails = (id,data) => {
    return async function(dispatch) {
        dispatch({ type: ActionTypes.REQUEST_UPDATE_ACCOUNT_DETAILS });
        makeJavolinRequest({
            path: `admin-portal/admin/accounts/${id}`,
            method: "PUT",
            data: data
        },{
            SUCCESS: ActionTypes.UPDATE_ACCOUNT_DETAILS_SUCCESS,
            ERROR: ActionTypes.UPDATE_ACCOUNT_DETAILS_ERROR
        },dispatch,(data) => {
            Cookies.set("javAdmin", JSON.stringify(data), {
                expires: 7,
            });
        });
    }
}


export const fetchReceivableNotifications = () => {
    return async function(dispatch) {
        dispatch({ type: ActionTypes.REQUEST_RECEIVABLE_NOTIFICATION_FETCH });
        makeJavolinRequest({
            path: `admin-portal/receivable_notifications`,
            method: "GET"
        },{
            SUCCESS: ActionTypes.FETCH_RECEIVABLE_NOTIFICATION_SUCCESS,
            ERROR: ActionTypes.FETCH_RECEIVABLE_NOTIFICATION_ERROR
        },dispatch);
    }
}


export const toggleReceivableNotifications = (id) => {
    return async function(dispatch) {
        dispatch({ type: ActionTypes.REQUEST_RECEIVABLE_NOTIFICATION_TOGGLE });
        makeJavolinRequest({
            path: `admin-portal/receivable_notifications/${id}/toggle`,
            method: "GET"
        },{
            SUCCESS: ActionTypes.TOGGLE_RECEIVABLE_NOTIFICATION_SUCCESS,
            ERROR: ActionTypes.TOGGLE_RECEIVABLE_NOTIFICATION_ERROR
        },dispatch);
    }
}

export const changeAccountPassword = (data) => {
    return async function(dispatch) {
        dispatch({ type: ActionTypes.REQUEST_RECEIVABLE_NOTIFICATION_CHANGE_PASSWORD });
        makeJavolinRequest({
            path: `admin-portal/password/change`,
            method: "POST",
            data: data
        },{
            SUCCESS: ActionTypes.CHANGE_PASSWORD_RECEIVABLE_NOTIFICATION_SUCCESS,
            ERROR: ActionTypes.CHANGE_PASSWORD_RECEIVABLE_NOTIFICATION_ERROR
        },dispatch);
    }
}












