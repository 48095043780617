import JavInput from "../../../../Shared/Components/Forms/JavInput";
import {useEffect, useState} from "react";
import JavFormSelect from "../../../../Shared/Components/Forms/JavFormSelect";
import Table from "../../../../Shared/Components/Table";
import JavButton from "../../../../Shared/Components/Buttons/JavButton";
import {createBusinessUser} from "../duck/action";

export default function Ownership(props) {

    const [form,setForm] = useState({
        has_owner: false,
        full_name: "",
        nationality: "",
        percentage: 0,
        address: "",
        owned_by_public_entity: false,
        symbol: false
    });

    const [owners,setOwners] = useState([])

    const handleChanges = (event) => {
        const value = event.target.value;
        setForm({
            ...form,
            [event.target.name]: value
        })
    }

    useEffect(() => {
        if (Object.keys(props.data).length > 0) {
            setForm({...props.data})
        }
        if (props.data.owners) {
            props.data.owners.forEach((item,index) => {
                setOwners([...owners,
                    {
                        fields: [
                            {
                                title: (index + 1)
                            },
                            {
                                title: item.name
                            },
                            {
                                title: item.nationality
                            },
                            {
                                title: item.percentage
                            },
                            {
                                title: item.resident
                            },
                            {
                                render: () => {
                                    return (
                                        <td className={"flex items-center justify-center h-full"}>
                                            <JavButton
                                                bgColor={"bg-red-500"}
                                                textColor={"text-white"}
                                                title={"Remove"}
                                                className={"mt-2"}
                                                onClick={() => {
                                                    setOwners(owners.filter(item => index !== item.id))
                                                }}
                                            />
                                        </td>
                                    )
                                }
                            }
                        ]
                    }
                ])
            })
        }
        console.log("mOwnerShi",props.data);
    },[props.data])

    const columns = ["#","name","nationality","percentage (%)","resident","action"]

    const onSaveUserTapped = () => {
        const ownerId = owners.length + 1;
        setOwners([...owners,
            {
                fields: [
                    {
                        title: ownerId
                    },
                    {
                        title: form.full_name
                    },
                    {
                        title: form.nationality
                    },
                    {
                        title: form.percentage
                    },
                    {
                        title: form.address
                    },
                    {
                        render: () => {
                            return (
                                <td className={"flex items-center justify-center h-full"}>
                                    <JavButton
                                       bgColor={"bg-red-500"}
                                       textColor={"text-white"}
                                       title={"Remove"}
                                       className={"mt-2"}
                                       onClick={() => {
                                           setOwners(owners.filter(item => ownerId !== item.id))
                                       }}
                                    />
                                </td>
                            )
                        }
                    }
                ]
            }
        ])
    }

    const onContinueTapped = () => {

        let mOwners = owners.map(item => {
            return {
                name: item.fields[1].title,
                nationality: item.fields[2].title,
                percentage: item.fields[3].title,
                resident: item.fields[4].title
            }
        });


        props.onContinue({
            owners: mOwners,
            has_owner: form.has_owner,
            owned_by_public_entity: form.owned_by_public_entity,
            symbol: form.symbol
        });
    }

    return (
        <div className={"grid grid-cols-2 dark:bg-[#242A38] rounded border dark:border-gray-600 gap-x-12 gap-y-4 py-4 px-4 my-2"}>


            <JavFormSelect
                title={"Owned by a publicly traded entity?"}
                name={"owned_by_public_entity"}
                onChange={(item) => {
                    setForm({
                        ...form,
                        owned_by_public_entity: item.toLowerCase() === "yes"
                    })
                }}
                position={"bottom"}
                items={[
                    "No",
                    "Yes",
                ]}
            />
            <JavInput
                title={"Symbol"}
                name={"symbol"}
                value={form.symbol}
                onChange={handleChanges}
            />


            <div className={"col-span-2 my-4"}>

                <JavFormSelect
                    items={[
                        "No",
                        "Yes",
                    ]}
                    onChange={(item) => {
                        setForm({
                            ...form,
                            has_owner: item.toLowerCase() === "yes"
                        })
                    }}
                    position={"bottom"}
                    name={"has_owner"}
                    title={"Does any individual own 25% or more?"}
                    className={" mt-2"}/>

            </div>

            {
                form.has_owner &&
                    <div className={"col-span-2"}>

                        <div className={"col-span-2 mt-4 font-proximaBold dark:text-white"}>
                            <h2>Owner Details</h2>
                            <span className={"text-xs font-light"}>add owner</span>
                        </div>

                        <div className={"grid grid-cols-2 gap-x-4 gap-y-4 col-span-2"}>

                            <JavInput
                                title={"Full Legal Name"}
                                name={"full_name"}
                                value={form.full_name}
                                onChange={handleChanges}
                            />
                            <JavInput
                                title={"Nationality/Citizenship"}
                                name={"nationality"}
                                value={form.nationality}
                                onChange={handleChanges}
                            />

                            <JavInput
                                title={"Ownership Percentage (%)"}
                                name={"percentage"}
                                value={form.percentage}
                                onChange={handleChanges}
                            />

                            <JavInput
                                title={"Complete Residential Address"}
                                name={"address"}
                                value={form.address}
                                onChange={handleChanges}
                            />


                            <div className={"col-span-2 flex justify-end my-2"}>

                                <JavButton
                                    title={"Save"}
                                    padding={"px-16 py-3"}
                                    textColor={"text-white"}
                                    onClick={onSaveUserTapped}
                                />

                            </div>



                        </div>
                    </div>
            }



            {
                form.has_owner &&
                <div className={"bg-white dark:bg-[#181E2C] border rounded mt-10 p-5 col-span-2"}>
                    <h2 className={"mb-2 font-proximaBold dark:text-white"}> Owners </h2>
                    <Table
                        columns={columns}
                        data={owners}
                    />
                </div>
            }


            <div className={"col-span-2 flex justify-end my-2"}>

                <JavButton
                    title={"Continue"}
                    padding={"px-16 py-3"}
                    textColor={"text-white"}
                    onClick={onContinueTapped}
                />

            </div>








            </div>
    )
}