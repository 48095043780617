import YesNoDialog from "../../Shared/Components/Dialog/YesNoDialog";
import {approveTransaction} from "../PendingTransactions/duck/action";
import Table from "../../Shared/Components/Table";
import JavButton from "../../Shared/Components/Buttons/JavButton";
import CheckIcon from "../../Shared/Components/Icons/CheckIcon";
import {useDispatch, useSelector} from "react-redux";
import {useState} from "react";
import AddAccount from "./AddAccount";
import AddIcon from "../../Shared/Components/Icons/AddIcon";
import BlockIcon from "../../Shared/Components/Icons/BlockIcon";

export default function ApiAccount() {

    const pendingTransactionsState = useSelector( (state) => state.pendingTransactions)
    const [approveLoading,setApproveLoading] = useState(false);
    const [selectedItemModel,setSelectItemModel] = useState(null);
    const dispatch = useDispatch();
    const columns = [ "#", "Institute","APi Key","APi Token","Expires At","Created At","Action"];
    const [alert,setAlert] = useState({
        success: null,
        error: null
    })
    const [modalOpened,setOpenModal] = useState(false);


    return (
        <div className={""}>

            <YesNoDialog
                open={selectedItemModel != null}
                title={"Are you sure ?"}
                yesLoading={approveLoading}
                onYesClicked={() => {
                    dispatch(approveTransaction(selectedItemModel.id));
                }}
                onNoClicked={() => setSelectItemModel(null)}
                onCloseClicked={() => setSelectItemModel(null)}
            >
                <div className="text-xs py-3">
                    Are you sure you want to block this account ?
                </div>
            </YesNoDialog>

            <AddAccount
                open={modalOpened}
                onSuccess={(message) => { setAlert({...alert,success: message}) }}
                onError={(message) => { setAlert({...alert,error: message}) }}
                onDismissed={() => setOpenModal(false)}
            />

            <div className="flex justify-between items mt-16 mb-6">
                <div></div>
                <div className="grid grid-cols-1 gap-x-2">

                    <JavButton bgColor={"bg-blue-700"}
                               textColor={"text-white"}
                               onClick={() => setOpenModal(true)}
                               className={"px-5 py-1.5"}>

                        Create Account
                        <AddIcon className={"mx-1 w-5"}/>

                    </JavButton>


                </div>
            </div>



            <div className="dark:bg-[#242A38] bg-gray-50 dark:border-gray-500 border py-2 mb-4 rounded-lg px-2">
                <Table
                    link={"admin-portal/api-accounts"}
                    columns={columns}
                    tag={"api.table"}
                    fields={["id","institute","api_id","api_token",'exipres_at','created_at', {
                        "id": "action",
                        render: (content) => {
                            return (
                                <td className={""}>
                                    <div className={`flex justify-center`}>

                                        <JavButton onClick={() => {
                                            setSelectItemModel(content)
                                        }} className={"p-1 mx-2"} bgColor={"bg-gray-200 "}>
                                            <BlockIcon/>
                                        </JavButton>

                                    </div>

                                </td>
                            )
                        }
                    }]}
                />
            </div>


        </div>
    )
}