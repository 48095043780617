import {forwardRef, useEffect, useImperativeHandle, useState} from "react";

function Tab(props,ref) {

    const [selectedIndex,setSelectedIndex] = useState(0);

    useImperativeHandle(ref,() => {
        return {
            setSelectedIndex: (index) => {
                setSelectedIndex(index)
            },
            moveForward: () => {
                if (props.children && (props.children.length - 1) > selectedIndex) {
                    setSelectedIndex(selectedIndex + 1)
                }
            },
            moveBackward: () => {
                if (selectedIndex > 0) {
                    setSelectedIndex(selectedIndex - 1)
                }
            },
            getCurrentIndex: () => {
                return selectedIndex
            }
        }
    },[selectedIndex])


    return (
        <div className={`my-2 flex flex-col rounded border ${props.className}`}>
            <div className={`w-full px-2 items-center overflow-hidden flex justify-around
                            h-12 bg-white dark:bg-[#181E2C] border-b border-white`}>
                {
                    props.children && props.children.map((item,index) => {
                        return (
                            <div key={index} onClick={() => setSelectedIndex(index)}
                                 className={`flex font-proximaBold text-sm cursor-pointer flex-1 h-full justify-center
                                  ${selectedIndex === index ? 'border-b-2 dark:text-white' : 'text-gray-400'}
                                  items-center`}>
                                {item.props.title}
                            </div>
                        )
                    })
                }
            </div>


            <div className={"mt-2"}>
                {
                    props && props.children.map( (item,index) => {
                        return selectedIndex === index && (
                            props.children[index]
                        )
                    })
                }
            </div>
        </div>

    )
}

export default forwardRef(Tab)