import {useEffect, useState} from "react";
import {makeJavolinRequest} from "../../../../../../Shared/Utils/common";
import Dialog from "../../../../../../Shared/Components/Dialog/Dialog";
import JavInput from "../../../../../../Shared/Components/Forms/JavInput";
import JavTextArea from "../../../../../../Shared/Components/Forms/JavTextArea";
import JavButton from "../../../../../../Shared/Components/Buttons/JavButton";
import AddIcon from "../../../../../../Shared/Components/Icons/AddIcon";
import JavFormSelect from "../../../../../../Shared/Components/Forms/JavFormSelect";

export default function AddParticipantDialog(props) {

    const [addParticipant,setAddParticipant] = useState({});

    const [form,setForm] = useState({
        reference: null,
        name: null,
        maximum_participant: null,
        description: null
    });

    useEffect(() => {

        //fetch info
        setAddParticipant({
            ...addParticipant,
            fetch: {
                ...addParticipant.fetch,
                loading: true
            }
        })
        makeJavolinRequest({
            path: `admin-portal/connect/channels/${props?.channel?.id}/participants-creation-info`,
            method: "GET"
        },null,null,(data) => {
            setAddParticipant({
                ...addParticipant,
                fetch: {
                    ...addParticipant.fetch,
                    loading: false,
                    data: data
                }
            })
        },(error) => {
            setAddParticipant({
                ...addParticipant,
                fetch: {
                    ...addParticipant.fetch,
                    loading: false
                }
            })
            console.log("error",error)
        })

    },[]);

    const handleChanges = (event) => {
        const value = event.target.value;
        setForm({
            ...form,
            [event.target.name]: value
        })
    }

    const onCreateClicked = () => {
        setAddParticipant({
            ...addParticipant,
            create: {
                ...addParticipant.create,
                loading: true
            }
        })
        makeJavolinRequest({
            path: `admin-portal/connect/channels/${props?.channel?.id}/participants`,
            method: "POST",
            data: {...form}
        },null,null,(data) => {
            setAddParticipant({
                ...addParticipant,
                create: {
                    ...addParticipant.create,
                    loading: false,
                    data: data
                }
            })
            alert("Channel Participant Created Successfully")
            props.onCloseClicked();
            console.log("data",data)
        },(error) => {
            setAddParticipant({
                ...addParticipant,
                create: {
                    ...addParticipant.create,
                    loading: false
                }
            });
            console.log("error",error)
        })
    }


    return (
        <Dialog position={"top"} icon={AddIcon} style={{width: '30%'}} open={props?.open} onCloseClicked={props?.onCloseClicked}
                title={"Add Participant"} titleWithSubtitle={true}>

            <div className={"my-8"}>

                <JavInput
                    title={"Channel"}
                    name={"maximum_participant"}
                    disabled={true}
                    value={props?.channel?.name}
                    onChange={handleChanges}
                />

                <JavFormSelect
                    items={addParticipant?.fetch?.data?.accounts?.map(item => {
                        return {
                            ...item,
                            title: item?.name
                        }
                    })}
                    position={"bottom"}
                    onChange={(item) => {
                        setForm({
                            ...form,
                            participant_id: item?.id
                        })
                    }}
                    name={"participant"}
                    title={"Participant"}
                    className={" mt-2"}/>

                <div className={"grid grid-cols-1 col-span-2 gap-4 mt-8"}>
                    <JavButton
                        title={"Add Participant"}
                        textColor={"text-white"}
                        isLoading={addParticipant?.create?.loading}
                        onClick={onCreateClicked}
                    />
                </div>


            </div>

        </Dialog>
    )
}