export default function CircleProgress(props) {
    return (
        <div className={"relative h-20 w-20"}>
            <svg viewBox="0 0 36 36" className={"border rounded-full"}>
                <path strokeWidth={3.5} className={"stroke-green-600  fill-gray-100"}
                      style={{strokeLinecap: "round"}}
                      stroke-dasharray={`${props.progress ? props.progress : 0}, 100`}
                      d="M18 2.0845
                      a 15.9155 15.9155 0 0 1 0 31.831
                      a 15.9155 15.9155 0 0 1 0 -31.831"
                />
            </svg>

            <div className={"absolute top-0 right-0 left-0 bottom-0 flex items-center justify-center"}>
                <span className={"text-gray-500 text-xs"}>
                    {props.progress ? props.progress : 0}%
                </span>
            </div>

        </div>
    )
}