import { forwardRef, useEffect, useImperativeHandle, useRef, useState } from "react";
import useOnClickOutside from "use-onclickoutside";
import ArrowDownIcon from "../Icons/Arrow/ArrowDownIcon";
// import CancelIcon from "../Icons/CancelIcon";
import { makeJavolinRequest } from "../../Utils/common";
import CloseIcon from "../Icons/CloseIcon";

function JavMultiFormSelect(props, ref) {

    const [opened, setOpened] = useState(false)
    const [value, setValue] = useState("")
    const [filteredItems, setFilteredItems] = useState(props.items ?? []);
    const [selectedItems, setSelectedItems] = useState([]);
    const fieldKey = props?.fieldKey


    useEffect(() => {
        if (props?.selectedItems) {
            let itemsList = [...selectedItems, ...props.selectedItems];
            //set only the unique value
            setSelectedItems(itemsList.filter( (obj,index) => {
                return itemsList.findIndex(indexObj => indexObj[props?.fieldKey] === obj[props?.fieldKey]) === index
            }))
        }
    }, [props?.selectedItems]);




    // useEffect(() => {
    //     makeJavolinRequest({
    //         path: props?.remoteAutoSearchLink,
    //         method: "GET",
    //         query: {
    //             search: value
    //         }
    //     }, null, null, (data) => {
    //         setFilteredItems(data)
    //     }, (error) => {
    //         console.log("error is ", error)
    //     })
    // }, [props?.remoteAutoSearchLink])


    useEffect(() => {
        if (!props.remoteAutoSearchLink) {
            setFilteredItems(props.items)
        }
    }, [props.items])
    const getPosition = (position) => {
        switch (position) {
            case "bottom":
                return "left-0 right-0";
            default:
                return "-top-52 left-0 right-0"
        }
    }

    const selfRef = useRef(null);
    useOnClickOutside(selfRef, (e) => {
        if (e.target) {
            if (e.target.dataset.class !== "allowed") {
                if (opened) {
                    setOpened(false);
                }
            }
        }
    })

    const onChipRemoveItemClicked = (item) => {
        console.log("onChipRemoveItemClicked", item)
        setSelectedItems(selectedItems.filter(mItem => {
            let mMItem = fieldKey ? mItem[fieldKey] : mItem;
            return mMItem !== item
        }));
    }

    useEffect(() => {
        if (props.items && props.items.length > 0) {
            let text = props.items[0];
            if (props.items[0] instanceof Object) {
                text = props.items[0].title;
            }
            // setValue(text) //TODO: This is empty
        }
    }, [])


    useImperativeHandle(ref, () => {
        return {
            getSelectedItems: () => {
                return selectedItems
            }
        }
    }, [selectedItems])



    return (

        <div className={`flex flex-wrap h-full dark:bg-[#242A38] items-center text-gray-600 
        ${props.bgColor} ${props.isColumn ? 'flex-row' : 'flex-col'} col-span-${props.colSpan}`}>

            <span className={`h-full ${props.isColumn ? 'w-2/5 bg-gray-100 border border-gray-200 dark:bg-[#242A38] flex items-center px-2' : ''}`}>
                {props.title && <span className={"text-sm py-0.5 pt-0 mx-1"}>{props.title}</span>}
            </span>

            <div className={`relative ${props.hideBorder ? '' : 'border dark:border-gray-500'} 
                                ${props.isColumn ? 'w-3/5' : 'w-full'} 
                                 rounded px-1 `}>

                <div className={"flex flex-wrap"}>
                    <div className={"flex flex-wrap"}>
                        {
                            selectedItems.map((item, index) => {
                                let text = fieldKey ? item[fieldKey] : item;
                                return (
                                    <div key={index} className={"flex mx-1 my-0.5 px-1.5 items-center justify-between py-0.5 text-xs bg-gray-200 rounded border"}>
                                        <p className={"line-clamp-1"}>
                                            {text}
                                        </p>

                                        <div className={"ml-5"}>
                                            <div onClick={() => onChipRemoveItemClicked(text)} className={"cursor-pointer"}>
                                                <CloseIcon className={" fill-white stroke-2"} />
                                            </div>
                                        </div>

                                    </div>
                                )
                            })
                        }
                        <input data-class={"allowed"} ref={selfRef}
                               className={"outline-none mx-1 cursor-pointer py-1 bg-gray-50 text-xs px-1 dark:bg-[#242A38] dark:text-gray-100"}
                               onFocus={() => { setOpened(true) }}
                               placeholder={props?.searchPlaceholder}
                               onChange={(e) => setValue(e.target.value)}
                               value={value}
                        />
                    </div>


                </div>

                <div className={`mt-4 absolute z-50 border 
                            overflow-hidden overflow-y-auto
                            rounded-lg max-h-44 bg-white 
                            mb-2 rounded 
                           ${getPosition(props.position)}
                         ${opened ? 'block' : 'hidden'}
                         `}
                >
                    {
                        filteredItems.filter(item => {
                            let mItem = fieldKey ? item[fieldKey] : item
                            return mItem?.toLowerCase().includes(value
                            )
                        })
                            .filter(item => {
                                if (fieldKey) {
                                    let mItem = fieldKey ? item[fieldKey] : item
                                    return !(selectedItems.filter(selectedItem => {
                                        let mSelectedItem = fieldKey ? selectedItem[fieldKey] : selectedItem
                                        return mItem.toLowerCase() === mSelectedItem
                                    }).length > 0)
                                }
                                return !(selectedItems.filter(selectedItem => {
                                    return selectedItem.toLowerCase() === item.toLowerCase()
                                }).length > 0)
                            })
                            .map((item, index) => {
                                let text = item;
                                if (item instanceof Object) {
                                    text = item[fieldKey];
                                }
                                return (
                                    <div key={index} data-class={"allowed"} onClick={() => {
                                        setValue("");
                                        setOpened(false)
                                        setSelectedItems([...selectedItems, item])
                                        // props.onChange(item);
                                    }}
                                         className="h-10 px-6 border-t flex text-gray-600
                                hover:bg-gray-50 text-xs cursor-pointer
                                 items-center">
                                        {
                                            text
                                        }
                                    </div>
                                )
                            })
                    }
                </div>

            </div>
        </div>
    )
}

export default forwardRef(JavMultiFormSelect)

