import JavButton from "../../../../../Shared/Components/Buttons/JavButton";
import JavInput from "../../../../../Shared/Components/Forms/JavInput";
import Table from "../../../../../Shared/Components/Table";
import {useEffect, useRef, useState} from "react";
import AddParticipantDialog from "./components/AddParticipantDialog";
import {useSelector} from "react-redux";
import {buildQueryParamFromForm} from "../../../../../Shared/Utils/common";

export default function ConnectChannelDetailSettings() {

    const tableRef = useRef();
    const [openParticipant,setOpenParticipant] = useState(false);
    const connectState = useSelector( (state) => state.connect);
    const [form,setForm] = useState({})
    const handleChanges = (event) => {
        const value = event.target.value;
        setForm({
            ...form,
            [event.target.name]: value
        })
    }

    useEffect(() => {
        console.log("channelId is ",connectState?.fetch_channels)
        setForm({
            ...form,
            name: connectState?.fetch_channels?.data?.name
        })
        tableRef.current.reloadTable(`admin-portal/connect/channels/${connectState?.fetch_channels?.data?.id}/participants?${buildQueryParamFromForm({})}`);
    }, [connectState?.fetch_channels?.data?.id]);

    return (
        <div className={"flex flex-col"}>

            <AddParticipantDialog
                open={openParticipant}
                channel={connectState?.fetch_channels?.data}
                onCloseClicked={() => setOpenParticipant(false)}
            />

            <div className={"flex items-center px-2 border-y mx-4 py-5 mt-10"}>
                <div className={"w-1/5 text-center"}>Name</div>
                <div className={"w-3/5"}>
                    <JavInput
                        name={"name"}
                        value={form?.name}
                        className={"w-full"}
                        placeholder={"Change name..."}
                    />
                </div>
                <div className={"w-1/5 flex justify-end text-center items-center"}>
                    <JavButton textColor={"text-white"}>save</JavButton>
                </div>
            </div>

            <div className={"flex items-center px-2 border-y mx-4 py-5"}>
                <div className={"w-1/5 text-center"}>Manage Participants</div>
                <div className={"w-4/5"}>
                    <div className={"flex justify-end"}>

                        <JavButton
                            title={"Add Participant"}
                            textColor={"text-white"}
                            onClick={() => setOpenParticipant(true)}
                        />

                    </div>

                    <Table
                        ref={tableRef}
                        columns={[
                            "id", "participant name", "created_at", "action"
                        ]}
                        tag={"table.users"}
                        fields={["id","participant.name","created_at",{
                            render: () => {
                                return (
                                    <td>
                                        <JavButton padding={"px-2 py-1"} textColor={"text-white"} bgColor={"bg-red-500"}>delete</JavButton>
                                    </td>
                                )
                            }
                        }]}
                        clientSide={true}
                    />
                </div>

            </div>


            <div className={"flex items-center px-2 border-y mx-4 py-5"}>
            <div className={"w-1/5 text-center"}>Deactivate account</div>
                <div className={"w-3/5"}>
                    Before you can deactivate your channel, you will need to delete any teams you own or transfer ownership of them to another user.
                </div>
                <div className={"w-1/5 flex justify-end text-center items-center"}>
                    <JavButton bgColor={"bg-red-500"}>Delete</JavButton>
                </div>
            </div>




        </div>
    )
}