import {ActionTypes} from "./type";

export const TransactionReducer = (state = {
    fetch: {
        success: false,
        loading: false,
        error: null,
        data: null
    },
    details: {
        success: false,
        loading: false,
        error: null,
        data: null
    },
    upload: {
        success: false,
        loading: false,
        error: null,
        data: null
    },
    upload_transaction_document: {
        success: false,
        loading: false,
        error: null,
        data: null
    }

},action) => {
    switch (action.type) {
        //fetch
        case ActionTypes.REQUEST_FETCH:
            return {
                ...state,
                fetch: {
                    ...state.fetch,
                    loading: true,
                    success: false,
                    error: null
                }
            }
        case ActionTypes.FETCH_SUCCESS:
            return {
                ...state,
                fetch: {
                    ...state.fetch,
                    success: true,
                    loading: false,
                    data: action.payload
                }
            }
        case ActionTypes.FETCH_ERROR:
            return {
                ...state,
                fetch: {
                    ...state.fetch,
                    success: false,
                    loading: false,
                    error: action.payload
                }
            }

        //details
        case ActionTypes.REQUEST_DETAILS:
            return {
                ...state,
                details: {
                    ...state.details,
                    loading: true,
                    success: false,
                    error: null
                }
            }
        case ActionTypes.DETAILS_SUCCESS:
            return {
                ...state,
                details: {
                    ...state.details,
                    success: true,
                    loading: false,
                    data: action.payload
                }
            }
        case ActionTypes.DETAILS_ERROR:
            return {
                ...state,
                details: {
                    ...state.details,
                    success: false,
                    loading: false,
                    error: action.payload
                }
            }

        //upload resource
        case ActionTypes.REQUEST_UPLOAD:
            return {
                ...state,
                upload: {
                    ...state.upload,
                    loading: true,
                    success: false,
                    error: null
                }
            }
        case ActionTypes.UPLOAD_SUCCESS:
            return {
                ...state,
                upload: {
                    ...state.upload,
                    success: true,
                    loading: false,
                    data: action.payload
                }
            }
        case ActionTypes.UPLOAD_ERROR:
            return {
                ...state,
                upload: {
                    ...state.upload,
                    success: false,
                    loading: false,
                    error: action.payload
                }
            }


        //upload transaction document
        case ActionTypes.REQUEST_UPLOAD_TRANSACTION_DOCUMENT:
            return {
                ...state,
                upload_transaction_document: {
                    ...state.upload_transaction_document,
                    loading: true,
                    success: false,
                    error: null
                }
            }
        case ActionTypes.SUCCESS_UPLOAD_TRANSACTION_DOCUMENT:
            return {
                ...state,
                upload_transaction_document: {
                    ...state.upload_transaction_document,
                    success: true,
                    loading: false,
                    data: action.payload
                }
            }
        case ActionTypes.ERROR_UPLOAD_TRANSACTION_DOCUMENT:
            return {
                ...state,
                upload_transaction_document: {
                    ...state.upload_transaction_document,
                    success: false,
                    loading: false,
                    error: action.payload
                }
            }




        default:
            return state

    }
}

