import {ActionTypes} from "./type";

export const PendingTransactionReducer = (state = {
    fetch: {
        success: false,
        loading: false,
        error: null,
        data: null
    },
    approve_transaction: {
        success: false,
        loading: false,
        error: null,
        data: null
    }
},action) => {
    switch (action.type) {
        //fetch
        case ActionTypes.REQUEST_FETCH:
            return {
                ...state,
                fetch: {
                    ...state.fetch,
                    loading: true,
                    success: false,
                    error: null
                }
            }
        case ActionTypes.FETCH_SUCCESS:
            return {
                ...state,
                fetch: {
                    ...state.fetch,
                    success: true,
                    loading: false,
                    data: action.payload
                }
            }
        case ActionTypes.FETCH_ERROR:
            return {
                ...state,
                fetch: {
                    ...state.fetch,
                    success: false,
                    loading: false,
                    error: action.payload
                }
            }

        //approve_transaction
        case ActionTypes.REQUEST_APPROVE_TRANS:
            return {
                ...state,
                approve_transaction: {
                    ...state.approve_transaction,
                    loading: true,
                    success: false,
                    error: null
                }
            }
        case ActionTypes.APPROVE_TRANS_SUCCESS:
            return {
                ...state,
                approve_transaction: {
                    ...state.approve_transaction,
                    success: true,
                    loading: false,
                    data: action.payload
                }
            }
        case ActionTypes.APPROVE_TRANS_ERROR:
            return {
                ...state,
                approve_transaction: {
                    ...state.approve_transaction,
                    success: false,
                    loading: false,
                    error: action.payload
                }
            }

        default:
            return state

    }
}

