import { ActionTypes } from "./type";
import { makeJavolinRequest } from "../../../Shared/Utils/common";


export const createCodeGroup = (data) => {
    return async function (dispatch) {
        dispatch({ type: ActionTypes.CREATE_CODE_GROUP_REQUEST });
        makeJavolinRequest({
            path: `admin-portal/common-code-groups`,
            method: "POST",
            data
        }, {
            SUCCESS: ActionTypes.CREATE_CODE_GROUP_SUCCESS,
            ERROR: ActionTypes.CREATE_CODE_GROUP_ERROR
        }, dispatch);
    }
}
export const deleteCodeGroup = (id) => {
    return async function (dispatch) {
        dispatch({ type: ActionTypes.DELETE_CODE_GROUP_REQUEST });
        makeJavolinRequest({
            path: `admin-portal/common-code-groups/${id}`,
            method: "DELETE"
        }, {
            SUCCESS: ActionTypes.DELETE_CODE_GROUP_SUCCESS,
            ERROR: ActionTypes.DELETE_CODE_GROUP_ERROR
        }, dispatch);
    }
}




export const createCode = (id, data) => {
    return async function (dispatch) {
        dispatch({ type: ActionTypes.CREATE_CODE_REQUEST });
        makeJavolinRequest({
            path: `admin-portal/common-code-groups/${id}/common-codes`,
            method: "POST",
            data
        }, {
            SUCCESS: ActionTypes.CREATE_CODE_SUCCESS,
            ERROR: ActionTypes.CREATE_CODE_ERROR
        }, dispatch);
    }



}



export const deleteCode = (groupId, id) => {
    return async function (dispatch) {
        dispatch({ type: ActionTypes.DELETE_CODE_REQUEST });
        makeJavolinRequest({
            path: `admin-portal/common-code-groups/${groupId}/common-codes/${id}`,
            method: "DELETE"
        }, {
            SUCCESS: ActionTypes.DELETE_CODE_SUCCESS,
            ERROR: ActionTypes.DELETE_CODE_ERROR
        }, dispatch);

    }
}


