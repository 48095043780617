import AddIcon from "../../../Shared/Components/Icons/AddIcon";
import JavInput from "../../../Shared/Components/Forms/JavInput";
import Dialog from "../../../Shared/Components/Dialog/Dialog";
import {useState} from "react";
import JavFormSelect from "../../../Shared/Components/Forms/JavFormSelect";
import JavButton from "../../../Shared/Components/Buttons/JavButton";
import {makeJavolinRequest} from "../../../Shared/Utils/common";
import JavMultiFormSelect from "../../../Shared/Components/Forms/JavMultiFormSelect";

export default function CreateDigitalKYCDialog (props) {

    const [createKyc,setCreateKyc] = useState({});
    const [form,setForm] = useState({});

    const handleChanges = (event) => {
        const value = event.target.value;
        setForm({
            ...form,
            [event.target.name]: value
        })
    }

    const onCreateClicked = () => {
        setCreateKyc({...createKyc, loading: true})
        makeJavolinRequest({
            path: `admin-portal/digital-kyc`,
            method: "POST",
            data: {meta : form}
        },null,null,(data) => {
            setCreateKyc({...createKyc, data: data, loading: true})
            alert("KYC Link Created Successfully")
            props.onCloseClicked();
            console.log("data",data)
        },(error) => {
            setCreateKyc({...createKyc, loading: false})
            console.log("error",error)
        })
    }

    return (
    <Dialog position={"top"} icon={AddIcon} style={{width: '33%'}} open={props?.open} onCloseClicked={props?.onCloseClicked}
                title={"Create Digial Kyc Link"} titleWithSubtitle={true}>

        <div className={"grid grid-cols-2 gap-4 my-8"}>


            <div className={"col-span-2"}>

                <JavFormSelect
                    code={"DKEC001"}
                    codeField={"code_name"}
                    position={"bottom"}
                    onChange={(item) => setForm({...form, country: item.id})}
                    title={"Select Country"}
                />
            </div>


            <div className={"col-span-2"}>

                <span className={"text-sm"}>Select Id Type</span>
                <div className={"border rounded-md py-2"}>
                    <JavMultiFormSelect
                        items={[
                            "National Identity",
                            "Drivers License",
                            "Voters Id",
                            "Passport",
                        ]}
                        position={"bottom"}
                        onChange={(item) => {
                            console.log(item);
                            setForm({
                                ...form,
                                id_types: item
                            })
                        }}
                        className={""}
                        isColumn={false}
                        hideBorder={true}
                    />
                </div>

            </div>


            <div className={"col-span-2"}>
                <JavInput
                    title={"Heading Name"}
                    name={"name"}
                    value={form?.name}
                    onChange={handleChanges}
                />
            </div>

            <div className={"col-span-2"}>
                <JavInput
                    title={"Privacy/Policy Link"}
                    name={"privacy_link"}
                    value={form?.privacy_link}
                    onChange={handleChanges}
                />
            </div>


            <div className={"col-span-2"}>
                <JavInput
                    title={"Callback Endpoint"}
                    placeholder={"eg. https://api.javolin.com/api/callback"}
                    name={"callback_endpoint"}
                    value={form?.callback_endpoint}
                    onChange={handleChanges}
                />
            </div>

            <div className={"col-span-2"}>
                <JavButton
                    className={"mt-4 w-full"}
                    title={"Create KyC Form"}
                    textColor={"text-white"}
                    isLoading={createKyc?.loading}
                    onClick={onCreateClicked}
                />
            </div>

        </div>
    </Dialog>
    )
}