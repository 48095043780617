import {ActionTypes} from "./type";
import {makeJavolinRequest} from "../../../../Shared/Utils/common";
import Cookies from "js-cookie";


export const fetchBusinessRoles = (businessId) => {
    return async function(dispatch) {
        dispatch({ type: ActionTypes.REQUEST_FETCH_ROLES });
        makeJavolinRequest({
            path: `admin-portal/roles?business_id=${businessId}`,
            method: "GET",
        },{
            SUCCESS: ActionTypes.FETCH_SUCCESS_ROLES,
            ERROR: ActionTypes.FETCH_ERROR_ROLES
        },dispatch);
    }
}

export const createBusinessUser = (data) => {
    return async function(dispatch) {
        dispatch({ type: ActionTypes.REQUEST_CREATE_ACCOUNT });
        makeJavolinRequest({
            path: `admin-portal/accounts`,
            method: "POST",
            data: data
        },{
            SUCCESS: ActionTypes.SUCCESS_CREATE_ACCOUNT,
            ERROR: ActionTypes.ERROR_CREATE_ACCOUNT
        },dispatch);
    }
}

export const updateKYCDetails = (data) => {
    return async function(dispatch) {
        dispatch({ type: ActionTypes.REQUEST_UPDATE_KYC });
        makeJavolinRequest({
            path: `admin-portal/kyc`,
            method: "POST",
            data: data
        },{
            SUCCESS: ActionTypes.SUCCESS_UPDATE_KYC,
            ERROR: ActionTypes.ERROR_UPDATE_KYC
        },dispatch);
    }
}

export const fetchBusinessAccounts = (businessId) => {
    return async function(dispatch) {
        dispatch({ type: ActionTypes.REQUEST_FETCH_ACCOUNTS });
        makeJavolinRequest({
            path: `admin-portal/accounts?business_id=${businessId}`,
            method: "GET",
        },{
            SUCCESS: ActionTypes.FETCH_SUCCESS_ACCOUNTS,
            ERROR: ActionTypes.FETCH_ERROR_ROLES
        },dispatch);
    }
}

export const fetchPreRules = () => {
    return async function(dispatch) {
        dispatch({ type: ActionTypes.REQUEST_FETCH_PRE_RULES });
        makeJavolinRequest({
            path: `admin-portal/pre-rules`,
            method: "GET",
        },{
            SUCCESS: ActionTypes.FETCH_SUCCESS_PRE_RULES,
            ERROR: ActionTypes.FETCH_ERROR_PRE_RULES
        },dispatch);
    }
}

export const fetchBanks = (country) => {
    return async function(dispatch) {
        dispatch({ type: ActionTypes.REQUEST_FETCH_BANKS });
        makeJavolinRequest({
            path: `admin-portal/rules-banks?country=${country}`,
            method: "GET",
        },{
            SUCCESS: ActionTypes.FETCH_SUCCESS_BANKS,
            ERROR: ActionTypes.FETCH_ERROR_BANKS
        },dispatch);
    }
}

export const businessDetails = (businessId) => {
    return async function(dispatch) {
        dispatch({ type: ActionTypes.REQUEST_FETCH_BUSINESS_DETAILS });
        makeJavolinRequest({
            path: `admin-portal/businesses/${businessId}`,
            method: "GET",
        },{
            SUCCESS: ActionTypes.FETCH_SUCCESS_BUSINESS_DETAILS,
            ERROR: ActionTypes.FETCH_ERROR_BUSINESS_DETAILS
        },dispatch);
    }
}


export const fetchBusinessKYCLevel = (businessId) => {
    return async function(dispatch) {
        dispatch({ type: ActionTypes.REQUEST_CREATE_BUSINESS_KYC_LEVEL });
        makeJavolinRequest({
            path: `admin-portal/businesses/${businessId}/kycPercentageLevel`,
            method: "GET",
        },{
            SUCCESS: ActionTypes.SUCCESS_CREATE_BUSINESS_KYC_LEVEL,
            ERROR: ActionTypes.ERROR_CREATE_BUSINESS_KYC_LEVEL
        },dispatch);
    }
}

export const resetBusinessAccount = (id) => {
    return async function(dispatch) {
        dispatch({ type: ActionTypes.REQUEST_BUSINESS_ACCOUNT_RESET });
        makeJavolinRequest({
            path: `admin-portal/business/accounts/${id}/reset`,
            method: "POST"
        },{
            SUCCESS: ActionTypes.SUCCESS_BUSINESS_ACCOUNT_RESET,
            ERROR: ActionTypes.ERROR_BUSINESS_ACCOUNT_RESET
        },dispatch);
    }
}




export const createBusinessAccount = (data) => {
    return async function(dispatch) {
        dispatch({ type: ActionTypes.REQUEST_CREATE_BUSINESS_ACCOUNT });
        makeJavolinRequest({
            path: `admin-portal/business/register`,
            method: "POST",
            data: data
        },{
            SUCCESS: ActionTypes.SUCCESS_CREATE_BUSINESS_ACCOUNT,
            ERROR: ActionTypes.ERROR_CREATE_BUSINESS_ACCOUNT
        },dispatch);
    }
}

