import { ActionTypes } from "./type";

const initialState = {
    fetch_channels: {
        success: false,
        loading: false,
        error: null,
        data: null
    }
};
export const ConnectReducer = (state = initialState, action) => {
    switch (action.type) {


        //init
        case "INIT_COMMON":
            return {
                ...state,
                [action.action]: initialState[action.action]
            }

        //create-code-group
        case ActionTypes.CHANNEL_DETAIL_REQUEST:
            return {
                ...state,
                fetch_channels: {
                    ...state.fetch_channels,
                    loading: true,
                    success: false,
                    error: null
                }
            }
        case ActionTypes.CHANNEL_DETAIL_SUCCESS:
            return {
                ...state,
                fetch_channels: {
                    ...state.fetch_channels,
                    success: true,
                    loading: false,
                    data: action.payload
                }
            }
        case ActionTypes.CHANNEL_DETAIL_ERROR:
            return {
                ...state,
                fetch_channels: {
                    ...state.fetch_channels,
                    success: false,
                    loading: false,
                    error: action.payload
                }
            }



        default:
            return state

    }
}

