import JavSwitch from "../../../Shared/Components/Switch/JavSwitch";
import {useDispatch, useSelector} from "react-redux";
import {useEffect, useState} from "react";
import {fetchReceivableNotifications, toggleReceivableNotifications} from "../duck/action";
import LoadingIcon from "../../../Shared/Components/Icons/LoadingIcon";

export default function AccountNotification() {

    const settingsState = useSelector( (state) => state.settings)
    const [loadingId,setLoadingId] = useState(null);
    const [error,setError] = useState(null);
    const dispatch = useDispatch();


    useEffect(() => {
        dispatch(fetchReceivableNotifications());
    },[]);

    useEffect(() => {
        if (settingsState.toggle_receivable_notifications && settingsState.toggle_receivable_notifications.success) {
            setLoadingId(null)
            setError("updated successfully");
            dispatch(fetchReceivableNotifications());
        }
        if (settingsState.toggle_receivable_notifications && settingsState.toggle_receivable_notifications.error) {
            setLoadingId(null)
            setError(settingsState.toggle_receivable_notifications.error)
        }
    },[settingsState.toggle_receivable_notifications])

    const toggleNotification = (notification) => {
        setLoadingId(notification.id)
        dispatch(toggleReceivableNotifications(notification.id))
    }


    return (
        <div>

            <div className={"px-6 py-2"}>

                <h1 className={"text-3xl text-[#000]/76 font-proximaBold"}>
                    Notifications
                </h1>

                <div className={"flex justify-between items-center border-b py-4"}>
                    <h2 className={"text-gray-700 font-proximaBold"}>Check Notifications you want to receive</h2>

                </div>

                {
                    settingsState.fetch_receivable_notifications.data && settingsState.fetch_receivable_notifications.data.map(item => {
                        return (
                            <div className={"flex items-center py-4 border-b justify-between"}>
                                <div>
                                    <h3 className={"text-sm font-proximaBold"}>{item.title}</h3>
                                    <span className={"text-sm text-gray-600"}>{item.description}</span>
                                </div>
                                <JavSwitch
                                    active={item.active}
                                    onChange={() => toggleNotification(item)}
                                    isLoading={loadingId === item.id}
                                />
                            </div>
                        )
                    })
                }
                {
                    settingsState.fetch_receivable_notifications.loading &&
                    <div className={"flex justify-center my-10"}>
                        <LoadingIcon className={`animate-spin h-5 w-5 mx-2 fill-black`}/>
                    </div>
                }


            </div>

        </div>
    )
}