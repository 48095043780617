import {useState} from "react";

export default function JavTabSwitch({options,onChange}) {

    const [selectedOption,setSelectedOption] = useState(options?.[0]?.name);

    const TabIcon = ({item, Icon }) => {
        return (
            <Icon
                className={`p-1 h-5 w-5 ${selectedOption === item ? 'fill-white' : ''}`}
            />
        );
    };

    return (
        <div className={"flex border mx-2 bg-white  rounded"}>
            {
                options?.map((item,index) => {
                   return (
                       <div key={index} onClick={() => {
                           setSelectedOption(item?.name)
                           onChange(item)
                       }}
                            className={`mx-1 ${selectedOption === item.name ? 'bg-blue-700 text-white' : 'text-gray-600' } flex p-1 rounded my-1 text-xs  items-center px-4 cursor-pointer`}>
                           {
                               item.name
                           }
                           <TabIcon
                               item={item}
                               Icon={item?.icon}
                           />
                       </div>
                   )
                })
            }
        </div>
    )
}