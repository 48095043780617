import {useDispatch, useSelector} from "react-redux";
import {useEffect, useRef, useState} from "react";
import SummaryItem from "../../Shared/Components/SummaryItem";
import JavButton from "../../Shared/Components/Buttons/JavButton";
import BlockIcon from "../../Shared/Components/Icons/BlockIcon";
import Table from "../../Shared/Components/Table";
import CheckIcon from "../../Shared/Components/Icons/CheckIcon";
import YesNoDialog from "../../Shared/Components/Dialog/YesNoDialog";
import {approveTransaction} from "../PendingTransactions/duck/action";
import {Link, useNavigate} from "react-router-dom";
import AddIcon from "../../Shared/Components/Icons/AddIcon";


export default function Businesses() {

    const pendingTransactionsState = useSelector( (state) => state.pendingTransactions)
    const [approveLoading,setApproveLoading] = useState(false);
    const [selectedItemModel,setSelectItemModel] = useState(null);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const columns = [ "#", "Business Name","Email","Timestamp"];
    const [loadingId,setLoadingId] = useState(null);
    const [tableVersion,setTableVersion] = useState(0);




    useEffect(() => {
        if (pendingTransactionsState.approve_transaction.success) {
            //reload
            setTableVersion(tableVersion+1)
        }

    },[pendingTransactionsState.approve_transaction.success])

    return (
        <div className={""}>

            <YesNoDialog
                open={selectedItemModel != null}
                title={"Are you sure ?"}
                yesLoading={approveLoading}
                onYesClicked={() => {
                    dispatch(approveTransaction(selectedItemModel.id));
                    setLoadingId(selectedItemModel.id)
                    setSelectItemModel(null);
                }}
                onNoClicked={() => setSelectItemModel(null)}
                onCloseClicked={() => setSelectItemModel(null)}
            >
                <div className="text-xs py-3">
                    Are you sure approve this transaction ?
                </div>
            </YesNoDialog>


            <div className="flex justify-between items mt-6 mb-6">
                <div></div>
                <div className="grid grid-cols-1 gap-x-2">

                    <JavButton bgColor={"bg-blue-700"}
                               textColor={"text-white"}
                               onClick={() => {
                                   navigate(`/businesses/account/onboard`)
                               }}
                               className={"px-5 py-1.5"}>
                            Onboard Business
                        <AddIcon className={"mx-1 w-5"}/>

                    </JavButton>


                </div>
            </div>


            <div className="dark:bg-[#242A38] bg-gray-50 dark:border-gray-500 border py-2 mb-4 rounded-lg px-2">
                <Table
                    link={"admin-portal/businesses"}
                    columns={columns}
                    currentVersion={tableVersion}
                    tag={"business.business_table"}
                    dependencies={{
                        pendingTransactionsState,
                        loadingId
                    }}
                    fields={["id",{
                        id: "business_name",
                        render: (content) => {
                            return (
                                <td className={""}>
                                    <Link to={`/businesses/${content.id}`}
                                          state={{business: content}}
                                          className={"text-blue-600 underline"}>
                                        { content.business_name }
                                    </Link>
                                </td>
                            )
                        }
                    },"email",'created_at']}
                />
            </div>


        </div>

    )
}