import {ActionTypes} from "./type";
import {makeJavolinRequest} from "../../../Shared/Utils/common";


export const getTransactionDetail = (id) => {
    return async function(dispatch) {
        dispatch({ type: ActionTypes.REQUEST_DETAILS });
        makeJavolinRequest({
            path: `admin-portal/transactions/${id}`,
            method: "GET",
        },{
            SUCCESS: ActionTypes.DETAILS_SUCCESS,
            ERROR: ActionTypes.DETAILS_ERROR
        },dispatch);
    }
}

export const uploadTransactionDocument = (data) => {
    return async function(dispatch) {
        dispatch({ type: ActionTypes.REQUEST_UPLOAD_TRANSACTION_DOCUMENT });
        makeJavolinRequest({
            path: `admin-portal/documents`,
            method: "POST",
            data: data
        },{
            SUCCESS: ActionTypes.SUCCESS_UPLOAD_TRANSACTION_DOCUMENT,
            ERROR: ActionTypes.ERROR_UPLOAD_TRANSACTION_DOCUMENT
        },dispatch);
    }
}

export const uploadResource = (data) => {
    return async function(dispatch) {
        dispatch({ type: ActionTypes.REQUEST_UPLOAD });
        makeJavolinRequest({
            path: `admin-portal/resource/base64/upload`,
            method: "POST",
            data: data
        },{
            SUCCESS: ActionTypes.UPLOAD_SUCCESS,
            ERROR: ActionTypes.UPLOAD_ERROR
        },dispatch);
    }
}

