import {Component, useEffect, useState} from "react";
import AddIcon from "../../../Shared/Components/Icons/AddIcon";
import JavInput from "../../../Shared/Components/Forms/JavInput";
import Dialog from "../../../Shared/Components/Dialog/Dialog";
import JavTextArea from "../../../Shared/Components/Forms/JavTextArea";
import JavFormSelect from "../../../Shared/Components/Forms/JavFormSelect";
import Table from "../../../Shared/Components/Table";
import JavButton from "../../../Shared/Components/Buttons/JavButton";
import {uid} from "chart.js/helpers";
import {makeJavolinRequest} from "../../../Shared/Utils/common";
import JavMultiFormSelect from "../../../Shared/Components/Forms/JavMultiFormSelect";

export default function CreateCollectionDialog(props) {

    const [form,setForm] = useState({
        reference: null,
        name: null,
        maximum_participant: null,
        description: null
    });
    const [createChannel,setCreateChannel] = useState({
        loading: false,
        data: null
    })
    const [amountList,setAmountList] = useState([]);
    const [formItemList,setFormItemList] = useState([]);

    const handleChanges = (event) => {
        const value = event.target.value;
        setForm({
            ...form,
            [event.target.name]: value
        })
    }

    const onCreateClicked = () => {
        setCreateChannel({...createChannel,loading: true})
        makeJavolinRequest({
            path: 'admin-portal/collection-link',
            method: "POST",
            data: {...form,amount_list: amountList}
        },null,null,(data) => {
            setCreateChannel({...createChannel,loading: false,data: data})
            alert("Payment Link Created Successfully")
            props.onCloseClicked();
            console.log("data",data)
        },(error) => {
            setCreateChannel({...createChannel,loading: false})
            console.log("error",error)
        })
    }


    const onAmountListItemDeleteClicked = (uid) => {
        setAmountList((prevItems) => prevItems.filter(item => item.uid !== uid))
    }

    useEffect(() => {
        console.log("amountList",amountList)
    }, [amountList]);
    const onAddAmountClick = () => {
        let uid = Math.random().toString(16).slice(2)
        setAmountList([...amountList, {
            uid: uid,
            fields: [
                {
                    render: () => {
                        return <td>
                            <JavInput
                                name={"name"}
                                placeholder={"name"}
                                onChange={(event) => {
                                    setAmountList(prevItems => prevItems.map(item => item.uid ===  uid ? {...item,name: event.target.value } : item))
                                }}
                            />
                        </td>
                    }
                },
                {
                    render: () => {
                        return <td>
                            <JavInput
                                placeholder={"amount (GHS) eg. 10"}
                                type={"number"}
                                onChange={(event) => {
                                    setAmountList(prevItems => prevItems.map(item => item.uid ===  uid ? {...item,amount: event.target.value } : item))
                                }}
                            />
                        </td>
                    }
                },
                {
                    render: () => {
                        return <td className={"flex items-center justify-center py-2"}>
                            <JavButton
                                title={"delete"}
                                bgColor={"bg-red-500"}
                                textColor={"text-white"}
                                className={"py-1"}
                                onClick={() => onAmountListItemDeleteClicked(uid)}
                            />
                        </td>
                    }
                }
            ]
        }]);
    }


    return (
        <Dialog position={"top"} icon={AddIcon} style={{width: '40%'}} open={props?.open} onCloseClicked={props?.onCloseClicked}
                title={"Create Collection Link"} titleWithSubtitle={true}>

            <div className={"h-[70vh] overflow-y-scroll"}>
                <div className={"grid grid-cols-2 gap-4 my-8 pb-20"}>

                    <div className={"col-span-2"}>
                        <JavInput
                            title={"Page Name"}
                            name={"name"}
                            value={form?.name}
                            onChange={handleChanges}
                        />
                    </div>

                    <div className={"col-span-2"}>
                        <JavTextArea
                            title={"Description"}
                            name={"description"}
                            value={form?.description}
                            onChange={handleChanges}
                        />
                    </div>

                    <div className={"col-span-2"}>
                        <JavFormSelect
                            title={"Amount Configuration"}
                            items={["Endpoint", "Create Here"]}
                            position={"bottom"}
                            onChange={(item) => {
                                setForm({
                                    ...form,
                                    amount_configuration: item
                                })
                            }}
                        />
                    </div>

                    {
                        form?.amount_configuration === "Endpoint" &&
                        <div className={"col-span-2"}>
                            <JavInput
                                title={"Endpoint"}
                                name={"endpoint"}
                                placeholder={"https://api.company.com/fees-list"}
                                value={form?.endpoint}
                                onChange={handleChanges}
                            />
                        </div>
                    }


                    {
                        form?.amount_configuration === "Create Here" &&
                        <div className={"col-span-2"}>
                            <div className={"flex justify-end"}>
                                <JavButton
                                    className={"py-2"}
                                    textColor={"text-white"}
                                    title={"Add Amount"}
                                    onClick={onAddAmountClick}
                                />
                            </div>
                            <Table
                                columns={["Title", "Amount", "Action"]}
                                hideToolbar={true}
                                hideBottomToolbar={true}
                                data={amountList}
                            />
                        </div>
                    }


                    <div className={"border col-span-2 py-4  w-full"}>
                        <JavMultiFormSelect
                            items={[
                                "MOBILE MONEY",
                                "BANKS CARDS",
                            ]}
                            position={"bottom"}
                            onChange={(item) => {
                                console.log(item);
                                setForm({
                                    ...form,
                                    status: item
                                })
                            }}
                            title={"Payment Medium"}
                            className={"py-2"}
                            isColumn={true}
                            hideBorder={true}
                        />
                    </div>

                    <div className={"col-span-2"}>
                        <JavFormSelect
                            title={"Require KYC"}
                            items={["Yes", "No"]}
                            position={"bottom"}
                            onChange={(item) => {
                                setForm({
                                    ...form,
                                    require_kyc: item
                                })
                            }}
                        />
                    </div>


                    <div className={"col-span-2  flex justify-end mt-8"}>
                        <JavButton
                            title={"Create Link"}
                            textColor={"text-white"}
                            isLoading={createChannel?.loading}
                            onClick={onCreateClicked}
                        />
                    </div>


                </div>

            </div>
        </Dialog>
    )
}