import {useState} from "react";
import {makeJavolinRequest} from "../../../../Shared/Utils/common";
import JavInput from "../../../../Shared/Components/Forms/JavInput";
import JavButton from "../../../../Shared/Components/Buttons/JavButton";
import Dialog from "../../../../Shared/Components/Dialog/Dialog";
import AddIcon from "../../../../Shared/Components/Icons/AddIcon";
import JavTextArea from "../../../../Shared/Components/Forms/JavTextArea";

export default function CreateChannelDialog(props) {

    const [form,setForm] = useState({
        reference: null,
        name: null,
        maximum_participant: null,
        description: null
    });
    const [createChannel,setCreateChannel] = useState({
        loading: false,
        data: null
    })


    const handleChanges = (event) => {
        const value = event.target.value;
        setForm({
            ...form,
            [event.target.name]: value
        })
    }

    const onCreateClicked = () => {
        setCreateChannel({...createChannel,loading: true})
        makeJavolinRequest({
            path: 'admin-portal/connect/channels',
            method: "POST",
            data: {...form}
        },null,null,(data) => {
            setCreateChannel({...createChannel,loading: false,data: data})
            alert("Channel Created Successfully")
            props.onCloseClicked();
            console.log("data",data)
        },(error) => {
            setCreateChannel({...createChannel,loading: false})
            console.log("error",error)
        })
    }


    return (
        <Dialog position={"top"} icon={AddIcon} style={{width: '40%'}} open={props?.open} onCloseClicked={props?.onCloseClicked}
                title={"Create Channel"} titleWithSubtitle={true}>

            <div className={"grid grid-cols-2 gap-4 my-8"}>

                <div className={"col-span-2"}>
                    <JavInput
                        title={"Channel Name"}
                        name={"name"}
                        value={form?.name}
                        onChange={handleChanges}
                    />
                </div>

                {/*<JavInput*/}
                {/*    title={"Maximum Participants"}*/}
                {/*    name={"maximum_participant"}*/}
                {/*    value={form?.maximum_participant}*/}
                {/*    onChange={handleChanges}*/}
                {/*/>*/}

                <div className={"col-span-2"}>
                    <JavTextArea
                        title={"Description"}
                        name={"description"}
                        rows={5}
                        palceholder={"Description"}
                        value={form?.description}
                        onChange={handleChanges}
                    />
                </div>

                <div className={"col-span-2"}>
                    <JavInput
                        title={"Reference"}
                        name={"reference"}
                        value={form?.reference}
                        onChange={handleChanges}
                        placeholder={"Optional"}
                    />

                </div>


                <div className={"grid grid-cols-1 col-span-2 gap-4 mt-8"}>
                    <JavButton
                        title={"Create Channel"}
                        textColor={"text-white"}
                        isLoading={createChannel?.loading}
                        onClick={onCreateClicked}
                    />
                </div>


            </div>

        </Dialog>
    )
}