import ChatBubbleIcon from "../Icons/ChatBubbleIcon";
import {useEffect, useState} from "react";
import ChatPanel from "./ChatPanel";

export default function JavChat() {

    const [showChatPanel,setShowChatPanel] = useState(false);
    const [conversations,setConversations] = useState([]);


    useEffect(() => {
        console.log("conversations",conversations)
    },[conversations])


    return (


        <div className={"absolute flex flex-col items-end bottom-10 right-10"}>

            {
                showChatPanel &&
                <ChatPanel
                    conversations={conversations}
                    setConversations={setConversations}
                />
            }


            <div onClick={() => setShowChatPanel(!showChatPanel)} className={"rounded-full mt-5 w-14 h-14 flex items-center justify-center transition " +
                "ease-in-out delay-50 hover:scale-110 cursor-pointer bg-blue-700"}>
                <ChatBubbleIcon
                    className={"fill-none stroke-white"}
                />
            </div>

        </div>
    )
}