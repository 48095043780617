import {useDispatch, useSelector} from "react-redux";
import {useEffect, useRef, useState} from "react";
import Table from "../../Shared/Components/Table";
import YesNoDialog from "../../Shared/Components/Dialog/YesNoDialog";
import {approveTransaction} from "./duck/action";
import {useNavigate} from "react-router-dom";
import JavButton from "../../Shared/Components/Buttons/JavButton";
import EyeIcon from "../../Shared/Components/Icons/EyeIcon";


export default function Notifications() {

    const notificationState = useSelector( (state) => state.notifications)
    const [approveLoading,setApproveLoading] = useState(false);
    const [selectedItemModel,setSelectItemModel] = useState(null);
    const dispatch = useDispatch();
    const columns = [ "#", "Title","Recipient","Message","Delivered","Channel","Created At"];
    const [loadingId,setLoadingId] = useState(null);
    const [tableVersion,setTableVersion] = useState(0);
    const navigate = useNavigate();

    return (
        <div className={""}>

            <YesNoDialog
                open={selectedItemModel != null}
                title={"Are you sure ?"}
                yesLoading={approveLoading}
                onYesClicked={() => {
                    dispatch(approveTransaction(selectedItemModel.id));
                    setLoadingId(selectedItemModel.id)
                    setSelectItemModel(null);
                }}
                onNoClicked={() => setSelectItemModel(null)}
                onCloseClicked={() => setSelectItemModel(null)}
            >
                <div className="text-xs py-3">
                    Are you sure approve this transaction ?
                </div>
            </YesNoDialog>

            <div className="dark:bg-[#242A38] bg-gray-50 dark:border-gray-500 border py-2 mb-4 rounded-lg px-2">
                <Table
                    link={"admin-portal/notifications"}
                    tag={"pending_transactions.table"}
                    columns={columns}
                    currentVersion={tableVersion}
                    dependencies={{
                        notificationState,
                        loadingId
                    }}
                    fields={["id","title","recipient","message","delivered","channel",
                    'created_at']}
                />
            </div>



        </div>

    )
}