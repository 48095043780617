import {useDispatch, useSelector} from "react-redux";
import {useEffect, useRef, useState} from "react";
import SummaryItem from "../../Shared/Components/SummaryItem";
import JavButton from "../../Shared/Components/Buttons/JavButton";
import BlockIcon from "../../Shared/Components/Icons/BlockIcon";
import Table from "../../Shared/Components/Table";
import CheckIcon from "../../Shared/Components/Icons/CheckIcon";
import YesNoDialog from "../../Shared/Components/Dialog/YesNoDialog";
import {approveTransaction} from "./duck/action";
import JavInput from "../../Shared/Components/Forms/JavInput";
import JavFormSelect from "../../Shared/Components/Forms/JavFormSelect";
import DatePicker from "react-datepicker";
import {getDashboard} from "../Dashboard/duck/action";
import {useNavigate} from "react-router-dom";
import CashIcon from "../../Shared/Components/Icons/CashIcon";
import BackArrowIcon from "../../Shared/Components/Icons/Arrow/BackArrowIcon";


export default function FeeDetails() {

    const pendingTransactionsState = useSelector( (state) => state.pendingTransactions)
    const [approveLoading,setApproveLoading] = useState(false);
    const [selectedItemModel,setSelectItemModel] = useState(null);
    const dispatch = useDispatch();
    const columns = [ "#", "Name","Description","Fees","Discounts","Created At"];
    const [loadingId,setLoadingId] = useState(null);
    const [tableVersion,setTableVersion] = useState(0);
    const navigate = useNavigate();
    const [dateRange, setDateRange] = useState([null, null]);
    const [startDate, endDate] = dateRange;


    const [form,setForm] = useState({
        legal_name: "",
        trade_name: "",
        address: "",
        business_telephone_number: "",
        legal_structure: "",
        tax_id: "",
        primary_activities: "",
        website_link: "",
        formation_country: "",
        formation_state: "",
        incorporation_number: "",
    });

    const handleChanges = (event) => {
        const value = event.target.value;
        setForm({
            ...form,
            [event.target.name]: value
        })
    }




    useEffect(() => {
        if (pendingTransactionsState.approve_transaction.success) {
            //reload
            setTableVersion(tableVersion+1)
        }

    },[pendingTransactionsState.approve_transaction.success])

    return (
        <div className={""}>

            <YesNoDialog
                open={selectedItemModel != null}
                title={"Are you sure ?"}
                yesLoading={approveLoading}
                onYesClicked={() => {
                    dispatch(approveTransaction(selectedItemModel.id));
                    setLoadingId(selectedItemModel.id)
                    setSelectItemModel(null);
                }}
                onNoClicked={() => setSelectItemModel(null)}
                onCloseClicked={() => setSelectItemModel(null)}
            >
                <div className="text-xs py-3">
                    Are you sure approve this transaction ?
                </div>
            </YesNoDialog>
            <div className={"flex items-center cursor-pointer"} onClick={() => {
                navigate(-1)
            }}>
                <BackArrowIcon class={"h-6 w-6 dark:text-white"}/>
                <span className={"text-sm mx-2 dark:text-gray-50 text-gray-600"}>
                    Back to previous page
                </span>
            </div>




            <h3 className="text-sm font-proximaBold text-gray-600 mt-4">Fees</h3>

            <div className="dark:bg-[#242A38] bg-gray-50 dark:border-gray-500 border py-2 mb-4 rounded-lg px-2">
                <Table
                    link={"admin-portal/modules"}
                    tag={"pending_transactions.table"}
                    columns={columns}
                    currentVersion={tableVersion}
                    dependencies={{
                        pendingTransactionsState,
                        loadingId
                    }}
                    fields={["id","name","description","transaction.amount",'transaction.module','created_at']}
                />
            </div>

            <h3 className="text-sm  font-proximaBold text-gray-600">Discounts</h3>

            <div className="dark:bg-[#242A38] bg-gray-50 dark:border-gray-500 border py-2 mb-4 rounded-lg px-2">
                <Table
                    link={"admin-portal/modules"}
                    tag={"pending_transactions.table"}
                    columns={columns}
                    currentVersion={tableVersion}
                    dependencies={{
                        pendingTransactionsState,
                        loadingId
                    }}
                    fields={["id","name","description","transaction.amount",'transaction.module','created_at']}
                />
            </div>


        </div>

    )
}