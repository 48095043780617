import * as React from "react"

const MapIcon = (props) => (
    <svg
        width={20}
        height={20}
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >

        <path
            strokeLinecap="round"
            strokeLinejoin="round"
            d="m9 20-5.447-2.724A1 1 0 0 1 3 16.382V5.618a1 1 0 0 1 1.447-.894L9 7m0 13 6-3m-6 3V7m6 10 4.553 2.276A1 1 0 0 0 21 18.382V7.618a1 1 0 0 0-.553-.894L15 4m0 13V4m0 0L9 7"
        />

    </svg>
)

export default MapIcon
