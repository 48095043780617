import React, {useEffect} from 'react';
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Filler,
    Legend, BarElement,
} from 'chart.js';
import {Bar, Line} from 'react-chartjs-2';
import { faker } from '@faker-js/faker';
import {ucFirstWord} from "../../../Shared/Utils/common";

export default function TransactionOverview(props) {

    ChartJS.register(
        CategoryScale,
        LinearScale,
        PointElement,
        LineElement,
        BarElement,
        Title,
        Filler,
        Tooltip,
        Legend
    );

    const options = {
        responsive: true,
        maintainAspectRatio: false,
        tension: 0.5,
        lineWidth: 0.2,
        borderJoinStyle: 'round',
        borderWidth: 2.2,
        pointRadius: 1.5,
        plugins: {
            legend: {
                position: 'top',
                align: 'end',
                display: true,
                labels: {
                    font: {
                        size: 13,
                        family: "proxima-bold"
                    }
                }
            },
            title: {
                display: false,
                text: 'CircleProgress.js Line CircleProgress',
            },
        },
        scales: {
            x: {
                ticks: {
                    display: true
                },
                border: {
                    width: 0.1
                },
                grid: {
                    display: false,
                    borderColor: "rgba(0,0,0,0)",
                    borderDash: [4, 20],
                    circular: true
                },
            },
            y: {
                ticks: {
                    display: true
                },
                border: {
                    width: 0.1
                },
                grid: {
                    borderColor: "rgba(0,0,0,0)",
                    display: true,
                    borderDash: [4, 20],
                }
            }
        }
    };


    const labels = props?.labels;

    const data = {
        labels,
        datasets: props.data
            ? props.data.map((item) => {
                return {
                    label : ucFirstWord(item.module),
                    data: item.data,
                    borderColor: item?.color?.borderColor,
                    backgroundColor: item?.color?.backgroundColor,
                    borderWidth: 1.5
                }
            })
            : []
    };
    return <Bar options={options} data={data} />;

}