import {ActionTypes} from "./type";

const initialState = {
    update_account_details: {
        success: false,
        loading: false,
        error: null,
        data: null
    },
    fetch_receivable_notifications: {
        success: false,
        loading: false,
        error: null,
        data: null
    },
    toggle_receivable_notifications: {
        success: false,
        loading: false,
        error: null,
        data: null
    },
    change_password: {
        success: false,
        loading: false,
        error: null,
        data: null
    }
}
export const SettingsReducer = (state = initialState,action) => {
    switch (action.type) {

        //update account
        case ActionTypes.REQUEST_UPDATE_ACCOUNT_DETAILS:
            return {
                ...state,
                update_account_details: {
                    ...state.update_account_details,
                    loading: true,
                    success: false,
                    error: null
                }
            }
        case ActionTypes.UPDATE_ACCOUNT_DETAILS_SUCCESS:
            return {
                ...state,
                update_account_details: {
                    ...state.update_account_details,
                    success: true,
                    loading: false,
                    data: action.payload
                }
            }
        case ActionTypes.UPDATE_ACCOUNT_DETAILS_ERROR:
            return {
                ...state,
                update_account_details: {
                    ...state.update_account_details,
                    success: false,
                    loading: false,
                    error: action.payload
                }
            }

        //fetch receivable notifications
        case ActionTypes.REQUEST_RECEIVABLE_NOTIFICATION_FETCH:
            return {
                ...state,
                fetch_receivable_notifications: {
                    ...state.fetch_receivable_notifications,
                    loading: true,
                    success: false,
                    error: null,
                    data: null
                },
                toggle_receivable_notifications: {
                    ...initialState.toggle_receivable_notifications
                }
            }
        case ActionTypes.FETCH_RECEIVABLE_NOTIFICATION_SUCCESS:
            return {
                ...state,
                fetch_receivable_notifications: {
                    ...state.fetch_receivable_notifications,
                    success: true,
                    loading: false,
                    data: action.payload
                }
            }
        case ActionTypes.FETCH_RECEIVABLE_NOTIFICATION_ERROR:
            return {
                ...state,
                fetch_receivable_notifications: {
                    ...state.fetch_receivable_notifications,
                    success: false,
                    loading: false,
                    error: action.payload
                }
            }

        //toggle receivable notifications
        case ActionTypes.REQUEST_RECEIVABLE_NOTIFICATION_TOGGLE:
            return {
                ...state,
                toggle_receivable_notifications: {
                    ...state.toggle_receivable_notifications,
                    loading: true,
                    success: false,
                    error: null,
                    data: null
                }
            }
        case ActionTypes.TOGGLE_RECEIVABLE_NOTIFICATION_SUCCESS:
            return {
                ...state,
                toggle_receivable_notifications: {
                    ...state.toggle_receivable_notifications,
                    success: true,
                    loading: false,
                    data: action.payload
                }
            }
        case ActionTypes.TOGGLE_RECEIVABLE_NOTIFICATION_ERROR:
            return {
                ...state,
                toggle_receivable_notifications: {
                    ...state.toggle_receivable_notifications,
                    success: false,
                    loading: false,
                    error: action.payload
                }
            }

        //change password
        case ActionTypes.REQUEST_RECEIVABLE_NOTIFICATION_CHANGE_PASSWORD:
            return {
                ...state,
                change_password: {
                    ...state.change_password,
                    loading: true,
                    success: false,
                    error: null,
                    data: null
                }
            }
        case ActionTypes.CHANGE_PASSWORD_RECEIVABLE_NOTIFICATION_SUCCESS:
            return {
                ...state,
                change_password: {
                    ...state.change_password,
                    success: true,
                    loading: false,
                    data: action.payload
                }
            }
        case ActionTypes.CHANGE_PASSWORD_RECEIVABLE_NOTIFICATION_ERROR:
            return {
                ...state,
                change_password: {
                    ...state.change_password,
                    success: false,
                    loading: false,
                    error: action.payload
                }
            }


        default:
            return state

    }
}

