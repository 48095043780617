import JavInput from "../../../../Shared/Components/Forms/JavInput";
import {useState} from "react";
import JavFormSelect from "../../../../Shared/Components/Forms/JavFormSelect";
import JavTextArea from "../../../../Shared/Components/Forms/JavTextArea";
import JavButton from "../../../../Shared/Components/Buttons/JavButton";
import JavFileInput from "../../../../Shared/Components/Forms/JavFileInput";

export default function TransactionNerd() {

    const [form,setForm] = useState({
        document_type_id: "WS1",
        document_reference: ""
    });
    const handleChanges = (event) => {
        const value = event.target.value;
        setForm({
            ...form,
            [event.target.name]: value
        })
    }

    const documentTypes = [
        {
            id: "WS1",
            title: "Sender Swift Advice"
        },
        {
            id: "W1",
            title: "Sender Wire Confirmation",
        },
        {
            id: "W2",
            title: "Provider Wire Confirmation"
        },
        {
            id: "DC",
            title: "Destination Wire Confirmation"
        }
    ]

    return (
        <div className={""}>

            <div className={"mt-5 dark:border-gray-600 rounded  dark:text-white p-2"}>


                <h3 className={"text-sm font-proximaBold"}>
                    Platform
                </h3>

                <div className={"text-sm mt-3 border rounded bg-gray-50 dark:bg-[#242A38] px-2 py-1 text-gray-500"}>
                    <div>
                        <h4>Channel: Android</h4>
                    </div>

                    <div>
                        <h4>Counter: Ghana</h4>
                    </div>

                    <div>
                        <h4>TimeZone: Africa/Ghana [UTC]</h4>
                    </div>

                    <div>
                        <h4>IP: Africa/Ghana [UTC]</h4>
                    </div>

                    <div>
                        <h4>API Version: V3</h4>
                    </div>

                    <div>
                        <h4>App Version: V1.2.3</h4>
                    </div>





                </div>




                <div className={"mt-3 mt-10 flex py-1"}>


                    <div className={"w-2/3 border rounded bg-gray-50"}>


                        <h3 className={"text-sm p-2 font-proximaBold"}>
                            Update Transaction Document Details
                        </h3>

                        <div className={"px-2 grid gap-y-4 py-3"}>

                            <JavInput
                                title={"Transaction Reference"}
                                name={"business_name"}
                                disabled={true}
                                value={form.business_name}
                                onChange={handleChanges}
                            />

                            <JavFormSelect
                                items={documentTypes}
                                position={"bottom"}
                                onChange={(item) => {
                                    setForm({
                                        ...form,
                                        document_type_id: item.id
                                    })
                                }}
                                name={"document_type_id"}
                                title={"Document Type"}
                                className={" mt-2"}/>


                            <JavInput
                                title={"Document Reference"}
                                name={"document reference"}
                                value={form.document_reference}
                                onChange={handleChanges}
                            />

                            <JavFileInput/>

                            <JavTextArea
                                title={"Remarks"}
                                rows={3}
                                placeholder={"Remarks here..."}
                                name={"remarks"}
                                value={form.remarks}
                                onChange={handleChanges}
                            />

                            <div className={"flex justify-end"}>

                                <JavButton
                                    title={"Create"}
                                    padding={"px-16 py-3"}
                                    textColor={"text-white"}
                                    isLoading={false}
                                    onClick={() => {}}
                                />

                            </div>











                        </div>



                    </div>


                </div>




            </div>

        </div>
    )
}