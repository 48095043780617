import SettingsIcon from "../../Shared/Components/Icons/SettingsIcon";
import SettingsAccount from "./tabs/account";
import AccountNotification from "./tabs/notifications";
import {useState} from "react";
import NotificationIcon from "../../Shared/Components/Icons/NotificationIcon";
import AccountSecurity from "./tabs/security";
import LockIcon from "../../Shared/Components/Icons/LockIcon";

export default function Settings() {
    const tabs = [
        {
            title: "Accounts",
            description: "Manage your account, change name, profile picture, phone number etc",
            icon: <SettingsIcon fill={"white"} color={"#242A38"}/>,
            component: <SettingsAccount/>
        },
        {
            title: "Notifications",
            description: "Manage your notifications, change medium and channels for notifications and how frequent you receive it",
            icon: <NotificationIcon />,
            component: <AccountNotification/>
        },
        {
            title: "Security",
            description: "Manage your notifications, change medium and channels for notifications and how frequent you receive it",
            icon: <LockIcon />,
            component: <AccountSecurity/>
        }

    ];
    const [selectedTab,setSelectedTab] = useState(0);
    return (
        <div className={"flex sticky top-20 right-0"}>

            <div className={"w-1/3 rounded-l border dark:bg-[#242A38] dark:border-gray-500 bg-gray-50"}>
                {
                    tabs.map((item,index) => {
                        return (
                            <div onClick={() => {
                                setSelectedTab(index)
                            }} className={`flex cursor-pointer hover:bg-blue-600
                             hover:text-white py-6 px-4 border-b 
                             border-gray-300 ${selectedTab === index ? 'bg-blue-700 text-white' : ''}`}>


                                { item.icon }

                                <div className={"flex flex-col mx-4 leading-tight"}>

                                    <h2 className={"font-proximaBold"}>{item.title}</h2>
                                    <span className={"text-xs leading-3 text-gray-400"}>
                                        {item.description}
                                    </span>

                                </div>

                            </div>
                        )
                    })
                }


            </div>

            <div style={{height: '88vh'}} className={"w-2/3 border bg-gray-50 dark:bg-[#242A38] dark:border-gray-500 rounded-r"}>
                {
                    tabs.map((item,index) => index === selectedTab && tabs[index]["component"])
                }
            </div>

        </div>
    )
}