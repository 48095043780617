import { useEffect, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';

const usePreviousPath = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const [previousPath, setPreviousPath] = useState(null);

    useEffect(() => {
        setPreviousPath(location.pathname);
    }, [location.pathname]);

    // const navigateToPreviousPath = () => {
    //     if (previousPath) {
    //         navigate(previousPath);
    //     } else {
    //         console.error('Previous path is not available.');
    //     }
    // };

    return previousPath;
};

export default usePreviousPath;
