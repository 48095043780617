import JavButton from "../../Shared/Components/Buttons/JavButton";
import Table from "../../Shared/Components/Table";
import DeleteIcon from "../../Shared/Components/Icons/DeleteIcon";
import { useDispatch, useSelector } from "react-redux";
import {useEffect, useRef, useState} from "react";
import JavInput from "../../Shared/Components/Forms/JavInput";
import YesNoDialog from "../../Shared/Components/Dialog/YesNoDialog";
import { deleteCode, deleteCodeGroup } from "./duck/action";
import { useParams } from "react-router";
import { useLocation, useNavigate } from "react-router-dom";
import AddCommonCodeDialog from "./AddCodeDialog";
import {useAlert} from "../../Shared/Context/AlertContext";
import {buildQueryParamFromForm, setToInitial} from "../../Shared/Utils/common";

export default function CommonCode() {

    const params = useParams();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { state } = useLocation();
    const tableRef = useRef();
    const [selectedItem, setSelectedItem] = useState(null);
    const [selectedType, setSelectedType] = useState(null);
    const [selectedAction, setSelectedAction] = useState(null);
    const [showCreateCodeDialog, setShowCreateCodeDialog] = useState(false);
    const commonsState = useSelector((state) => state.commons)
    const link = `admin-portal/common-code-groups/${params.id}/common-codes`;
    const { showAlert } = useAlert();


    useEffect(() => {

        return () => {
            //clean up mess
            dispatch(setToInitial("common","create_code"));
            dispatch(setToInitial("common","delete_code"));
        }
    },[])


    useEffect(() => {
        if (commonsState.create_code.error) {
            setShowCreateCodeDialog(false);
            showAlert(commonsState?.create_code?.error,"red");
        }

        if (commonsState?.create_code?.success) {
            setShowCreateCodeDialog(false);
            showAlert("created successfully","green");
            tableRef.current.reloadTable(`${link}?${buildQueryParamFromForm({})}`);
        }


    }, [commonsState?.create_code])


    useEffect(() => {
        if (commonsState?.delete_code?.success) {
            setSelectedItem(null);
            showAlert("deleted successfully","red");
            tableRef.current.reloadTable(`${link}?${buildQueryParamFromForm({})}`);
        }
    }, [commonsState?.delete_code])


    useEffect(() => {
        if (state === null) {
            navigate("/commons")
        }
    }, [])


    return (
        <div>

            <AddCommonCodeDialog
                group={state}
                open={showCreateCodeDialog}
                onCloseClicked={() => {
                    setShowCreateCodeDialog(false);
                }}
            />


            <YesNoDialog
                open={selectedItem != null}
                title={"Are you sure ?"}
                yesLoading={commonsState?.delete_code?.loading}
                onYesClicked={() => {
                    switch (selectedType) {
                        case "code":
                            dispatch(deleteCode(state.id, selectedItem.id));
                            break;
                        default:
                    }
                }}
                onNoTapped={() => setSelectedItem(null)}
                onCloseClicked={() => setSelectedItem(null)}
            >
                <div className="text-xs py-3">
                    {
                        selectedType === "code" && selectedAction === "delete" &&
                        <span>Are you sure you want to delete code ?</span>
                    }

                </div>
            </YesNoDialog>



            <h3 className={"font-firmaBold text-lg my-2 text-gray-600 flex items-center"}>
                <div className={"h-2 w-2 bg-yellow-500  rounded-full mr-1"} /> Search
            </h3>
            <div className={"grid gap-x-2 gap-y-1 py-2 px-1 border grid-cols-1"}>


                <JavInput
                    isColumn={true}
                    title={"Name"}
                />


            </div>

            <div className={"flex justify-end mt-2"}>
                <JavButton
                    title={"Search"}
                    padding={"px-5 py-2"}
                    textColor={"text-white"}
                    isLoading={false}
                    onClick={() => { }}
                />
            </div>




            <h3 className={"text-lg flex justify-between items-center text-gray-600 mt-14 mb-1 font-firmaBold"}>

                <span className={"font-firmaBold text-lg my-2 flex items-center"}>
                    <div className={"h-2 w-2 bg-yellow-500 rounded-full mr-1"} />
                    Common Codes - {state && state.name}
                </span>

                <JavButton
                    title={"Add Common Code"}
                    padding={"px-5 py-2"}
                    textColor={"text-white"}
                    isLoading={false}
                    onClick={() => { setShowCreateCodeDialog(true) }}
                />

            </h3>

            <div className="dark:bg-[#242A38] bg-gray-50 dark:border-gray-500 border py-2 mb-4 rounded-lg px-2">
                <Table
                    columns={["#", "Code", "Name", "Option", "Created At", "Action"]}
                    link={link}
                    tag={"common-code"}
                    ref={tableRef}
                    fields={[
                        "id",
                        "code",
                        "code_name",
                        "code_option",
                        "created_at",
                        {
                            render: (content) => {
                                return (
                                    <td>
                                        <div className={"flex items-center justify-center"}>
                                            <JavButton onClick={() => {
                                                setSelectedItem(content)
                                                setSelectedType("code");
                                                setSelectedAction("delete");
                                            }} className={"p-1"} bgColor={"bg-gray-200 "}>
                                                <DeleteIcon />
                                            </JavButton>
                                        </div>
                                    </td>
                                )
                            }
                        }
                    ]}
                />
            </div>




        </div>
    )
}