export default function ChatBubbleRight({chat}) {
    return (
        <div className={""}>

            <div className={"p-1 pr-5 rounded text-xs flex justify-end text-white overflow-hidden"}>
                <pre className={"max-w-[80%] text-wrap p-2 rounded overflow-hidden bg-blue-600 "}>
                    {chat?.message}
                </pre>
            </div>

        </div>
    )
}