import * as React from "react"

const DistrictsIcon = (props) => (
    <svg
        width={20}
        height={20}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <path
            d="M6.375 17.425c-.025 0-.059.017-.084.017A8.338 8.338 0 0 1 2.55 13.7c0-.025.016-.058.016-.083 1.017.3 2.067.525 3.109.7.183 1.05.4 2.091.7 3.108ZM17.45 13.708c-.825 1.659-2.2 3-3.875 3.809.317-1.059.584-2.125.759-3.2 1.05-.175 2.083-.4 3.1-.7-.009.033.016.066.016.091ZM17.517 6.425a26.371 26.371 0 0 0-3.183-.758 23.262 23.262 0 0 0-.759-3.184 8.377 8.377 0 0 1 3.942 3.942ZM6.375 2.575a26.04 26.04 0 0 0-.692 3.1 24.058 24.058 0 0 0-3.2.75A8.411 8.411 0 0 1 6.292 2.55c.025 0 .058.025.083.025ZM12.909 5.492a26.107 26.107 0 0 0-5.817 0c.208-1.142.475-2.284.85-3.384.016-.066.008-.116.016-.183A8.672 8.672 0 0 1 10 1.667c.7 0 1.383.1 2.034.258.008.067.008.117.024.183.376 1.109.642 2.242.85 3.384ZM5.492 12.908c-1.15-.208-2.284-.475-3.384-.85-.067-.016-.117-.008-.183-.016A8.672 8.672 0 0 1 1.667 10c0-.7.1-1.383.258-2.033.067-.009.117-.009.183-.025a24.81 24.81 0 0 1 3.384-.85 27.145 27.145 0 0 0 0 5.816ZM18.333 10c0 .708-.1 1.383-.258 2.042-.067.008-.117 0-.183.016a25.106 25.106 0 0 1-3.384.85 26.103 26.103 0 0 0 0-5.816c1.142.208 2.284.475 3.384.85a.694.694 0 0 0 .183.025c.158.658.258 1.333.258 2.033ZM12.909 14.508c-.209 1.15-.476 2.284-.85 3.384-.017.066-.017.116-.025.183a8.604 8.604 0 0 1-2.034.258c-.708 0-1.383-.1-2.042-.258-.008-.067 0-.117-.016-.183a24.806 24.806 0 0 1-.85-3.384c.966.109 1.933.184 2.908.184.975 0 1.95-.075 2.909-.184ZM13.136 13.136a25.029 25.029 0 0 1-6.272 0 25.032 25.032 0 0 1 0-6.272 25.033 25.033 0 0 1 6.272 0 25.03 25.03 0 0 1 0 6.272Z"
        />
    </svg>
)

export default DistrictsIcon
