import * as React from "react"

const TaxPayerIcon = (props) => (
    <svg
        width={20}
        height={20}
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <path
            d="M15 1.667H5c-1.383 0-2.5 1.108-2.5 2.475v9.092A2.493 2.493 0 0 0 5 15.717h.633c.659 0 1.3.258 1.767.725l1.425 1.408c.65.642 1.7.642 2.35 0l1.425-1.408a2.504 2.504 0 0 1 1.767-.725H15c1.383 0 2.5-1.117 2.5-2.483V4.142c0-1.367-1.117-2.475-2.5-2.475Zm-5 2.958a1.627 1.627 0 0 1 .058 3.25h-.125A1.621 1.621 0 0 1 8.367 6.25 1.64 1.64 0 0 1 10 4.625Zm2.292 7.617c-1.259.842-3.325.842-4.584 0-1.108-.733-1.108-1.95 0-2.692 1.267-.841 3.334-.841 4.584 0 1.108.742 1.108 1.95 0 2.692Z"
        />
    </svg>
)

export default TaxPayerIcon
