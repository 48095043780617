export const ActionTypes = {
    CREATE_CODE_GROUP_REQUEST: "CREATE_CODE_GROUP_REQUEST",
    CREATE_CODE_GROUP_SUCCESS: "CREATE_CODE_GROUP_SUCCESS",
    CREATE_CODE_GROUP_ERROR: "CREATE_CODE_GROUP_ERROR",

    DELETE_CODE_GROUP_REQUEST: "DELETE_CODE_GROUP_REQUEST",
    DELETE_CODE_GROUP_SUCCESS: "DELETE_CODE_GROUP_SUCCESS",
    DELETE_CODE_GROUP_ERROR: "DELETE_CODE_GROUP_ERROR",



    CREATE_CODE_REQUEST: "CREATE_CODE_REQUEST",
    CREATE_CODE_SUCCESS: "CREATE_CODE_SUCCESS",
    CREATE_CODE_ERROR: "CREATE_CODE_ERROR",


    //Delete code
    DELETE_CODE_REQUEST: "DELETE_CODE_REQUEST",
    DELETE_CODE_SUCCESS: "DELETE_CODE_SUCCESS",
    DELETE_CODE_ERROR: "DELETE_CODE_ERROR",



}