import {ActionTypes} from "./type";
import {makeJavolinRequest} from "../../../Shared/Utils/common";


export const getPendingTransactions = () => {
    return async function(dispatch) {
        dispatch({ type: ActionTypes.REQUEST_FETCH });
        makeJavolinRequest({
            path: `admin-portal/pending-transactions`,
            method: "GET",
            query: {
                module: module
            }
        },{
            SUCCESS: ActionTypes.FETCH_SUCCESS,
            ERROR: ActionTypes.FETCH_ERROR
        },dispatch);
    }
}

export const approveTransaction = (id) => {
    return async function(dispatch) {
        dispatch({ type: ActionTypes.REQUEST_APPROVE_TRANS });
        setTimeout(() => {
            console.log("success")
            dispatch({ type: ActionTypes.APPROVE_TRANS_SUCCESS,payload: {
                    message: "joined successfully"
                } });
        },3000);
        // makeJavolinRequest({
        //     path: `admin-portal/pending-transactions/${id}/approve`,
        //     method: "POST",
        // },{
        //     SUCCESS: ActionTypes.APPROVE_TRANS_SUCCESS,
        //     ERROR: ActionTypes.APPROVE_TRANS_ERROR
        // },dispatch);
    }
}


