import React from 'react';
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
} from 'chart.js';
import { Line } from 'react-chartjs-2';
import { faker } from '@faker-js/faker';

ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend
);

export const options = {
    responsive: true,
    maintainAspectRatio: false,
    scales: {
        yAxis: {
            display: false
        },
        xAxis: {
            grid: {
                display: false
            }
        }
    },
    plugins: {
        legend: {
            position: 'top',
            display: false
        },
        title: {
            display: false,
            text: 'Chart.js Line Chart',
        },
        datalabels: {
            color: 'black',
            anchor: 'end',
            align: 'start',
            clamp: true,
            labels: {
                title: {
                    font: {
                        weight: 'bold'
                    }
                }
            },
            backgroundColor: function(context) {
                return context.dataset.backgroundColor;
            },
            borderRadius: 4,
            formatter: function (value, context) {
                return value; // Display the value of the bar
            },
            display: function(context) {
                return true;
            },
            padding: 6
        }
    },
};




export function LineChart(props) {

    let labels = props?.data?.map(item => {
        return item[0].split(" ")[1].substring(0,5)
    });
    const data = {
        labels,
        datasets: [
            {
                label: 'Dataset 1',
                data: props?.data?.map(item => {
                    return item[1]
                }),
                borderColor: 'rgb(255, 99, 132)',
                backgroundColor: 'rgba(255, 99, 132, 0.5)',
            }
        ],
    };

    return <Line options={options} data={data} />;
}
