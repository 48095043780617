import JavButton from "../../Shared/Components/Buttons/JavButton";
import Table from "../../Shared/Components/Table";
import DeleteIcon from "../../Shared/Components/Icons/DeleteIcon";
import { useDispatch, useSelector } from "react-redux";
import {useEffect, useRef, useState} from "react";
import JavInput from "../../Shared/Components/Forms/JavInput";
import YesNoDialog from "../../Shared/Components/Dialog/YesNoDialog";
import AddCommonGroupDialog from "./AddGroupDialog";
import { deleteCodeGroup } from "./duck/action";
import { useNavigate } from "react-router-dom";
import {useAlert} from "../../Shared/Context/AlertContext";
import {buildQueryParamFromForm} from "../../Shared/Utils/common";

export default function Commons() {

    const tableRef = useRef();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [selectedItem, setSelectedItem] = useState(null);
    const [selectedType, setSelectedType] = useState(null);
    const [selectedAction, setSelectedAction] = useState(null);
    const [showCreateGroupDialog, setShowCreateGroupDialog] = useState(false);
    const commonsState = useSelector((state) => state.commons);
    const link = `admin-portal/common-code-groups`;
    const { showAlert } = useAlert();

    const onItemClicked = (item) => {
        navigate(`/commons/${item.id}`, {
            state: item
        })
    }

    useEffect(() => {
        if (commonsState.create_code_group?.error) {
            setShowCreateGroupDialog(false);
            showAlert(commonsState.create_code_group?.error,"red");
        }

        if (commonsState.create_code_group?.success) {
            setShowCreateGroupDialog(false);
            showAlert("created successfully","primary");;
            tableRef.current.reloadTable(`${link}?${buildQueryParamFromForm({})}`);
        }

    }, [commonsState?.create_code_group]);



    useEffect(() => {
        if (commonsState?.delete_code_group?.success) {
            setSelectedItem(null);
            showAlert("deleted successfully","primary");
            tableRef.current.reloadTable(`${link}?${buildQueryParamFromForm({})}`);
        }
    }, [commonsState?.delete_code_group]);


    return (
        <div>


            <AddCommonGroupDialog
                open={showCreateGroupDialog}
                onCloseClicked={() => {
                    setShowCreateGroupDialog(false);
                }}
            />

            <YesNoDialog
                open={selectedItem != null}
                title={"Are you sure ?"}
                yesLoading={commonsState?.delete_code_group?.loading}
                onYesClicked={() => {
                    switch (selectedType) {
                        case "group":
                            dispatch(deleteCodeGroup(selectedItem.id));
                            break;
                        default:
                    }
                }}
                onNoTapped={() => setSelectedItem(null)}
                onCloseClicked={() => setSelectedItem(null)}
            >
                <div className="text-xs py-3">
                    {
                        selectedType === "group" && selectedAction === "delete" &&
                        <span>Are you sure you want to delete common group ?</span>
                    }

                </div>
            </YesNoDialog>



            <h3 className={"font-firmaBold text-lg my-2 text-gray-600 flex items-center"}>
                <div className={"h-2 w-2 bg-yellow-500  rounded-full mr-1"} /> Search
            </h3>
            <div className={"grid gap-x-2 gap-y-1 py-2 px-1 border grid-cols-2"}>


                <JavInput
                    isColumn={true}
                    title={"Code Group"}
                />

                <JavInput
                    isColumn={true}
                    title={"Code Name"}
                />

            </div>

            <div className={"flex justify-end mt-2"}>
                <JavButton
                    title={"Search"}
                    padding={"px-5 py-2"}
                    textColor={"text-white"}
                    isLoading={false}
                    onClick={() => { }}
                />
            </div>




            <h3 className={"text-lg flex justify-between items-center text-gray-600 mt-14 mb-1 font-firmaBold"}>

                <span className={"font-firmaBold text-lg my-2 flex items-center"}>
                    <div className={"h-2 w-2 bg-yellow-500 rounded-full mr-1"} />
                    Common Groups
                </span>

                <JavButton
                    title={"Add Common Group"}
                    padding={"px-5 py-2"}
                    textColor={"text-white"}
                    isLoading={false}
                    onClick={() => { setShowCreateGroupDialog(true) }}
                />

            </h3>

            <div className="dark:bg-[#242A38] bg-gray-50 dark:border-gray-500 border py-2 mb-4 rounded-lg px-2">
                <Table
                    ref={tableRef}
                    columns={["#", "Group Code", "Group Name", "Created At", "Action"]}
                    link={link}
                    tag={"common-code-groups"}
                    fields={[
                        "id",
                        {
                            render: (content) => {
                                return (
                                    <td onClick={() => onItemClicked(content)} className={"underline text-center cursor-pointer text-blue-800"}>
                                        {content.code}
                                    </td>
                                )
                            }
                        },
                        "name",
                        "created_at",
                        {
                            render: (content) => {
                                return (
                                    <td>
                                        <div className={"flex items-center justify-center"}>
                                            <JavButton onClick={() => {
                                                setSelectedItem(content)
                                                setSelectedType("group");
                                                setSelectedAction("delete");
                                            }} className={"p-1"} bgColor={"bg-gray-200 "}>
                                                <DeleteIcon />
                                            </JavButton>
                                        </div>
                                    </td>
                                )
                            }
                        }
                    ]}
                />
            </div>




        </div>
    )
}