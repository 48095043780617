import Table from "../../Shared/Components/Table";
import {Link, useLocation, useNavigate} from "react-router-dom";
import {useEffect, useState} from "react";
import {useParams} from "react-router";
import CircleProgress from "../../Shared/Components/CircleProgress";
import JavInput from "../../Shared/Components/Forms/JavInput";
import JavButton from "../../Shared/Components/Buttons/JavButton";
import BlockIcon from "../../Shared/Components/Icons/BlockIcon";
import {useDispatch, useSelector} from "react-redux";
import {fetchBusinessKYCLevel, updateKYCDetails} from "./kyc/duck/action";
import Loading from "../../Shared/Components/Loading";
import LoadingIcon from "../../Shared/Components/Icons/LoadingIcon";
import EditIcon from "../../Shared/Components/Icons/EditIcon";

export default function BusinessDetail() {

    const navigate = useNavigate();
    const location = useLocation();
    const {business} = location.state;
    const {id} = useParams();
    const dispatch = useDispatch();
    const kycState = useSelector( (state) => state.kyc);

    useEffect(() => {
        dispatch(fetchBusinessKYCLevel(id))
    },[id]);

    useEffect(() => {

        console.log("kyc_level",kycState.kyc_level);
    },[kycState.kyc_level])


    const [form,setForm] = useState({
        legal_name: "",
        trade_name: "",
    });


    const handleChanges = (event) => {
        const value = event.target.value;
        setForm({
            ...form,
            [event.target.name]: value
        })
    }




    return (
        business &&
        <div>



            {
                1 === 1 &&
                <div className={"bg-white flex justify-between shadow-sm p-2 rounded"}>

                    <div className={"flex"}>
                        <div className={"h-14 w-14 rounded-lg text-white flex items-center justify-center bg-blue-500"}>
                            NM
                        </div>

                        <div className={"mx-4"}>
                            <h2 className={"text-xl font-firmaBold"}>{business.business_name}</h2>
                            <span className={"text-sm text-gray-600 font-light"}>
                        Global fintech company tailored for b2b and b2p clienteles
                    </span>

                            <div className={"flex text-xs text-gray-500"}>

                                <div className={"flex"}>
                                    www.javolin.com
                                </div>

                                <div className={"flex mx-4"}>
                                    +233577719143
                                </div>

                                <div className={"flex"}>
                                    {business.business_address}
                                </div>

                            </div>



                        </div>
                    </div>

                    <div className={"flex flex-col justify-center items-start mx-10"}>

                        <div className={"text-sm flex items-center justify-center"}>
                            <h3 className={"font-proximaBold text-sm"}>KYC Level:</h3>
                            {
                                kycState.kyc_level.loading &&
                                <LoadingIcon class={" mx-4 h-4 animate-spin h-5 w-5 mx-2 fill-blue-700"} />
                            }
                            {
                                kycState.kyc_level.data &&
                                <div className={"flex items-center"}>
                                    <span className={"ml-4 "}> { kycState.kyc_level.data}% </span>

                                    <div onClick={() => {
                                        navigate(`/businesses/kyc/${id}`,{state: {business: business}})
                                    }} className={"mx-1 cursor-pointer border rounded border-blue-300  p-0.5"}>
                                        <EditIcon class={"h-4 fill-gray-600"} />
                                    </div>

                                </div>
                            }
                        </div>


                    </div>

                </div>
            }



            {/*<div className={"grid grid-cols-2 dark:bg-[#242A38] rounded border dark:border-gray-600 gap-x-12 gap-y-4 py-4 px-4 my-2"}>*/}

            {/*    <JavInput*/}
            {/*        title={"Client Organization's Complete Legal Name"}*/}
            {/*        name={"legal_name"}*/}
            {/*        // value={form.legal_name}*/}
            {/*        // onChange={handleChanges}*/}
            {/*    />*/}


            {/*</div>*/}

            <div className={"bg-white rounded my-4 p-2"}>
                <h2 className={"my-3 font-proximaBold dark:text-white"}>
                    Wallets
                </h2>


                <Table
                    columns={["#","Account Number","Account Type","Balance","Timestamp","Action"]}
                    link={`admin-portal/wallets?customer_id=${id}`}
                    tag={"detail_wallets"}
                    fields={["id","account_number","",{
                        render: (content) => {
                            return (
                                <td className={""}>
                                    <div className={"flex items-center justify-center"}>
                                         <span className={"text-center"}>
                                            {content.currency ? content.currency.symbol : ""} {content.current_balance}
                                        </span>
                                    </div>

                                </td>
                            )
                        }
                    },"created_at",{
                        render: () => {
                            return (
                                <td className={""}>
                                    <div className={`flex justify-center`}>

                                        <JavButton onClick={() => {
                                            // setSelectItemModel(content)
                                        }} className={"p-1 mx-2"} bgColor={"bg-gray-200 "}>
                                            <BlockIcon/>
                                        </JavButton>

                                    </div>
                                </td>
                            )
                        }
                    }]}
                />

            </div>


            <div className={"bg-white rounded my-4 p-2"}>
                <h2 className={"my-3 font-proximaBold dark:text-white"}>
                    Latest Transactions
                </h2>
                <Table
                    columns={[ "#", "Reference","platform","Amount","Fee","Status","Timestamp"]}
                    link={"admin-portal/transactions?module=COREPAY"}
                    fields={[
                        "id",
                        {
                            id: "reference",
                            render: (content) => {
                                return (
                                    <td>
                                            <span onClick={() => {
                                                navigate(`/transaction/${content.id}`)
                                            }} className={"px-2 text-blue-500 underline cursor-pointer"}>{ content.reference}</span>
                                    </td>
                                )
                            },
                        },"platform",{
                            id: "amount",
                            render: (content) => {
                                return (
                                    <td className={"text-center"}>
                                        <span> GHS { content.amount} </span>
                                    </td>
                                )
                            }
                        },{
                            id: "fee",
                            render: (content) => {
                                return (
                                    <td className={"text-center"}>
                                        <span> GHS { content.fee} </span>
                                    </td>
                                )
                            }
                        },"status","created_at",
                    ]}
                    tag={"detail_form_transaction"}
                />
            </div>


        </div>
    )
}