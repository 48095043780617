export const ActionTypes = {

    REQUEST_FETCH: "REQUEST_FETCH_TRANSACTIONS",
    FETCH_SUCCESS: "FETCH_SUCCESS_TRANSACTIONS",
    FETCH_ERROR: "FETCH_ERROR_TRANSACTIONS",

    REQUEST_DETAILS: "REQUEST_DETAILS",
    DETAILS_SUCCESS: "DETAILS_SUCCESS",
    DETAILS_ERROR: "DETAILS_ERROR",

    REQUEST_UPLOAD: "REQUEST_UPLOAD",
    UPLOAD_SUCCESS: "UPLOAD_SUCCESS",
    UPLOAD_ERROR: "UPLOAD_ERROR",

    REQUEST_UPLOAD_TRANSACTION_DOCUMENT: "REQUEST_UPLOAD_TRANSACTION_DOCUMENT",
    SUCCESS_UPLOAD_TRANSACTION_DOCUMENT: "SUCCESS_UPLOAD_TRANSACTION_DOCUMENT",
    ERROR_UPLOAD_TRANSACTION_DOCUMENT: "ERROR_UPLOAD_TRANSACTION_DOCUMENT"

}