import {useDispatch, useSelector} from "react-redux";
import {useEffect, useRef, useState} from "react";
import JavButton from "../../Shared/Components/Buttons/JavButton";
import Table from "../../Shared/Components/Table";
import YesNoDialog from "../../Shared/Components/Dialog/YesNoDialog";
import {approveTransaction} from "../PendingTransactions/duck/action";
import {useNavigate} from "react-router-dom";
import JavInput from "../../Shared/Components/Forms/JavInput";
import {getDashboard} from "../Dashboard/duck/action";
import DatePicker from "react-datepicker";
import JavFormSelect from "../../Shared/Components/Forms/JavFormSelect";

export default function SpotDeals() {

    const transactionsState = useSelector( (state) => state.transactions)
    const [approveLoading,setApproveLoading] = useState(false);
    const [selectedItemModel,setSelectItemModel] = useState(null);
    const dispatch = useDispatch();
    const columns = [ "#", "Business","Transaction Id","Amount","Module","Created At"];
    const [loadingId,setLoadingId] = useState(null);
    const [tableVersion,setTableVersion] = useState(0);
    const navigate = useNavigate();
    const [dateRange, setDateRange] = useState([null, null]);
    const [startDate, endDate] = dateRange;

    const [form,setForm] = useState({
        legal_name: "",
        trade_name: "",
        address: "",
        business_telephone_number: "",
        legal_structure: "",
        tax_id: "",
        primary_activities: "",
        website_link: "",
        formation_country: "",
        formation_state: "",
        incorporation_number: "",
    });

    const handleChanges = (event) => {
        const value = event.target.value;
        setForm({
            ...form,
            [event.target.name]: value
        })
    }




    return (
        <div className={""}>

            <YesNoDialog
                open={selectedItemModel != null}
                title={"Are you sure ?"}
                yesLoading={approveLoading}
                onYesClicked={() => {
                    dispatch(approveTransaction(selectedItemModel.id));
                    setLoadingId(selectedItemModel.id)
                    setSelectItemModel(null);
                }}
                onNoClicked={() => setSelectItemModel(null)}
                onCloseClicked={() => setSelectItemModel(null)}
            >
                <div className="text-xs py-3">
                    Are you sure approve this transaction ?
                </div>
            </YesNoDialog>

            <div className={"grid grid-cols-2 mb-10 bg-gray-50 dark:bg-[#242A38] rounded border dark:border-gray-600 gap-x-0 gap-y-0 py-4 px-4 my-2"}>


                <div className={"col-span-2 flex my-2"}>

                    <h4 className={"text-sm font-proximaBold dark:text-white text-gray-600"}>Filter Spot Deals with</h4>

                </div>

                <JavInput
                    title={"Reference"}
                    name={"reference"}
                    value={form.legal_name}
                    onChange={handleChanges}
                    isColumn={true}
                    hideBorder={true}
                />

                <JavInput
                    title={"Customer"}
                    name={"user_id"}
                    value={form.legal_name}
                    onChange={handleChanges}
                    isColumn={true}
                    hideBorder={true}
                />

                <JavFormSelect
                    items={[
                        "All",
                        "Pending",
                        "Failed",
                        "Completed",
                        "Pending approval",
                        "Pending javolin approval"
                    ]}
                    position={"bottom"}
                    onChange={(item) => {
                        console.log(item);
                        setForm({
                            ...form,
                            role_id: item.id
                        })
                    }}
                    title={"Transaction Status"}
                    className={""}
                    isColumn={true}
                    hideBorder={true}
                />


                <div className={'flex'}>
                    <h3 className={`text-sm font-proximaBold text-gray-600 w-2/5 bg-gray-100 dark:bg-[#242A38] flex items-center px-2`}>Date Range</h3>
                    <div className={'w-3/5'}>
                        <DatePicker
                            className={"px-1 text-gray-600 text-xs py-2 dark:bg-[#242A38] " +
                            "outline-none dark:text-gray-200 w-full " +
                            "dark:border-gray-300 "}
                            onChange={(dates) => {
                                setDateRange(dates)
                                dispatch(getDashboard());
                            }}
                            startDate={startDate}
                            endDate={endDate}
                            selectsRange={true}
                        />
                    </div>

                </div>


                <div className={"col-span-2 flex justify-end my-2"}>

                    <JavButton
                        title={"Search"}
                        padding={"px-16 py-3"}
                        textColor={"text-white"}
                        onClick={() => {}}
                    />

                </div>




            </div>

            <div className="dark:bg-[#242A38] bg-gray-50 dark:border-gray-500 border py-2 mb-4 rounded-lg px-2">
                <Table
                    columns={[ "#", "Customer","Selling","Buying","Rate","Order Id","Status","Timestamp"]}
                    link={"admin-portal/spot-deals"}
                    tag={"spot-deals.table"}
                    fields={[
                        "id",
                        {
                            id: "reference",
                            render: (content) => {
                                return (
                                    <td className={"text-center"}>
                                        <span>
                                            {content.business && content.business.business_name || ""}
                                        </span>
                                    </td>
                                )
                            },
                        },{
                            id: "sender",
                            render: (content) => {
                                return (
                                    <td className={"text-center"}>
                                         <span>
                                            {JSON.parse(content.sender).currency}
                                             {" "}
                                             {Number.parseFloat(JSON.parse(content.sender).amount).toFixed(2)}
                                        </span>
                                    </td>
                                )
                            }
                        },{
                            id: "recipient",
                            render: (content) => {
                                return (
                                    <td className={"text-center"}>
                                         <span>
                                            {JSON.parse(content.recipient).currency}
                                             {" "}
                                             {Number.parseFloat(JSON.parse(content.recipient).amount).toFixed(2)}
                                        </span>
                                    </td>
                                )
                            }
                        },{
                            id: "response",
                            render: (content) => {
                                return (
                                    <td className={"text-center"}>
                                        <span> {JSON.parse(content.response).response && JSON.parse(content.response).response.content.rate.rateType} </span>
                                        <span> {JSON.parse(content.response).rate && JSON.parse(content.response).rate.rateType} </span>
                                        {" -> "}
                                        <span> {JSON.parse(content.response).response && JSON.parse(content.response).response.content.rate.value} </span>
                                        <span> {JSON.parse(content.response).rate && JSON.parse(content.response).rate.value} </span>
                                    </td>
                                )
                            }
                        },{
                            id: "order_id",
                            render: (content) => {
                                return (
                                    <td className={"text-center"}>
                                        <span className={"underline cursor-pointer text-blue-500"}>
                                            {content.order_id}
                                        </span>
                                    </td>
                                )
                            }
                        },{
                            id: "status",
                            render: (content) => {
                                return (
                                    <td className={"text-center"}>
                                        <span className={`${content.status === 'CHECKED' ? 'bg-yellow-300': 'bg-blue-300' }  p-1 rounded text-xs font-proximaBold`}> {content.status.toLowerCase()} </span>
                                    </td>
                                )
                            }
                        },"created_at",
                    ]}
                />
            </div>


        </div>

    )
}