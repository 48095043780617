import {useEffect, useState} from "react";
import JavInput from "../../../../Shared/Components/Forms/JavInput";
import Cookies from "js-cookie";
import JavTextArea from "../../../../Shared/Components/Forms/JavTextArea";
import JavButton from "../../../../Shared/Components/Buttons/JavButton";

export default function BusinessApproval() {

    const user = JSON.parse(Cookies.get("javAdmin"));
    const [form,setForm] = useState({
        remarks: "",
        signature: "",
    });

    const handleChanges = (event) => {
        const value = event.target.value;
        setForm({
            ...form,
            [event.target.name]: value
        })
    }

    const onContinueTapped = () => {

    }



    return (
        <div className={"grid grid-cols-1 dark:bg-[#242A38] rounded border dark:border-gray-600 gap-x-12 gap-y-4 py-4 px-4 my-2"}>



            <JavInput
                title={"Approved By"}
                value={user.name}
                onChange={handleChanges}
            />


            <JavTextArea
                title={"Remarks"}
                rows={5}
                placeholder={"Remarks here..."}
                name={"remarks"}
                value={form.remarks}
                onChange={handleChanges}
            />


            <div className={"flex justify-end my-1"}>

                <JavButton
                    title={"Approve"}
                    padding={"px-16 py-3"}
                    textColor={"text-white"}
                    isLoading={false}
                    onClick={onContinueTapped}
                />

            </div>


        </div>
    )
}