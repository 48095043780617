import React from 'react';
import {
    Chart as ChartJS,
    RadialLinearScale,
    ArcElement,
    Tooltip,
    Legend,
} from 'chart.js';
import { PolarArea } from 'react-chartjs-2';

export default function CountryOverview() {


    ChartJS.register(RadialLinearScale, ArcElement, Tooltip, Legend);

    const options = {
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
            legend: {
                position: 'right',
                labels: {
                    font: {
                        size: 13,
                        family: "proxima-bold"
                    }
                }
            },
        },
        scales: {
            r: {
                ticks: {
                    display: true
                },
                grid: {
                    borderColor: "#FFF",
                    display: true,
                    borderDash: [20, 10],
                    tickColor: '#ddd'
                }
            },
        }
    }
    const data = {
        labels: ['Ghana', 'United States', 'Senegal', 'Nigeria', 'Canada', 'Brazil'],
        datasets: [
            {
                label: '# of Votes',
                data: [12, 19, 2, 1, 2, 1],
                backgroundColor: [
                    '#ef4444',
                    '#047857',
                    '#64748b',
                    'rgba(75, 192, 192, 0.5)',
                    'rgba(153, 102, 255, 0.5)',
                    'rgba(255, 159, 64, 0.5)',
                ],
                borderWidth: 2,
            },
        ],
    };

    return <PolarArea data={data} options={options}/>;

}