import NavBar from "../../Shared/Components/Nav/NavBar";
import Toolbar from "../../Shared/Toolbar";
import {Outlet, useLocation, useNavigate, useRoutes} from "react-router-dom";
import {useEffect, useState} from "react";
import {useSelector} from "react-redux";
import Alert from "../../Shared/Components/Alert";
import ChatBubbleIcon from "../../Shared/Components/Icons/ChatBubbleIcon";
import JavChat from "../../Shared/Components/Chat";

export default function AppLayout() {
    const location = useLocation();
    const navigate = useNavigate();
    const [theme,setTheme] = useState(localStorage.theme || "white");
    const isAuth = !!useSelector((state) => state.auth.login.token);
    const [currentPath,setCurrentPath] = useState(null);

    const changeModel = () => {
        if (localStorage.theme === undefined || localStorage.theme === "dark") {
            localStorage.theme = "white";
            setTheme("white")
            document.documentElement.classList.remove('dark');
        }else {
            localStorage.theme = "dark";
            setTheme("dark");
            document.documentElement.classList.add('dark');
        }
    }

    useEffect(() => {
        if (location.pathname === "/") {
            navigate(isAuth ? "/dashboard" : "/login")
        }
        if (currentPath && currentPath !== location?.pathname) {
            //this previous page
        }
        setCurrentPath(location?.pathname);
    },[location]);



    return (
        <div className="relative flex h-screen w-screen bg-blue-50/40 dark:bg-[#141925]">
            <Alert/>

            <div className="hidden md:block w-64
                            dark:bg-[#181E2C] bg-blue-50 border dark:border-[#181E2C]
                            overflow-hidden overflow-y-auto">

                <div onClick={changeModel} className="h-20 flex pl-6 mt-4">
                    <img src={theme === "dark" ? "/images/logo_white.png" : "/images/logo.png"}
                         className="h-10"/>
                </div>

                <div className="pl-6 mt-2">
                    <NavBar/>
                </div>

            </div>

            <div className="static flex-1 mx-6 overflow-hidden overflow-y-auto">

                <Toolbar className={"bg-gray-50 dark:bg-[#141925] dark:border-[#141925]"}/>

                <div className="mt-6 h-full">
                    <Outlet />
                </div>

            </div>

            <JavChat/>

        </div>
    )
}