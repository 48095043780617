import CloseIcon from "../Icons/CloseIcon";
import {useEffect} from "react";
import JavButton from "../Buttons/JavButton";
import LoadingIcon from "../Icons/LoadingIcon";

export default function Loading(props) {

    useEffect(() => {

    },[])

    if (!props.open) {
        return <></>;
    }

    return (
        <div className="absolute flex z-10 justify-center
                        items-center p-2 top-0 left-0 w-screen h-screen
                        backdrop-blur-sm">

            <div className={"rounded"}>

                <LoadingIcon className={`animate-spin h-8 w-8 mx-2 font-black text-4xl fill-black ${props.isLoading ? 'block' : 'hidden'}`}/>

            </div>

        </div>
    )
}