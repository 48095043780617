import JavInput from "../../../Shared/Components/Forms/JavInput";
import {useEffect, useState} from "react";
import JavButton from "../../../Shared/Components/Buttons/JavButton";
import {useDispatch, useSelector} from "react-redux";
import {updateAccountDetails} from "../duck/action";
import {useAlert} from "../../../Shared/Context/AlertContext";

export default function SettingsAccount(props) {

    const authState = useSelector( (state) => state.auth)
    const settingsState = useSelector( (state) => state.settings)
    const dispatch = useDispatch();
    const { showAlert } = useAlert();

    const [form,setForm] = useState({
        full_name: authState?.login?.user?.name,
        email: authState?.login?.user?.email,
        phone_number: authState?.login?.user?.phone_number
    });

    useEffect(() => {
        if(settingsState.update_account_details?.success) {
            showAlert("updated successfully","green");
        }
        if (settingsState.update_account_details?.error) {
            showAlert("Error: "+settingsState.update_account_details.error,"red");
        }
    },[settingsState.update_account_details])


    const handleChanges = (event) => {
        const value = event.target.value;
        setForm({
            ...form,
            [event.target.name]: value
        })
    }

    const changeAccountDetails = () => {
        dispatch(updateAccountDetails(authState.user.id,{phone_number: form.phone_number}))
    }

    return (
        <div className={"px-6 py-2"}>


            <h1 className={"text-3xl text-[#000]/76 dark:text-white font-proximaBold"}>
                Accounts
            </h1>


            <div className={"grid grid-cols-2 dark:bg-[#242A38] rounded  dark:border-gray-600 gap-x-12 gap-y-4 py-4 my-2"}>

                <div className={"col-span-2 mt-4  font-proximaBold"}>
                    <h2 className={"text-gray-700 dark:text-gray-300"}>Change Profile Details</h2>
                </div>

                <JavInput
                    title={"Full name"}
                    name={"name"}
                    disabled={true}
                    value={form.full_name}
                    onChange={handleChanges}
                />

                <JavInput
                    title={"Email"}
                    name={"email"}
                    disabled={true}
                    value={form.email}
                    onChange={handleChanges}
                />

                <JavInput
                    title={"Phone Number"}
                    name={"phone_number"}
                    type={"number"}
                    value={form.phone_number}
                    onChange={handleChanges}
                />

                <div className={"col-span-2 flex justify-end my-1"}>

                    <JavButton
                        title={"Change Account Details"}
                        padding={"px-16 py-3"}
                        textColor={"text-white"}
                        isLoading={settingsState.update_account_details.loading}
                        onClick={changeAccountDetails}
                    />

                </div>



            </div>



        </div>
    )
}