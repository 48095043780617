import Table from "../../../../Shared/Components/Table";
import {useEffect} from "react";
import moment from "moment";

export default function TransactionTracking(props) {

    useEffect(() => {
        console.log("TransactionTracking",props.data)
    },[props])
    return (
        <div className={"bg-gray-50 p-2"}>
            <Table
                columns={["No","Process","Done By","Reference","Timestamp"]}
                data={props.data.map( (item,index) => {

                    let user = "-";
                    let timestamp = "-";
                    if (item.process.length > 0) {
                        user = item.process.map((it,idx) => {
                            const pre = item.process.length > 1 ? `${idx + 1}.` : '';
                            return `${pre} ${it.creator.full_name || it?.creator?.name}`
                        })

                        timestamp = item.process.map((it,idx) => {
                            const pre = item.process.length > 1 ? `${idx + 1}.` : '';
                            return `${pre} ${moment(it.created_at).toDate()}`;
                        })
                    }

                    return {
                        fields: [
                            {
                                title: index + 1
                            },
                            {
                                render: () => {
                                    return (
                                        <div className={"h-10 flex justify-center items-center text-center"}>
                                            {item.status_name} [ {item.status_code} ]
                                        </div>
                                    )
                                }
                            },
                            {
                                title: user
                            },
                            {
                                title: "-"
                            },
                            {
                                title: timestamp
                            }
                        ]
                    }
                })}
            />
        </div>
    )
}
