import BackArrowIcon from "../../../Shared/Components/Icons/Arrow/BackArrowIcon";
import {useLocation, useNavigate} from "react-router-dom";
import Copy from "../../../Shared/Components/Copy";
import {useEffect, useState} from "react";
import Loading from "../../../Shared/Components/Loading";
import {useDispatch, useSelector} from "react-redux";
import {getTransactionDetail} from "../duck/action";
import {useParams} from "react-router";
import JavButton from "../../../Shared/Components/Buttons/JavButton";
import Tab from "../../../Shared/Components/Tabs";
import TransactionSummary from "./tabs/summary";
import TransactionTracking from "./tabs/tracking";
import TransactionTickets from "./tabs/tickets";
import TransactionNerd from "./tabs/nerd";
import {formatCurrency, makeJavolinRequest} from "../../../Shared/Utils/common";
import {approveTransaction} from "../../PendingTransactions/duck/action";
import YesNoDialog from "../../../Shared/Components/Dialog/YesNoDialog";
import TransactionDocument from "./tabs/document";
import {useAlert} from "../../../Shared/Context/AlertContext";

export default function TransactionDetail(props) {

    const navigate = useNavigate();
    const { state } = useLocation();
    const transactionState = useSelector( (state) => state.transactions)
    const pendingTransactionsState = useSelector( (state) => state.pendingTransactions)
    const [selectedItemModel,setSelectItemModel] = useState(null);
    const [approveLoading,setApproveLoading] = useState(false);
    const { showAlert } = useAlert();
    const dispatch = useDispatch();
    const params = useParams();
    const [form,setForm] = useState({
        notes: ""
    });

    const handleChanges = (event) => {
        const value = event.target.value;
        setForm({
            ...form,
            [event.target.name]: value
        })
    }


    const [transactionDetail,setTransactionDetail] = useState({
        saveNotes: {
            loading: false,
            data: null,
            error: null
        }
    });

    const saveNotes = () => {
        setTransactionDetail({...transactionDetail, saveNotes: {...transactionDetail.saveNotes, loading: true}  })
        makeJavolinRequest({
            path: `admin-portal/transactions/${transactionState?.details?.data?.id}/save-notes`,
            method: "POST",
            data: {
                notes: form?.notes
            }
        }, null, null, (data) => {
            setTransactionDetail({...transactionDetail, saveNotes: {...transactionDetail.saveNotes, loading: false, data: data}  })
            console.log("data",data);
            alert("saved successfully")
        }, (error) => {
            setTransactionDetail({...transactionDetail, saveNotes: {...transactionDetail.saveNotes, loading: false, error: error}  })
            console.log("error is ", error)
        })
    }


    useEffect(() => {
        console.log("state is ",state);
        dispatch(getTransactionDetail(params.transactionId))
    },[])

    const getApproved = () => {
        if (transactionState.details.data) {
            let filtered = transactionState.details.data.pending_transaction.filter((item) => {
                return !item.is_for_admin
            })
            return filtered.length > 0 ? filtered[0] : null
        }
        return null;
    }


    useEffect(() => {
        if (pendingTransactionsState.approve_transaction?.error) {
            setSelectItemModel(null);
            setApproveLoading(false);
            showAlert(pendingTransactionsState.approve_transaction?.error,"red");
        }

        if (pendingTransactionsState.approve_transaction?.success) {
            setSelectItemModel(null);
            setApproveLoading(false);
            showAlert(pendingTransactionsState.approve_transaction?.data?.message,"green");
        }

    },[pendingTransactionsState?.approve_transaction])



    return (
        <div className={" py-2 px-1 rounded "}>

            <YesNoDialog
                open={selectedItemModel != null}
                title={"Are you sure ?"}
                yesLoading={approveLoading}
                onYesClicked={() => {
                    let pendingTransaction = selectedItemModel["pending_transaction"].filter(pending => pending["action"] === "instructPayment" && pending["is_for_admin"])[0];
                    dispatch(approveTransaction(pendingTransaction?.id));
                    setApproveLoading(true);
                }}
                onNoClicked={() => setSelectItemModel(null)}
                onCloseClicked={() => setSelectItemModel(null)}
            >
                <div className="text-xs py-3">
                    Are you sure approve this transaction ?
                </div>
            </YesNoDialog>



            <div className={"flex items-center cursor-pointer"} onClick={() => {
                navigate(-1,{
                    state: {
                        form: "form "
                    }
                })
                localStorage.navigation = JSON.stringify(state)
            }}>
                <BackArrowIcon class={"h-6 w-6 dark:text-white"}/>
                <span className={"text-sm mx-2 dark:text-gray-50 text-gray-600"}>
                    Back to previous page
                </span>
            </div>

            <div className={"mt-8 py-4 rounded border dark:border-gray-600 dark:bg-gray-800 bg-gray-50 mx-1 flex justify-between"}>

                <div className={"flex items-center"}>
                    <h2 className={"text-3xl mx-1 dark:text-white font-bold"}>
                        GHS {transactionState.details.data && formatCurrency(transactionState.details.data.amount)}
                    </h2>
                    <span className={"bg-red-100 text-red-500 rounded text-xs p-1 font-bold mx-2"}>
                        {transactionState.details.data && transactionState.details.data.status.toLowerCase()}
                    </span>
                </div>

                <div className={"flex mx-2"}>

                    <Copy text={transactionState.details.data && transactionState.details.data.reference}/>

                </div>

            </div>


            <div className={"flex mb-5 justify-end"}>
                <JavButton
                    title={"Download Transaction"}
                    textColor={"text-white"}
                    className={"mt-5"}
                />
            </div>

            <Tab>
                <TransactionSummary
                    title={"Summary"}
                    data={transactionState?.details?.data}
                    onSaveChanged={(notes) => {
                        setForm({
                            ...form,
                            notes: notes
                        })
                    }}
                />
                <TransactionTracking title={"Tracking"} data={transactionState.details.data && transactionState.details.data.tracking}/>
                <TransactionDocument title={"Documents"} data={transactionState?.details?.data}/>
                <TransactionTickets title={"Tickets"}/>
                <TransactionNerd title={"Nerds"}/>
            </Tab>

            <div className={"py-5"}></div>
            <div className={"flex mb-5 justify-end"}>
                <JavButton
                    title={"Save Notes"}
                    textColor={"text-white"}
                    bgColor={"bg-blue-300"}
                    className={"mt-5 mx-2"}
                    onClick={saveNotes}
                    isLoading={transactionDetail?.saveNotes?.loading}
                />
            {
                transactionState.details.data &&
                transactionState.details.data.pending_transaction.filter(item => item.is_for_admin).length > 0 &&
                transactionState.details.data.status.toLowerCase() === "waiting_jav_admin_approval" &&
                <>
                    <JavButton
                        title={"Decline Transaction"}
                        textColor={"text-white"}
                        bgColor={"bg-red-600"}
                        className={"mt-5"}
                    />
                    <JavButton
                        title={"Approve Transaction"}
                        textColor={"text-white"}
                        className={"mt-5 mx-2"}
                        onClick={() => {
                            setSelectItemModel(transactionState?.details?.data)
                        }}
                    />
                </>

            }
        </div>






            <Loading
                open={transactionState.details.loading}
                isLoading={transactionState.details.loading}
            >
            </Loading>


        </div>
    )
}