import {useDispatch, useSelector} from "react-redux";
import {useEffect, useMemo, useRef, useState} from "react";
import JavButton from "../../Shared/Components/Buttons/JavButton";
import Table from "../../Shared/Components/Table";
import YesNoDialog from "../../Shared/Components/Dialog/YesNoDialog";
import {approveTransaction} from "../PendingTransactions/duck/action";
import {useLocation, useNavigate} from "react-router-dom";
import JavInput from "../../Shared/Components/Forms/JavInput";
import {getDashboard} from "../Dashboard/duck/action";
import DatePicker from "react-datepicker";
import JavFormSelect from "../../Shared/Components/Forms/JavFormSelect";
import moment from "moment";
import ListSelectDialog from "../../Shared/Components/Dialog/ListSelectDialog";
import {buildQueryParamFromForm, formatCurrency} from "../../Shared/Utils/common";
import DownloadIcon from "../../Shared/Components/Icons/DownladIcon";
import DashboardStatItem from "./Components";
import JavMultiFormSelect from "../../Shared/Components/Forms/JavMultiFormSelect";
import TransactionCharts from "./transactions_charts";
import usePreviousPath from "../../Shared/Hooks/usePreviousPath";


const link = `admin-portal/transactions`;
export default function Transactions() {

    const { state } = useLocation();
    const transactionsState = useSelector( (state) => state.transactions)
    const [approveLoading,setApproveLoading] = useState(false);
    const [selectedItemModel,setSelectItemModel] = useState(null);
    const dispatch = useDispatch();
    const [loadingId,setLoadingId] = useState(null);
    const navigate = useNavigate();
    const [dateRange, setDateRange] = useState([null, null]);
    const [startDate, endDate] = dateRange;
    const [showSelectBusinessDialog,setShowSelectBusinessDialog] = useState(false);
    const tableRef = useRef();
    const tableState = useSelector( (state) => state.table)
    const statusRef = useRef();
    const previousPath = usePreviousPath();
    const navigation = JSON.parse(localStorage.navigation ?? "{}");

    const [form,setForm] = useState({
        reference: "",
        business_name: "",
        status: "",
        module: navigation?.form?.module,
        platform: "",
    });

    const handleChanges = (event) => {
        const value = event.target.value;
        setForm({
            ...form,
            [event.target.name]: value
        })
    }

    useEffect(() => {
        localStorage.navigation = "{}"
        console.log("navigation",navigation);
    },[]);


    const onSearchTapped = () => {
        let queryParams = buildQueryParamFromForm({...form, transaction_status: JSON.stringify(statusRef.current.getSelectedItems() ?? []), start_date: dateRange[0] ? moment(dateRange[0]).format("YYYY-MM-DD") : null, end_date: dateRange[1] ? moment(dateRange[1]).format("YYYY-MM-DD"): null});
        tableRef.current.reloadTable(`${link}?${queryParams}`)
    }


    return (
        <div className={""}>

            <YesNoDialog
                open={selectedItemModel != null}
                title={"Are you sure ?"}
                yesLoading={approveLoading}
                onYesClicked={() => {
                    dispatch(approveTransaction(selectedItemModel.id));
                    setLoadingId(selectedItemModel.id)
                    setSelectItemModel(null);
                }}
                onNoClicked={() => setSelectItemModel(null)}
                onCloseClicked={() => setSelectItemModel(null)}
            >
                <div className="text-xs py-3">
                    Are you sure approve this transaction ?
                </div>
            </YesNoDialog>


            <ListSelectDialog
                link={link}
                open={showSelectBusinessDialog}
                searchField={"business_name"}
                render={(item,index) => {
                    return (
                        <div className={"flex justify-between"}>
                            {item.business_name}
                            <span className={item.business_address ? 'text-xs text-gray-400' : 'hidden'}>
                                {item.business_address}
                            </span>
                        </div>
                    )
                }}
                onCloseClicked={() => {setShowSelectBusinessDialog(false)}}
                onItemSelected={(item) => {
                    console.log("clicked",item)
                    setForm({
                        ...form,
                        user_id: item.id,
                        business_name: item.business_name
                    })
                    setShowSelectBusinessDialog(false)
                    return false;
                }}
            />



            <div className={"grid grid-cols-2 mb-10 bg-gray-50 dark:bg-[#242A38] rounded border dark:border-gray-600 gap-x-0 gap-y-0 py-4 px-4 my-2"}>


                <div className={"col-span-2 flex my-2"}>

                    <h4 className={"text-sm font-proximaBold dark:text-white text-gray-600"}>Filter Transactions with</h4>

                </div>


                <div className={"border col-span-2 rounded  grid grid-cols-2"}>

                    <JavInput
                        title={"Reference"}
                        name={"reference"}
                        value={form.legal_name}
                        onChange={handleChanges}
                        isColumn={true}
                        hideBorder={true}
                    />

                    <JavInput
                        title={"Customer"}
                        name={"business_name"}
                        onFocus={() => {
                            setShowSelectBusinessDialog(true)
                        }}
                        value={form.business_name}
                        onChange={handleChanges}
                        isColumn={true}
                        hideBorder={true}
                    />


                    <JavMultiFormSelect
                        items={[
                            "PENDING",
                            "SUCCESS",
                            "WAITING_APPROVAL",
                            "WAITING_JAV_ADMIN_APPROVAL",
                            "PENDING_WIRE_DELIVERY",
                            "COMPLETED",
                            "REPAID",
                            "CANCELLED",
                        ]}
                        ref={statusRef}
                        position={"bottom"}
                        onChange={(item) => {
                            console.log(item);
                            setForm({
                                ...form,
                                status: item
                            })
                        }}
                        title={"Transaction Status"}
                        className={""}
                        isColumn={true}
                        hideBorder={true}
                    />

                    <JavFormSelect
                        items={[
                            "All",
                            "CorPay",
                            "Zenith Collection",
                            "Zenith Remittance",
                            "Seerbit",
                            "Gym",
                        ]}
                        position={"bottom"}
                        onChange={(item) => {
                            console.log(item);
                            setForm({
                                ...form,
                                platform: item
                            })
                        }}
                        title={"Platform"}
                        className={""}
                        isColumn={true}
                        hideBorder={true}
                    />

                    <JavFormSelect
                        items={[
                            "ALL",
                            "Forex",
                            "Airtime",
                            "Cross Border",
                            "Government",
                            "LOANS",
                            "INTERNATIONAL_LOANS",
                        ]}
                        position={"bottom"}
                        onChange={(item) => {
                            console.log(item);
                            setForm({
                                ...form,
                                module: item
                            })
                        }}
                        title={"Module"}
                        value={form?.module}
                        className={""}
                        isColumn={true}
                        hideBorder={true}
                    />


                    <div className={'flex'}>
                        <h3 className={`text-sm font-proximaBold text-gray-600 w-2/5 bg-gray-100 dark:bg-[#242A38] flex items-center px-2`}>Date Range</h3>
                        <div className={'w-3/5'}>
                            <DatePicker
                                className={"px-1 text-gray-600 text-xs py-2 dark:bg-[#242A38] " +
                                "outline-none dark:text-gray-200 w-full " +
                                "dark:border-gray-300 "}
                                onChange={(dates) => {
                                    setDateRange(dates);
                                }}
                                startDate={startDate}
                                endDate={endDate}
                                selectsRange={true}
                            />
                        </div>

                    </div>

                </div>


                <div className={"col-span-2 flex justify-end my-2"}>

                    <JavButton
                        title={"Search"}
                        padding={"px-16 py-3"}
                        textColor={"text-white"}
                        isLoading={tableState['transactions.table']?.loading}
                        onClick={() => onSearchTapped()}
                    />

                </div>

            </div>

            <div className="dark:bg-[#242A38] bg-gray-50 dark:border-gray-500 border py-2 mb-4 rounded-lg px-2">
                <Table
                    columns={[ "#","Business","Reference","Module","Amount","Fee","Status","Timestamp"]}
                    link={link}
                    ref={tableRef}
                    page={navigation?.config?.page}
                    tag={"transactions.table"}
                    hasCharts={true}
                    chartsContent={<TransactionCharts/>}
                    fields={[
                        "id",
                        "business.business_name",
                        {
                            id: "reference",
                            render: (content) => {
                                return (
                                    <td className={"text-center"}>
                                            <span onClick={() => {
                                                console.log("getConfig",tableRef?.current?.getConfig())
                                                navigate(`/transaction/${content.id}`,{
                                                    state: {
                                                        form,
                                                        config: tableRef?.current?.getConfig(),
                                                        path: document?.location?.pathname
                                                    }
                                                })
                                            }} className={"px-2 text-blue-500 underline cursor-pointer"}>{ content.reference}</span>
                                    </td>
                                )
                            },
                        },"module",{
                            id: "amount",
                            render: (content) => {
                                return (
                                    <td className={"text-center"}>
                                        <span> GHS { formatCurrency(content.amount)} </span>
                                    </td>
                                )
                            }
                        },{
                            id: "fee",
                            render: (content) => {
                                return (
                                    <td className={"text-center"}>
                                        <span> GHS { content.fee} </span>
                                    </td>
                                )
                            }
                        },"status","created_at",
                    ]}
                />
            </div>


        </div>

    )
}