import {ActionTypes} from "./type";
import {makeJavolinRequest} from "../../../Shared/Utils/common";


export const getDashboard = (start,end,type) => {
    return async function(dispatch) {
        dispatch({ type: ActionTypes.REQUEST_FETCH });
        makeJavolinRequest({
            path: `admin-portal/dashboard`,
            method: "GET",
            query: {
                start,end,
                chart_type: type
            }
        },{
            SUCCESS: ActionTypes.FETCH_SUCCESS,
            ERROR: ActionTypes.FETCH_ERROR
        },dispatch);
    }
}


