import {useState} from "react";
import {useNavigate} from "react-router-dom";
import {useSelector} from "react-redux";
import ListIcon from "../../Shared/Components/Icons/ListIcon";
import {PresentationChartLineIcon} from "../../Shared/Components/Icons/PresentationChartLineIcon";
import CreateCollectionDialog from "./components/CreateCollectionDialog";
import JavButton from "../../Shared/Components/Buttons/JavButton";
import {HorizontalChart} from "../../Shared/Components/Charts/HorizontalChart";
import {LineChart} from "../../Shared/Components/Charts/LineChart";
import Table from "../../Shared/Components/Table";

export default function Collections() {

    const navigate = useNavigate();
    const [open,setOpen] = useState(false);
    const renderOptions = [{ name: "Today", icon: ListIcon }, { name: "Live", icon: PresentationChartLineIcon } ];
    const tableState = useSelector( (state) => state.table)

    return (
        <div className={"px-2 py-5"}>


            <CreateCollectionDialog
                open={open}
                onCloseClicked={() => setOpen(false)}
                titleWithSubtitle={true}
            />

            <div className={"flex justify-between"}>
                <div>
                    <h3 className={"font-firmaBold text-xl text-gray-600"}>Overall Usage</h3>
                </div>

                <JavButton
                    title={"Create Collection Link"}
                    textColor={"text-white"}
                    onClick={() => setOpen(true)}
                />
            </div>

            <div className={""}>

            </div>

            <div className={"grid grid-cols-1 gap-5 p-5"}>


                {/*<div className={"rounded p-2 font-satoshiMedium text-sm border"}>*/}
                {/*    <div className={"py-2 flex flex-col border-b"}>*/}
                {/*        <span className={"font-satoshiBold"}>🚚Transactions </span>*/}
                {/*        <span className={"mt-1 text-gray-500"}>transactions graph</span>*/}
                {/*    </div>*/}

                {/*    <div className={"h-64"}>*/}
                {/*        <LineChart*/}
                {/*            data={tableState['documents.table']?.data?.messages}*/}
                {/*        />*/}
                {/*    </div>*/}

                {/*</div>*/}


                <div
                    className={"dark:bg-[#242A38] col-span-2 bg-white dark:border-gray-500 border py-2 mb-4 rounded-xl w-full shadow-sm px-2"}>
                    <Table
                        columns={[
                            "id", "reference", "name", "Amount List","preview" ,"created_at", "action"
                        ]}
                        fields={["id", {
                            render: (content) => {
                                return (
                                    <td onClick={ () => navigate(`/collections/${content?.id}`)} className={"underline cursor-pointer text-center text-blue-500"}>
                                        {content?.reference}
                                    </td>
                                )
                            }
                        },"name", {
                            render: (content) => {
                                return (
                                    <td className={"text-center"}>
                                        <ol className={""}>
                                            {
                                                content?.meta?.amount_list.map((item,index) => {
                                                    return <li>{index+ 1}. {item?.name}  [GHS {item?.amount}]</li>
                                                })
                                            }
                                        </ol>

                                    </td>
                                )
                            }
                        }, {
                            render: (content) => {
                                return (
                                    <td className={"text-center"}>
                                        <a className={"underline text-blue-500"} target={"_blank"} href={`http://localhost:3000/payment/${content?.id}`}>
                                            Preview Link
                                        </a>
                                    </td>
                                )
                            }
                        },"created_at", {
                            render: () => {
                                return (
                                    <td className={"text-center"}>
                                        <button>Delete</button>
                                    </td>
                                )
                            }
                        }]}
                        link={`admin-portal/collection-link`}
                        tag={"documents.table"}
                    />
                </div>


            </div>

        </div>
    )
}