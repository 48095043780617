import JavInput from "../../Shared/Components/Forms/JavInput";
import Modal from "../../Shared/Components/Modal";
import {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import JavFormSelect from "../../Shared/Components/Forms/JavFormSelect";

export default function AddAccount(props) {

    const dispatch = useDispatch();
    const districtState = useSelector( (state) => state.district)

    const handleChanges = (event) => {
        const value = event.target.value;
        setCredentials({
            ...credentials,
            [event.target.name]: value
        })
    }
    const [advance,setAdvance] = useState(false);
    const [credentials,setCredentials] = useState({
        institute: "",
        api_id: ""
    })

    const onSubmitted = () => {
        // dispatch(addDistrict(credentials));
    }

    useEffect(() => {

        // if (districtState.adding.success) {
        //     props.onDismissed();
        //     props.onSuccess("added successfully")
        // }
        // if (districtState.adding.error) {
        //     props.onSuccess(districtState.adding.error)
        // }

    },[districtState]);

    return (
        <Modal
            title={"Create APi Account"}
            open={props.open}
            onCloseClicked={props.onDismissed}
            onSubmitted={() => {
                onSubmitted()
            }}
        >
            <div className="grid text-gray-600 grid-cols-1 gap-y-3">

                <JavInput onChange={handleChanges} title={"Institute"} name={"institute"}/>

                <JavInput onChange={handleChanges} title={"API Id"} name={"api_id"}/>


            </div>


        </Modal>
    )
}