import {useDispatch, useSelector} from "react-redux";
import {useEffect, useRef, useState} from "react";
import JavButton from "../../Shared/Components/Buttons/JavButton";
import Table from "../../Shared/Components/Table";
import YesNoDialog from "../../Shared/Components/Dialog/YesNoDialog";
import {approveTransaction} from "../PendingTransactions/duck/action";
import {useNavigate} from "react-router-dom";
import EyeIcon from "../../Shared/Components/Icons/EyeIcon";

export default function SettlementAccounts() {

    const transactionsState = useSelector( (state) => state.transactions)
    const [approveLoading,setApproveLoading] = useState(false);
    const [selectedItemModel,setSelectItemModel] = useState(null);
    const dispatch = useDispatch();
    const columns = [ "#", "Business","Transaction Id","Amount","Module","Created At"];
    const [loadingId,setLoadingId] = useState(null);
    const [tableVersion,setTableVersion] = useState(0);
    const navigate = useNavigate();
    const [dateRange, setDateRange] = useState([null, null]);
    const [startDate, endDate] = dateRange;
    const [type,setType] = useState("FX");
    const [tableLink,setTableLink] = useState("admin-portal/corpay/settlement-accounts?type=FX");


    const [form,setForm] = useState({
        legal_name: "",
        trade_name: "",
        address: "",
        business_telephone_number: "",
        legal_structure: "",
        tax_id: "",
        primary_activities: "",
        website_link: "",
        formation_country: "",
        formation_state: "",
        incorporation_number: "",
    });

    const handleChanges = (event) => {
        const value = event.target.value;
        setForm({
            ...form,
            [event.target.name]: value
        })
    }




    useEffect(() => {
        setTableLink(`admin-portal/corpay/settlement-accounts?type=${type}`)
    },[type])

    return (
        <div className={""}>

            <YesNoDialog
                open={selectedItemModel != null}
                title={"Are you sure ?"}
                yesLoading={approveLoading}
                onYesClicked={() => {
                    dispatch(approveTransaction(selectedItemModel.id));
                    setLoadingId(selectedItemModel.id)
                    setSelectItemModel(null);
                }}
                onNoClicked={() => setSelectItemModel(null)}
                onCloseClicked={() => setSelectItemModel(null)}
            >
                <div className="text-xs py-3">
                    Are you sure approve this transaction ?
                </div>
            </YesNoDialog>



            <div className={"flex bg-gray-50 px-2 rounded py-2 border"}>
                <div onClick={() => setType('FX')} className={`mx-1 ${type === 'FX' ? 'bg-green-700 text-white' : 'bg-gray-100 text-gray-600' } flex p-1.5 rounded my-1 text-sm  items-center px-4 cursor-pointer`}>Forex Balance</div>
                <div onClick={() => setType('Bank Account')} className={`mx-1 ${type === 'Bank Account' ? 'bg-green-700 text-white' : 'bg-gray-100 text-gray-600' } flex p-1.5 rounded my-1 text-sm items-center px-10 cursor-pointer`}>Bank Accounts</div>
            </div>


            <div className="dark:bg-[#242A38] bg-gray-50 dark:border-gray-500 border py-2 mb-4 rounded-lg px-2">
                <Table
                    columns={[ "#", "Reference" ,"Account Name","Currency","Ledger Balance","Available Balance","Action"]}
                    link={tableLink}
                    tag={`settlement-accounts.${type}.table`}
                    fields={[
                        {
                            id: "id",
                            render: (content,index) => {
                                return (
                                    <td className={"text-center"}>
                                        {index + 1}
                                    </td>
                                )
                            }
                        },
                        {
                            id: "reference",
                            render: (content) => {
                                return (
                                    <td className={"text-center"}>

                                        <span>{content.id}</span>

                                    </td>
                                )
                            },
                        },{
                            id: "id",
                            render: (content) => {
                                return (
                                    <td className={"text-center"}>
                                        {
                                            type !== "FX" ?
                                                <span>{content.bankAccount} [ {content.bankName} ]</span> :
                                                <span>{content.accountName} [ {content.currText} ]</span>
                                        }
                                    </td>
                                )
                            }
                        },{
                            id: "id",
                            render: (content) => {
                                return (
                                    <td className={"text-center"}>
                                        {
                                            type !== "FX" ?
                                                <span>{content.currency}</span> :
                                                <span>{content.curr}</span>
                                        }
                                    </td>
                                )
                            }
                        },{
                            id: "id",
                            render: (content) => {
                                return (
                                    <td className={"text-center"}>
                                        {
                                            type !== "FX" ?
                                                <span>-</span> :
                                                <span>{content.ledgerBalance.toLocaleString('en-US', {
                                                    style: 'currency',
                                                    currency: content.curr,
                                                })
                                                }</span>
                                        }
                                    </td>
                                )
                            }
                        },{
                            id: "order_id",
                            render: (content) => {
                                return (
                                    <td className={"text-center"}>
                                        {
                                            type !== "FX" ?
                                                <span>-</span> :
                                                <span>{content.availableBalance.toLocaleString('en-US', {
                                                    style: 'currency',
                                                    currency: content.curr,
                                                })
                                                }</span>
                                        }
                                    </td>
                                )
                            }
                        },{
                            id: "status",
                            render: (content) => {
                                return (
                                    <td className={"text-center"}>
                                        <div className={`flex justify-center`}>

                                            <JavButton onClick={() => {
                                                navigate(`/fees/module/${content.id}`);
                                            }} className={"p-1 mx-2"} bgColor={"bg-gray-200 "}>
                                                <EyeIcon/>
                                            </JavButton>

                                        </div>
                                     </td>
                                )
                            }
                        },
                    ]}
                />
            </div>


        </div>

    )
}