import {useDispatch, useSelector} from "react-redux";
import {useEffect, useRef, useState} from "react";
import JavButton from "../../Shared/Components/Buttons/JavButton";
import Table from "../../Shared/Components/Table";
import CheckIcon from "../../Shared/Components/Icons/CheckIcon";
import YesNoDialog from "../../Shared/Components/Dialog/YesNoDialog";
import {approveTransaction} from "./duck/action";
import JavInput from "../../Shared/Components/Forms/JavInput";
import JavFormSelect from "../../Shared/Components/Forms/JavFormSelect";
import DatePicker from "react-datepicker";
import {getDashboard} from "../Dashboard/duck/action";
import {useNavigate} from "react-router-dom";
import {buildQueryParamFromForm, hasRole} from "../../Shared/Utils/common";
import {useAlert} from "../../Shared/Context/AlertContext";


export default function PendingTransactions() {

    const tableRef = useRef();
    const navigate = useNavigate();
    const pendingTransactionsState = useSelector( (state) => state.pendingTransactions)
    const [approveLoading] = useState(false);
    const [selectedItemModel,setSelectItemModel] = useState(null);
    const dispatch = useDispatch();
    let columns = [ "#", "Business","Transaction Id","Amount","Module","Created At","Action"];
    const [loadingId,setLoadingId] = useState(null);
    const [tableVersion] = useState(0);
    const [dateRange, setDateRange] = useState([null, null]);
    const link = `admin-portal/pending-transactions`;
    const [startDate, endDate] = dateRange;
    const { showAlert } = useAlert();



    let fields = ["id","business.business_name","transaction_id","transaction.amount",'transaction.module','created_at', {
        "id": "action",
        render: (content) => {
            return (
                <td className={""}>
                    <div className={`flex justify-center`}>

                        <JavButton
                            onClick={() => {
                                setSelectItemModel(content)
                            }}
                            className={"p-1 mx-2"}
                            bgColor={"bg-blue-600 "}
                            hideChildrenWhenLoading={true}
                            isLoading={
                                pendingTransactionsState.approve_transaction.loading &&
                                content.id === loadingId
                            }
                        >
                            <CheckIcon className={"text-gray-100"}/>
                        </JavButton>

                    </div>

                </td>
            )
        }
    }];




    const [form,setForm] = useState({
        legal_name: "",
        trade_name: "",
        address: "",
        business_telephone_number: "",
        legal_structure: "",
        tax_id: "",
        primary_activities: "",
        website_link: "",
        formation_country: "",
        formation_state: "",
        incorporation_number: "",
    });

    const handleChanges = (event) => {
        const value = event.target.value;
        setForm({
            ...form,
            [event.target.name]: value
        })
    }


    useEffect(() => {
        if (pendingTransactionsState?.approve_transaction?.error) {
            showAlert(pendingTransactionsState.approve_transaction.error,"red");
        }

        if (pendingTransactionsState?.approve_transaction?.success) {
            showAlert(pendingTransactionsState.approve_transaction.data?.message,"green");
            tableRef.current.reloadTable(`${link}?${buildQueryParamFromForm({})}`);
        }

    },[pendingTransactionsState?.approve_transaction])


    return (
        <div className={""}>


            <YesNoDialog
                open={selectedItemModel != null}
                title={"Are you sure ?"}
                yesLoading={approveLoading}
                onYesClicked={() => {
                    dispatch(approveTransaction(selectedItemModel.id));
                    setLoadingId(selectedItemModel.id)
                    setSelectItemModel(null);
                }}
                onNoClicked={() => setSelectItemModel(null)}
                onCloseClicked={() => setSelectItemModel(null)}
            >
                <div className="text-xs py-3">
                    Are you sure approve this transaction ?
                </div>
            </YesNoDialog>

            <div className={"grid grid-cols-2 mb-10 bg-gray-50 dark:bg-[#242A38] rounded border dark:border-gray-600 gap-x-12 gap-y-2 py-4 px-4 my-2"}>

                <div className={"col-span-2 flex my-2"}>

                    <h4 className={"text-sm font-proximaBold dark:text-white text-gray-600"}>Filter Pending Transactions with</h4>

                </div>

                <JavInput
                    title={"Reference"}
                    name={"reference"}
                    value={form.legal_name}
                    onChange={handleChanges}
                />

                <JavInput
                    title={"User Id"}
                    name={"user_id"}
                    value={form.legal_name}
                    onChange={handleChanges}
                />

                <JavFormSelect
                    items={[
                        "PENDING",
                        "FAILED",
                        "COMPLETED",
                        "PENDING_APPROVAL",
                        "PENDING_JAVOLIN_APPROVAL"
                    ]}
                    position={"bottom"}
                    onChange={(item) => {
                        console.log(item);
                        setForm({
                            ...form,
                            role_id: item.id
                        })
                    }}
                    title={"User Role"}
                    className={" mt-2"}/>


                <div>
                    <h3 className={`text-sm font-proximaBold text-gray-600`}>Date Range</h3>

                    <DatePicker
                        className={"px-14 text-xs py-2 mt-1 mx-1 dark:bg-[#242A38] dark:text-gray-200 w-full border rounded dark:border-gray-300"}
                        onChange={(dates) => {
                            setDateRange(dates)
                            dispatch(getDashboard());
                        }}
                        startDate={startDate}
                        endDate={endDate}
                        selectsRange={true}
                    />

                </div>


                <div className={"col-span-2 flex justify-end my-2"}>

                    <JavButton
                        title={"Search"}
                        padding={"px-16 py-3"}
                        textColor={"text-white"}
                        onClick={() => {}}
                    />

                </div>




            </div>

            <div className="dark:bg-[#242A38] bg-gray-50 dark:border-gray-500 border py-2 mb-4 rounded-lg px-2">
                <Table
                    link={link}
                    tag={"pending_transactions.table"}
                    ref={tableRef}
                    columns={columns}
                    currentVersion={tableVersion}
                    dependencies={{
                        pendingTransactionsState,
                        loadingId
                    }}
                    fields={fields}
                />
            </div>


        </div>

    )
}