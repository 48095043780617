import AddIcon from "../../../Shared/Components/Icons/AddIcon";
import Dialog from "../../../Shared/Components/Dialog/Dialog";
import {useEffect} from "react";

export default function ViewCollectionPaymentDetailsDialog(props) {

    useEffect(() => {
        console.log("props?.selectedItem?.meta",props?.selectedItem?.meta?.results?.response?.results[0]?.original_id_photo)
    }, [props?.selectedItem]);
    return (
        <Dialog position={"top"} icon={AddIcon} style={{width: '70%'}} open={props?.open}
                onCloseClicked={props?.onCloseClicked}
                title={"View Responses"} titleWithSubtitle={true}>


            <div className={"bg-gray-50"}>

                <div className={"text-sm flex items-center font-proximaBold py-3"}>
                    <div className={"h-2 mx-2 w-2 bg-green-600 rounded-full"}/>
                    KYC Details
                </div>

                <div className={"grid grid-cols-3 border-b border-gray-300"}>

                    <div className={"grid grid-cols-2"}>

                        <div className={"bg-gray-200 font-proximaBold text-sm py-3 px-2"}>
                            Front Image
                        </div>

                        <div className={"text-center text-gray-600 py-2"}>
                            <img alt={"frontImage"} src={props?.selectedItem?.meta?.results?.response?.results?.[0]?.original_id_photo} />
                        </div>
                    </div>

                </div>
            </div>





        </Dialog>

    )
}