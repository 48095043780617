import {useEffect, useState} from "react";
import {makeJavolinRequest} from "../../Shared/Utils/common";
import {ActionTypes} from "../AccountManagement/duck/type";
import CloseIcon from "../../Shared/Components/Icons/CloseIcon";
import JavButton from "../../Shared/Components/Buttons/JavButton";
import JavFormSelect from "../../Shared/Components/Forms/JavFormSelect";
import {useParams} from "react-router";

export default function PaymentScreen(props) {


    const {id} = useParams();

    const [payment, setPayment] = useState({
        details: {
            loading: false,
            data: null,
            error: null,
        },
        request_payment: {
            loading: false,
            data: null,
            error: null,
        },
        kyc_performed: false
    });


    const fetchDetails = () => {
        makeJavolinRequest({
            path: `admin-portal/collection-link/${id}`,
            method: "GET"
        },null,null,(data) => {
            console.log("data",data)
            setPayment({
                ...payment,
                details: {
                    ...payment.details,
                    loading: false,
                    data: data
                }
            })
        });
    }

    useEffect(() => {
        fetchDetails()
    }, []);

    const onRequestForKycClicked = () => {
        setPayment({
            ...payment,
            kyc_performed: true
        })
        openNewWindow(payment?.details?.data?.kyc_link)
    }

    const onRequestForPaymentClicked = () => {
        setPayment({...payment, request_payment: {...payment.request_payment, loading: true}})
        makeJavolinRequest({
            path: `admin-portal/collection-link/${id}/initiate-payment`,
            method: "POST",
            data: {
                "amount": payment?.amount
            }
        },null,null,(data) => {
            console.log("data",data)


            window.open(data["payment_link"], '_blank');


            setPayment({
                ...payment,
                request_payment: {
                    ...payment.request_payment,
                    loading: false,
                    data: data
                }
            })
        });
    }

    const openNewWindow = (url) => {
        const windowName = '_blank'; // '_blank' to open in a new tab, or a specific name to open in a new window
        const windowFeatures = 'width=800,height=600'; // Specify the window features like size

        window.open(url, windowName, windowFeatures);
    };


    return (
        <div className={"flex justify-center items-center"}>


            <div className={"w-1/2 border bg-white mt-10 rounded flex flex-col  min-h-52"}>

                <div
                    className={
                        "bg-jav-card-100 bg-bg-pattern relative py-8 flex items-center justify-center"
                    }
                >
                    <div className={"flex flex-col text-center"}>
              <span className={"font-firmaBold text-lg text-black"}>
             {payment?.details?.data?.name}
              </span>
                        <span className={"font-satoshiMedium"}>
               {props?.subTitle}
              </span>
                    </div>
                    <CloseIcon
                        onClick={props?.onCloseClicked}
                        className={
                            "stroke-current shadow-lg text-black stroke-2 absolute top-3 right-5 bg-white rounded-full cursor-pointer"
                        }
                    />
                </div>


                {
                    (!payment?.kyc_performed && payment?.details?.data != null )&&
                    <div className={"flex justify-center mt-10"}>
                        <JavButton
                            title={"Perform KYC"}
                            textColor={"text-white"}
                            onClick={onRequestForKycClicked}
                        />
                    </div>
                }


                {

                    (payment?.kyc_performed && payment?.details?.data != null ) &&


                    <div className={"mx-10 my-10"}>

                        {
                            <JavFormSelect
                                title={"Select Amount"}
                                position={"bottom"}
                                onChange={(item) => {
                                    setPayment({
                                        ...payment,
                                        amount: item.amount
                                    })
                                }}
                                items={payment?.details?.data?.meta?.amount_list?.map(item => {
                                    return {
                                        title: `${item.name} - ${item.amount}`,
                                        amount: item.amount
                                    }
                                })}
                            />
                        }


                        <div className={"flex justify-end mx-10 mt-10"}>
                            <JavButton
                                title={"Proceed"}
                                textColor={"text-white"}
                                isLoading={payment?.request_payment?.loading}
                                onClick={onRequestForPaymentClicked}
                            />
                        </div>
                    </div>


                }

            </div>


        </div>
    )
}