import AddIcon from "../../../../../../Shared/Components/Icons/AddIcon";
import Dialog from "../../../../../../Shared/Components/Dialog/Dialog";
import JavInput from "../../../../../../Shared/Components/Forms/JavInput";
import {useState} from "react";
import JavFormSelect from "../../../../../../Shared/Components/Forms/JavFormSelect";
import JavFormFileInput from "../../../../../../Shared/Components/Forms/JavFormFileInput";
import JavButton from "../../../../../../Shared/Components/Buttons/JavButton";
import {makeJavolinRequest} from "../../../../../../Shared/Utils/common";
import {useSelector} from "react-redux";
import JavTextArea from "../../../../../../Shared/Components/Forms/JavTextArea";

export default function CreateConfigDialog(props) {

    const [form,setForm] = useState({
        name: ""
    });
    const [createFunction,setCreateFunction] = useState({});

    const handleChanges = (event) => {
        const value = event.target.value;
        setForm({
            ...form,
            [event.target.name]: value
        })
    }

    const onCreateClicked = () => {
        setCreateFunction({...createFunction, loading: true})
        makeJavolinRequest({
            path: `admin-portal/connect/channels/${props?.channel?.id}/concerns`,
            method: "POST",
            data: {meta : form,concern: "configs"}
        },null,null,(data) => {
            setCreateFunction({...createFunction, data: data, loading: true})
            alert("Channel Function Created Successfully")
            props.onCloseClicked();
            console.log("data",data)
        },(error) => {
            setCreateFunction({...createFunction, loading: false})
            console.log("error",error)
        })
    }

    return (
        <Dialog position={"top"} icon={AddIcon} style={{width: '30%'}} open={props?.open} onCloseClicked={props?.onCloseClicked}
                title={"Create Config Variable"} titleWithSubtitle={true}>

            <div className={"my-8 grid gap-2"}>

                <JavInput
                    title={"Name"}
                    name={"name"}
                    value={form?.name}
                    onChange={(change) => {
                        setForm({
                            ...form,
                            name: change?.target?.value?.toUpperCase()
                        })
                    }}
                />

                <JavTextArea
                    title={"Description"}
                    name={"description"}
                    rows={2}
                    value={form?.description}
                    onChange={handleChanges}
                />

                <JavInput
                    title={"Content"}
                    name={"content"}
                    value={form?.content}
                    onChange={handleChanges}
                />

                <JavButton
                    className={"mt-4"}
                    title={"Create Variable"}
                    textColor={"text-white"}
                    isLoading={createFunction?.loading}
                    onClick={onCreateClicked}
                />


            </div>

        </Dialog>
    )
}