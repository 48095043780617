import React, {useEffect} from 'react';
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import {Doughnut, Pie} from 'react-chartjs-2';
import {ucFirstWord} from "../../../Shared/Utils/common";


export default function ActiveOverview(props) {

    ChartJS.register(ArcElement, Tooltip, Legend);

    const options = {
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
            legend: {
                position: 'left',
                align: "center",
                labels: {
                    font: {
                        size: 13,
                        family: "proxima-bold"
                    }
                }
            },
        },
    }

    useEffect(() => {
        console.log("program_overview",props?.data)
    },[props])

    let labels = props?.data && Object.keys(props?.data).map(item => {
        return ucFirstWord(item)
    })

    const data = {
        labels,
        datasets: [
            {
                label: '# of Votes',
                data: props?.data && Object.values(props?.data).map(item => {
                    return item?.volumes
                }),
                backgroundColor: props?.data && Object.values(props?.data).map(item => {
                    return item?.color?.backgroundColor
                }),
                borderColor: props?.data && Object.values(props?.data).map(item => {
                    return item?.color?.borderColor
                }),
                borderWidth: 1.5,
            },
        ],
    };

    return ( <Doughnut data={data} options={options} />)

}