import DashboardStatItem from "./Components";
import DownloadIcon from "../../Shared/Components/Icons/DownladIcon";
import {formatCurrency} from "../../Shared/Utils/common";
import {useSelector} from "react-redux";

const TransactionCharts = ({}) => {

    const tableState = useSelector( (state) => state.table)

    return (
            <>
                <div className={"flex items-center mt-5"}>
                    👉 Summaries
                </div>

                <div className={"grid gap-4 grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 mb-10"}>

                    <DashboardStatItem title={"Total Transactions"} value={tableState['transactions.table']?.data?.summaries?.total ?? '-'} icon={<DownloadIcon className={"h-4 w-4 stroke-1 stroke-black"}/>}/>
                    {/*<DashboardStatItem title={"Total Expected Disburse Amount"} value={`GHS ${formatCurrency(tableState['transactions.table']?.data?.summaries?.expected_disbursed_amount) ?? '-'}`} icon={<DownloadIcon className={"h-4 w-4 stroke-1 stroke-black"}/>}/>*/}
                    <DashboardStatItem title={"Total Disbursed Amount"} value={`GHS ${formatCurrency(tableState['transactions.table']?.data?.summaries?.total_disbursed_amount) ?? '-'}`} icon={<DownloadIcon className={"h-4 w-4 stroke-1 stroke-black"}/>}/>
                    <DashboardStatItem title={"Total Repaid Amount"} value={`GHS ${formatCurrency(tableState['transactions.table']?.data?.summaries?.total_repaid_amount) ?? '-'}`} icon={<DownloadIcon className={"h-4 w-4 stroke-1 stroke-black"}/>}/>
                    <DashboardStatItem title={"Total Profit"} value={`GHS ${formatCurrency(tableState['transactions.table']?.data?.summaries?.total_profit) ?? '-'}`} icon={<DownloadIcon className={"h-4 w-4 stroke-1 stroke-black"}/>}/>
                    <DashboardStatItem title={"Delayed Loans"} value={`${formatCurrency(tableState['transactions.table']?.data?.summaries?.delayed_loans) ?? '-'}`} icon={<DownloadIcon className={"h-4 w-4 stroke-1 stroke-black"}/>}/>

                </div>
            </>
    )
}


export default TransactionCharts;