import JavCheck from "../../../../Shared/Components/Forms/JavCheck";
import JavInput from "../../../../Shared/Components/Forms/JavInput";
import {useRef, useState} from "react";
import SignaturePad from "react-signature-pad-wrapper";
import JavButton from "../../../../Shared/Components/Buttons/JavButton";

export default function AuthorizationAndCertification(props) {

    const signatureRef = useRef();
    const [form,setForm] = useState({
        agreement_check: false,
        read_and_understood_check: false,
        privacy_read_check: false,
        authority_check: false,
        legal_name: "",
        date_signed: "",
        dob: "",
        full_name: "",
        job_title: "",
        email: "",
        residential_address: "",
        signature: null
    });

    const handleChanges = (event) => {
        const value = event.target.value;
        setForm({
            ...form,
            [event.target.name]: value
        })
    }

    const onContinueTapped = () => {
        props.onContinue(form);
    }



    return (
        <div className={"grid grid-cols-2 dark:bg-[#242A38] rounded border dark:border-gray-600 gap-x-12 gap-y-4 py-4 px-4 my-2"}>

            <div className={"col-span-2 grid grid-cols-1 gap-y-5"}>

                <span className={"text-orange-600"}>
                    <span className={"font-bold mr-2"}>Beneficial Owners:</span>
                    The Client authorizes Cambridge to open an account for foreign currency exchange and global
                    payment transactions whereby Client shall be acting as principal, for and on its own behalf,
                    and not on behalf of any third party, and to rely on all orders and instructions from anyone
                    Cambridge reasonably believes is an Authorized User or Administrator of the Client.
                    The Client certifies that (please check all the boxes):
                </span>
                <JavCheck
                    title={"All statements in this Agreement, and any other information and documentation submitted in support of this Agreement, are true and correct."}
                    value={form.agreement_check}
                    onChange={(event) => {
                        setForm({
                            ...form,
                            agreement_check: event.target.checked
                        })
                    }}
                />

                <JavCheck
                    title={"Client has read, understood and hereby accepts the attached terms and conditions"}
                    value={form.read_and_understood_check}
                    onChange={(event) => {
                        setForm({
                            ...form,
                            read_and_understood_check: event.target.checked
                        })
                    }}
                />

                <JavCheck
                    title={"It consents to the Privacy Notice at https://javolin.com/privacy"}
                    value={form.privacy_read_check}
                    onChange={(event) => {
                        console.log("event ",event)
                        setForm({
                            ...form,
                            privacy_read_check: event.target.checked
                        })
                    }}
                />

                <JavCheck
                    title={"The individual(s) signing this application have the authority to bind the Client to the terms of this Agreement (supporting documentation may be requested)"}
                    value={form.authority_check}
                    onChange={(event) => {
                        console.log("event ",event)
                        setForm({
                            ...form,
                            authority_check: event.target.checked
                        })
                    }}
                />


                <h2>
                    Signatory Details
                </h2>


            </div>


            <JavInput
                title={"Client's Complete Legal Name"}
                name={"legal_name"}
                value={form.legal_name}
                onChange={handleChanges}
            />

            <JavInput
                title={"Date Signed"}
                name={"date_signed"}
                value={form.date_signed}
                onChange={handleChanges}
            />

            <JavInput
                title={"Full Legal name"}
                name={"legal_name"}
                value={form.legal_name}
                onChange={handleChanges}
            />

            <JavInput
                title={"Date of birth"}
                name={"dob"}
                value={form.dob}
                onChange={handleChanges}
            />

            <JavInput
                title={"Job Title"}
                name={"job_title"}
                value={form.job_title}
                onChange={handleChanges}
            />

            <JavInput
                title={"Email"}
                name={"email"}
                value={form.email}
                onChange={handleChanges}
            />

            <div className={"col-span-2"}>

                <JavInput
                    title={"Complete residential address"}
                    name={"residential_address"}
                    value={form.residential_address}
                    onChange={handleChanges}
                />

            </div>


            <div className={" col-span-2"}>
                <div className={"flex justify-between"}>
                    <h3 className="text-sm  font-proximaBold text-gray-600">Signature</h3>
                    <button onClick={() => {
                        signatureRef.current.clear();
                    }} className={"mx-2 text-blue-800 underline"}>
                        clear
                    </button>
                </div>
                <div className={"h-44 bg-white"}>
                    <SignaturePad
                        ref={signatureRef}
                    />
                </div>

            </div>



            <div className={"col-span-2 flex justify-end my-1"}>

                <JavButton
                    title={"Continue"}
                    padding={"px-16 py-3"}
                    textColor={"text-white"}
                    onClick={onContinueTapped}
                />

            </div>






        </div>
    )
}